import { useEffect, useState } from "react";
import { Route, Routes, useSearchParams } from "react-router-dom";
import "./App.css";
import Auth0Provider from "./auth/Auth";
import { ToastContainer } from 'react-toastify';
import ProtectedRoute from "./auth/ProtectedRoute";
import {
  AFFILIATE_CODE_EXPIRACIY_IN_DAYS,
  AFFILIATE_QUERY_STRING,
  ONE_DAY_MS,
  REACT_APP_ENVIRONMENT,
} from "./constants/constants";
import { getInitialAuthenticatedUser } from "./helpers/auth0Service";
import useHashconnect from "./hooks/useHashconnect";
import routes from "./pages/index";
import 'react-toastify/dist/ReactToastify.css';
import './assets/customToastStyles.css'
import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@400;700&display=swap');

  body {
    font-family: 'Josefin Sans', sans-serif;
  }
`;

function App() {
  const { initHashconnect } = useHashconnect();
  const [isLoading, setIsLoading] = useState(true);
  const [initialAuthState, setInitialAuthState] = useState(false);
  const [initialUser, setUser] = useState();
  const [searchParams, setSearchParams] = useSearchParams();

  const checkExpirationOfAffl = ({ queryAfflCode }) => {
    try {
      const storedAfflCode = JSON.parse(
        localStorage.getItem(AFFILIATE_QUERY_STRING)
      );
      const afflCode = storedAfflCode?.["afflCode"];
      const afflCodeDate = storedAfflCode?.timestamp;
      if (afflCode) {
        if (afflCodeDate) {
          const currentDate = Date.now();
          const oneDay = ONE_DAY_MS;
          const diffInTime = currentDate - afflCodeDate;
          const diffInDays = Math.round(diffInTime / oneDay);
          if (diffInDays > AFFILIATE_CODE_EXPIRACIY_IN_DAYS) {
            /* Expired affiliate code */
            localStorage.removeItem(AFFILIATE_QUERY_STRING);
          } else {
            /* If there is no query affl code, appends affl code */
            if (!queryAfflCode) {
              setSearchParams({ [AFFILIATE_QUERY_STRING]: afflCode });
            }
          }
        }
      }
    } catch (e) {
      console.log(e);
      console.error("Error checking expiration of afflCode");
    }
  };

  const storeAfllCode = ({ queryAfflCode }) => {
    try {
      if (queryAfflCode) {
        /* store in local storage overwrites*/
        const afflCodeObject = {
          afflCode: queryAfflCode,
          timestamp: new Date().getTime(),
        };
        localStorage.setItem(
          AFFILIATE_QUERY_STRING,
          JSON.stringify(afflCodeObject)
        );
      }
    } catch (e) {
      console.log(e);
      console.error("Error storing afflCode");
    }
  };

  useEffect(() => {
    (async () => {
      let queryAfflCode = searchParams.get(AFFILIATE_QUERY_STRING);
      storeAfllCode({ queryAfflCode });
      checkExpirationOfAffl({ queryAfflCode });

      initHashconnect();
  
      try {
        const { user } = await getInitialAuthenticatedUser();
        if (user?.email) {
          setInitialAuthState(true);
          setUser(user);
        }
      } catch (e) {
        console.log(e);
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  if (isLoading) return null;

  return (
    <Auth0Provider
      initialAuthState={initialAuthState}
      initialUser={initialUser}
    >
      <GlobalStyle />
      <Routes>
        {routes.map((data, index) => (
          <Route
            onUpdate={() => window.scrollTo(0, 0)}
            exact={true}
            path={data.path}
            element={
              !data.reqAuth ? (
                data.component
              ) : (
                <ProtectedRoute component={data.component} />
              )
            }
            key={index}
          />
        ))}
      </Routes>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </Auth0Provider>
  );
}

export default App;
