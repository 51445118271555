import React, { useRef, useEffect, useState } from "react";
import styled from "styled-components";
import { 
  ARTSTYLING, 
  GENRE } from "../../constants/constants";
import { useNavigate, useLocation } from "react-router-dom";
import { notify, notifyError } from "../../helpers/toastNotificationService"
import SynthFooter from "../../components/synth/Footer";
import SynthHeader from "../../components/synth/Header";
import { getCharactersById, getCharacters, createCharacter, getCharactersDesign } from "../../api/synth/getCharacters";
import { updateStories } from "../../api/synth/updatestories";
import Spinner from "../../components/layouts/Spinner/Spinner";
import ImportModal from "../../components/synth/ImportModal";
import ConfirmChangeModal from "../../components/synth/UpdateStyleModal";
import { importCharacters,  updateCharacterDesign, updateCharacters } from "../../api/synth/importCharacters";
import { sdxlCreateCharacter } from "../../api/synth/sdxl";


// TODO: Need to clearn up this sections handling, move to constants (kinda started this) and setup dropdown handling so that saved values are stored adn retrieved from the database. 
// Currently hardcoding values for demo purposes. 


const sections = [
  {
    title: "Art Styling",
    apiName: "artStyling",
    options: ["","anime", "digital-art", "comic-book", "cinematic", "photographic"],
  },
  /*{
    title: "Genre",
    apiName: "genre",
    options: ["","Action", "Fantasy", "Western", "Science Fiction"],
  },*/
];

const EditChar = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const queryParams = new URLSearchParams(location.search);
    const [isLoading, setLoading] = useState(false);
    const [characters, setCharacters] = React.useState([]);
    const [showModal, setShowModal] = useState(false);
    const [dropdownOpen, setDropdownOpen] = useState({});
    const [defaultOptions, setDefaultOptions] = useState({});
    const [loadingMessage, setLoadingMessage] = useState(null);
    const [showGenerateButton, setShowGenerateButton] = useState(false);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [pendingChanges, setPendingChanges] = useState(null);

  const projectName = queryParams.get('projectName');
  const storyId = queryParams.get('storyId');


  // Manage Dropdown menus
  const toggleDropdown = (index) => {
    setDropdownOpen(prev => ({
      ...prev,
      [index]: !prev[index]  // Toggle the current state for this index
    }));
  };

    // Get All Characters for this Story
  React.useEffect(() => {
    const fetchCharacters = async () => {
      setLoadingMessage('Fetching Characters... This will take a moment.');
      setLoading(true);
      try {
        const response = await getCharacters(storyId);
        //console.log('Characters response:', response);
        if (Array.isArray(response) && response.length > 0) {
          setCharacters(response);
          const missingImages = response.some(character => !character.charImage);
          setShowGenerateButton(missingImages);
          setShowModal(false); // Hide modal if characters are found
        } else {
          //console.error('No characters found or not an array:', response);
          setCharacters([]);
          setShowModal(true); // Show modal if no characters are found
        }
      } catch (error) {
        //console.error('Error fetching characters:', error);
        notifyError("Error obtaining characters. Please refresh and try again")
      } finally {
        setLoading(false);
      }
    };  
  
    fetchCharacters();
  }, [storyId]); // Dependency array to refetch if storyId changes

  const handleCharacterClick = (character) => {
    navigate(`/editchardetail?projectName=${encodeURIComponent(projectName)}&storyId=${encodeURIComponent(storyId)}&charId=${encodeURIComponent(character.id)}`);
  };

  const handleAddCharacterClick = async () => {
    navigate(`/createchar?projectName=${encodeURIComponent(projectName)}&storyId=${encodeURIComponent(storyId)}`);
  };

  const handleCharacterImport = async () => {
    //console.log("Importing characters");
    setShowModal(false);
    setLoadingMessage('Importing Characters... This will take a moment.');
    setLoading(true);
    try {
      const response = await importCharacters(storyId);
      //console.log("Characters imported successfully:");
      notify("Characters imported successfully");
    } catch (error) {
      console.error("Failed to import characters:", error);
      notifyError("Failed to import characters: " + error.message);
    } finally {
      setLoading(false);
      navigate(`/editchar?projectName=${encodeURIComponent(projectName)}&storyId=${encodeURIComponent(storyId)}`);
    }
  }
  
  const handleReturnToStory = () => {
    navigate(`/editstory?projectName=${encodeURIComponent(projectName)}&storyId=${encodeURIComponent(storyId)}`);
  }
  
  const handleIconClick = (character, iconIndex) => {
    console.log(
      `Clicked on icon ${iconIndex + 1} for character: ${character.name}`
    );
  };
  
  ///////////////////////////////////////////
  // Get Initial Character Design Options
  useEffect(() => {
    const fetchDefaultOptions = async () => {
      //console.log("Starting API call to fetch default options");
      setLoadingMessage('Fetching Characters... This will take a moment.');
      setLoading(true);
      try {
        const data = await getCharactersDesign(storyId); 
        setDefaultOptions({
          'Art Styling': data.artStyling,
          'Genre': data.genre
        });
      } catch (error) {
        console.error('Error fetching default options:', error);
      } finally {
        setLoading(false);
      }
    };
    
    fetchDefaultOptions();
  }, []); // This useEffect will run once on component mount
  
    const [selectedOptions, setSelectedOptions] = useState([]);

    useEffect(() => {
      if (Object.keys(defaultOptions).length > 0) {
        const newSelectedOptions = sections.map(section => {
          const defaultOption = defaultOptions[section.title];
          const defaultIndex = section.options.indexOf(defaultOption);
          return defaultIndex >= 0 ? defaultIndex : 0; // Fallback to the first option if not found
        });
        setSelectedOptions(newSelectedOptions);
      }
    }, [defaultOptions]); // This effect runs when defaultOptions changes
  
    
    ///////////////////////////////////
    // Update character design
    const handleOptionChange = async (sectionIndex, optionIndex) => {
      // Update selected options locally
      const sectionTitle = sections[sectionIndex].apiName;
      const option = sections[sectionIndex].options[optionIndex];

      // Update the selected options state
      setSelectedOptions(prev => {
        const newSelected = [...prev];
        newSelected[sectionIndex] = optionIndex;
        return newSelected;
      });

      // Prepare the request body using the selected option
      const requestBody = {
        storyId,
        [sectionTitle]: option,
      };

      // Close the dropdown
      setDropdownOpen(prev => ({ ...prev, [sectionIndex]: false }));

      // API call to update the backend
      try {
        await updateCharacterDesign({ requestBody });
      } catch (error) {
        console.error('Error updating character design:', error);
      }
    };


 /////////////////////////////////////////////////
 // Update AI images to align with style changes  
  // Delete character images for existing characters
      
  const handleStyleUpdate = () => {
    setShowConfirmationModal(true);
  };
    
  const handleConfirmChange = async () => {
    // close confirmation modal
    setShowConfirmationModal(false);
    setLoadingMessage('Updating Existing Characters... This will take a moment.');
    setLoading(true); // Initial loading message
    
    const response = await getCharacters(storyId);
    if (response && response.length > 0) {
      const charDesignData = await getCharactersDesign(storyId)
      for (let i = 0; i < response.length; i++) {
        const character = response[i];
  
        // Check if charImage is not empty
        if (character.charImage) {
          setLoadingMessage(`Generating image for ${character.charName}...`);
          //console.log(`Generating image for ${character.charName}, Style = ${charDesignData.artStyling}`);
          // generate new image
          const requestBody = {
            storyId: storyId,
            characterId: character.id,
            prompt: character.charDesc,
            seed: 0,
            stylePreset: charDesignData.artStyling,
          };
          const genAiImage = await sdxlCreateCharacter({ requestBody });
          const imageUrl = genAiImage.charImage;
          
          // Update charImage to with new one
          const charImageRequest = {
            charImage: imageUrl,
          };
          await updateCharacters(character.id, charImageRequest);
        }
      }
    }
    window.location.reload();
  };
  

  /////////////////////////////////////////////////  
  // Generate Missing AI Character Images
  const handleGenerateMissingImages = async () => {
    //console.log("Clicked Generate Character Images Button");
    setLoadingMessage('Generating Characters... This will take a moment.');
    setLoading(true); // Initial loading message
  
    try {
      const response = await getCharacters(storyId);
      if (response && response.length > 0) {
        const imageUrls = [];
        
        const charDesignData = await getCharactersDesign(storyId); 
                
          for (let i = 0; i < response.length; i++) {
          const character = response[i];
          // Update the loading message with the character's name
          setLoadingMessage(`Generating image for ${character.charName}...`);
  
          if (character.charImage) {
            //console.log(`Character ${i} has an image, adding to array`);
            imageUrls.push(character.charImage);
          } else {
            //console.log(`Character ${i} does not have an image, triggering image generation`);
            const requestBody = {
              storyId: storyId,
              characterId: character.id,
              prompt: character.charDesc,
              seed: 0,
              stylePreset: charDesignData.artStyling,
            };
            const genAiImage = await sdxlCreateCharacter({ requestBody });
            const imageUrl = genAiImage;
            imageUrls.push(imageUrl);
            //console.log(`Character ${i} created successfully`, imageUrl);
          }
        }
  
        //console.log("Collected Image URLs:", imageUrls);
        window.location.reload();
      } else {
        console.error("No characters found or response is not an array:", response);
      }
    } catch (error) {
      console.error("Failed to fetch characters:", error);
    } finally {
      setLoading(false);
    }
  };
  

  /////////////////////////////////////////////////
  
  
  const handleApplyStyleUpdates = () => {
    console.log("Clicked Apply Style Updates Button");
  };
  
  const handleUpdateStory = () => {
    console.log("Clicked Update Story Button");
  };

  const handleAISubmit = () => {
    console.log("Clicked AI Submit Button");
  };


  const handleSubmit = (event) => {
    event.preventDefault();
    const userInput = event.target.elements.userInput.value;
    //console.log("User input:", userInput);
  };

  const handleCancel = () => {
    setPendingChanges(null);
    setShowConfirmationModal(false);
  };
  

  return (
    <AppWrapper>
      <SynthHeader project={projectName} />
      <PageBody>
        <Main>
        {isLoading && (
          <ProgressOverlay>
            <ProgressStatus>{loadingMessage}</ProgressStatus>
            <Spinner />
          </ProgressOverlay>
        )}
          <CharacterDesignContainer>
            <Title>Character Design</Title>
            <CharacterGrid>
              <CharacterList>
                {characters.map((character, index) => (
                  <CharacterCard
                    key={index}
                    onClick={() => handleCharacterClick(character)}
                  >
                    <CharacterImageWrapper>
                      {character.charImage ? (
                        <CharacterImage src={character.charImage} alt={character.charName} />
                      ) : (
                        <NoImageProfile src="https://alsynthprod.s3.amazonaws.com/Default_pfp.jpg" alt="No image available" />
                      )}
                    </CharacterImageWrapper>
                    <CharacterDetails>
                      <CharacterName>{character.charName}</CharacterName>
                      <CharacterDescription>{character.charDesc}</CharacterDescription>
                    </CharacterDetails>
                  </CharacterCard>
                ))}
                <AddCharacterCard>
                  <AddCharacterButton onClick={handleAddCharacterClick}>
                    <AddCharacterIcon>+</AddCharacterIcon>
                    <AddCharacterText>Add Character</AddCharacterText>
                  </AddCharacterButton>
                </AddCharacterCard>
              </CharacterList>
            </CharacterGrid>
          </CharacterDesignContainer>
        </Main>
    <Sidebar>
    <CharactersTitle>Characters</CharactersTitle>
      <SidebarCharacterList>
        {characters.map((character, index) => (
          <React.Fragment key={character.charName}>
            <CharacterItem>
              <SidebarCharacterImage src={character.charImage || 'https://alsynthprod.s3.amazonaws.com/Default_pfp.jpg'} alt={character.charName} />
              <SidebarCharacterName>{character.charName}</SidebarCharacterName>
              <EditIcon onClick={() => handleCharacterClick(character)} src="https://alsynthprod.s3.amazonaws.com/Pencil_icon.svg" alt="Edit character" />
            </CharacterItem>
            {index < characters.length - 1 && <SidebarDivider />}
          </React.Fragment>
        ))}
      </SidebarCharacterList>
      <StyleTitle>Overall Character Style</StyleTitle>
      {sections.map((section, index) => (
        <React.Fragment key={section.title}>
          <SectionTitle onClick={() => toggleDropdown(index)}>
            {section.title}
            {selectedOptions[index] !== null && `: ${section.options[selectedOptions[index]]}`}
            <DropdownIcon src="https://alsynthprod.s3.amazonaws.com/drop-down-arrow.svg" alt="Dropdown arrow" />
          </SectionTitle>
          {dropdownOpen[index] && (
            <div>
              {section.options.map((option, optionIndex) => (
                <DropdownOption
                  key={option}
                  onClick={() => handleOptionChange(index, optionIndex)}
                  style={{ backgroundColor: selectedOptions[index] === optionIndex ? '#e1e1e1' : '#f1f1f1' }}
                >
                  {option}
                </DropdownOption>
              ))}
            </div>
          )}
        </React.Fragment>
      ))}
      <AiHelpText>
        Use AI to help you with your characters.
        <br />
      </AiHelpText>
      {showGenerateButton && (
                <SubmitButton onClick={handleGenerateMissingImages}>
                    Generate Missing Character Images
                </SubmitButton>
            )}
      <SubmitButton onClick={handleStyleUpdate}>Apply Styles to my Characters</SubmitButton>
      {/*<AiInstructionText>
        <br />
        Say what you want to change in your story, and AI will implement those changes.{" "}
      </AiInstructionText>
      <form>
        <UserInput
          type="text"
          id="userInput"
          placeholder="Add to My Story"
          aria-label="Add to My Story"
        />
        <SubmitButton onClick={handleAISubmit}>Submit</SubmitButton>
      </form>*/}
    </Sidebar>
    {showModal && (
        <ImportModal
          onReturn={handleReturnToStory}
          onImport={handleCharacterImport}
        />
      )}
      {showConfirmationModal && (
            <ConfirmChangeModal
            visible={showConfirmationModal}
            sectionTitle={pendingChanges ? sections[pendingChanges.sectionIndex].title : ""}
            option={pendingChanges ? pendingChanges.option : ""}
            onStyleConfirm={handleConfirmChange}
            onCancel={handleCancel}
          />
        )}
    </PageBody>
    <Divider />
    <SynthFooter />
    </AppWrapper>
  );
}

const AppWrapper = styled.div`
  background-color: #fbfdff;
  display: flex;
  flex-direction: column;
  min-height: 100vh; // Ensure it takes the full viewport height
`;


const PageBody = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  flex-grow: 1; // Allow it to grow and fill the remaining space
`;


const Main = styled.main`
  align-items: flex-start;
  margin-top: 42px;
  margin-top: 42px;
  width: 80%;
  overflow-x: auto; // Allows horizontal scrolling
  padding: 0 20px; // Add padding to prevent content from touching the edges
  flex-grow: 1; // Allow it to grow and fill the remaining space
  flex-shrink: 0; // Prevent it from shrinking
  @media (max-width: 991px) {
    max-width: 80%;
    margin-top: 40px;
    padding: 0 10px; // Adjust padding for smaller screens
  }
`;


const Sidebar = styled.main`
  background-color: #f7f5f5;
  width: 15%; // Set a fixed width for the sidebar
  min-width: 300px;
  flex-shrink: 0; // Prevent the sidebar from shrinking
  flex-direction: column;
  font-size: 16px;
  color: #000;
  font-weight: 400;
  @media (max-width: 991px) {
    margin-top: 40px;
    padding-right: 20px;
  }
`;

const Divider = styled.div`
  border-color: rgba(33, 89, 162, 1);
  border-style: solid;
  border-width: 4px;
  background-color: #2159a2;
  z-index: 10;
  min-height: 4px;
  width: 100%;
  flex-shrink: 0; // Prevent it from shrinking
`;

const CharacterDesignContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 20px 0;
  @media (max-width: 991px) {
    padding-top: 40px;
  }
`;

const Title = styled.h1`
  color: #011126;
  font: 700 24px 'Josefin Sans', sans-serif;
  text-align: center;
  margin: 0; // Remove default margin to help with centering
  display: flex;
  align-items: center; // Vertically center the text in the flex container
  justify-content: center; // Horizontally center the text in the flex container
  height: 100%; // Take full height of parent
`;

const CharacterGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 44px;

  @media (max-width: 991px) {
    flex-direction: column;
    gap: 24px;
    margin-top: 40px;
  }
`;

const CharacterList = styled.div`
  display: flex;
  flex-wrap: wrap; // Allows items to wrap onto the next line if needed
  gap: 20px;
  overflow-x: visible;
  padding: 10px 0;

  @media (max-width: 991px) {
    gap: 10px;
  }
`;


const CharacterCard = styled.button`
  width: 175px;
  height: 335px;
  flex-shrink: 0;
  background-color: #fff;
  border: 1px solid #2159a2;
  box-shadow: 4px 4px 6.5px 0px rgba(0, 0, 0, 0.25);
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

const CharacterImageWrapper = styled.div`
  flex: 0 0 40%; // Fixed height of 60% of CharacterCard
  width: 80%
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

const CharacterImage = styled.img`
  width: 100%;
  object-fit: contain;
  max-height: 100%;
  align-self: center;
`;

const CharacterDetails = styled.div`
  flex: 1; // Fills the remaining 40% height of CharacterCard
  padding: 10px;
  background-color: #fbfdff;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start; // Align items to the bottom
`;

const CharacterName = styled.h2`
  color: #011126;
  font: 700 15px 'Josefin Sans', sans-serif;
  margin: 0;
`;

const CharacterDescription = styled.p`
  color: #011126;
  font: 400 12px 'Josefin Sans', sans-serif;
  margin: 0;
  margin-top: 10px;
  align-self: flex-start; // Aligns to the top of CharacterName
`;

const NoImageProfile = styled.img`
  width: 100%;
  object-fit: contain;
  max-height: 100%;
  align-self: center;
`;

const CharacterIcons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: start;
  gap: 20px;
  padding: 0 2px;
  margin-top: 48px;

  @media (max-width: 991px) {
    margin-top: 40px;
  }
`;

const CharacterIconButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
`;

const CharacterIcon = styled.img`
  width: 22px;
  aspect-ratio: 1.1;
  object-fit: contain;
`;

const AddCharacterCard = styled(CharacterCard)` // Extend CharacterCard styles
  display: flex;
  justify-content: center;
  align-items: center;
`;

const AddCharacterButton = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: #fbfdff;
  border: none; // Remove default button border
  cursor: pointer;
`;

const AddCharacterIcon = styled.span`
  color: #2159a2;
  font-size: 48px;
  margin-bottom: 32px;

  @media (max-width: 991px) {
    font-size: 40px;
    margin-bottom: 20px;
  }
`;

const AddCharacterText = styled.span`
  color: #011126;
  font-size: 20px;
`;

const CharactersTitle = styled.h2`
  margin: 36px 0 25px 33px;
  font: 20px 'Josefin Sans', sans-serif;

  @media (max-width: 991px) {
    margin-left: 10px;
  }
`;

const SidebarCharacterList = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-right: 20px;
  gap: 10px;

  @media (max-width: 991px) {
    padding-right: 20px;
  }
`;

const CharacterItem = styled.div`
  display: flex;
  gap: 20px;
  justify-content: flex-start;
  align-items: center;
  height: 40px;
`;

const SidebarCharacterImage = styled.img`
  object-fit: contain;
  object-position: center;
  width: 50px;
  height:  50px;
  border-radius: 50%;
`;

const SidebarCharacterName = styled.span`
  font-family: 'Josefin Sans', sans-serif;
`;

const EditIcon = styled.img`
  aspect-ratio: 0.8;
  object-fit: contain;
  object-position: center;
  width: 24px;
  position: absolute;
  right: 15px;
  
`;

const SidebarDivider = styled.hr`
  border: none;
  border-top: 1px solid #e4e4e4;
  background-color: #e4e4e4;
  margin: 0 0;
`;

const StyleTitle = styled.h2`
  margin: 19px 0 9px 11px;
  font: 600 18px 'Josefin Sans', sans-serif;

  @media (max-width: 991px) {
    margin-left: 10px;
  }
`;

const SectionTitle = styled.h3`
  margin: 20px 0 19px 11px;
  font: 500 16px 'Josefin Sans', sans-serif;

  @media (max-width: 991px) {
    margin-left: 10px;
  }

  &:first-of-type {
    margin-top: 0;
  }
`;

const Dropdown = styled.div`
  border: 1px solid #d9d9d9;
  background-color: #fff;
  display: flex;
  gap: 20px;
  font-size: 15px;
  justify-content: space-between;
  margin: 0 11px;
  padding: 10px 16px 10px 12px;

  @media (max-width: 991px) {
    margin: 0 10px;
  }
`;

const DropdownText = styled.span`
  font-family: 'Josefin Sans', sans-serif;
`;

const DropdownIcon = styled.img`
  float: right;
  width: 5%;
  padding-right: 5px;
  
`;

const DropdownOption = styled.div`
  padding: 10px;
  background-color: #f1f1f1;
  border-bottom: 1px solid #ccc;

  &:hover {
    background-color: #e1e1e1;
  }
`;

const AiHelpText = styled.p`
  color: #24777d;
  margin: 28px 0 13px 12px;
  font: 700 18px 'Josefin Sans', sans-serif;

  @media (max-width: 991px) {
    margin-left: 10px;
  }
`;

const AiInstructionText = styled.p`
  color: #011126;
  font: 14px 'Josefin Sans', sans-serif;
`;

const UserInput = styled.input`
  border: 1px solid #81818a;
  background-color: #fff;
  width: 334px;
  max-width: 100%;
  color: #81818a;
  margin: 15px 12px 0;
  padding: 29px 25px;
  font: 15px 'Josefin Sans', sans-serif;

  @media (max-width: 991px) {
    margin: 0 10px;
    padding: 29px 20px;
  }
`;

const SubmitButton = styled.button`
  font-family: 'Josefin Sans', sans-serif;
  justify-content: center;
  border-radius: 15px;
  box-shadow: 3px 4px 6.1px 0px rgba(0, 0, 0, 0.25);
  background-color: #24777d;
  align-self: center;
  margin-top: 20px;
  color: #fbfdff;
  font-weight: 600;
  white-space: nowrap;
  text-align: center;
  padding: 10px 20px;
  border: none;
  cursor: pointer;

  @media (max-width: 991px) {
    white-space: initial;
    padding: 10px 20px;
  }
`;

const VisuallyHidden = styled.span`
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
`;

const ProgressOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  right: 0;
  bottom: 0;
  background: rgba(205, 205, 205, 0.8);
  z-index: 7777;
  position: fixed;

  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  flex-wrap: wrap;
  `;
  
  const ProgressStatus = styled.div`
    display: block;
    width: 100%;
    font-weight: 400;
    font-size: 24px;
    line-height: 24px;
    margin-bottom: 8px;
    text-align: center;
    color: #011126;
`;


export default EditChar;