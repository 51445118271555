import * as React from "react";
import styled from "styled-components";
import { useNavigate, useLocation } from "react-router-dom";

const data = [
  {
    id: 1,
    icon: "https://alsynthprod.s3.amazonaws.com/scriptIcon.svg",
    title: "Script",
    isActive: true,
  },
  {
    id: 2,
    icon: "https://alsynthprod.s3.amazonaws.com/charactersIcon.svg",
    title: "Characters",
    isActive: true,
  },
  {
    id: 3,
    icon: "https://alsynthprod.s3.amazonaws.com/storyboardIcon.svg",
    title: "Storyboard",
    isActive: true,
  },
  {
    id: 4,
    icon: "https://alsynthprod.s3.amazonaws.com/animateIcon.svg",
    title: "Animate",
    isActive: false,
  },
  {
    id: 5,
    icon: "https://alsynthprod.s3.amazonaws.com/soundIcon.svg",
    title: "Sound Design",
    isActive: false,
  },
  {
    id: 6,
    icon: "https://alsynthprod.s3.amazonaws.com/reviewIcon.svg",
    title: "Review",
    isActive: true,
  },
];

const SynthFooter = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = React.useState(1);
  const queryParams = new URLSearchParams(location.search);
  const projectName = queryParams.get('projectName');
  const storyId = queryParams.get('storyId');

  
  const handleStepClick = (stepId) => {
    setActiveStep(stepId);
    switch(stepId) {
      case 1:
        navigate(`/editstory?projectName=${encodeURIComponent(projectName)}&storyId=${encodeURIComponent(storyId)}`);
        break;
      case 2:
        navigate(`/editchar?projectName=${encodeURIComponent(projectName)}&storyId=${encodeURIComponent(storyId)}`);
        break;
      case 3:
        navigate(`/storyboard?projectName=${encodeURIComponent(projectName)}&storyId=${encodeURIComponent(storyId)}`);
        break;
      case 4:
        navigate(`/storyboardanimation?projectName=${encodeURIComponent(projectName)}&storyId=${encodeURIComponent(storyId)}`);
        break;
      case 5:
        break;
      case 6:
        navigate(`/review?projectName=${encodeURIComponent(projectName)}&storyId=${encodeURIComponent(storyId)}`);
        break;
      default:
        break;
    }
  };
  

  const AiProgress = "https://alsynthprod.s3.amazonaws.com/AIProgress.jpg";
  const LeftArrow = "https://alsynthprod.s3.amazonaws.com/leftArrow.svg";
  const RightArrow = "https://alsynthprod.s3.amazonaws.com/rightArrow.svg";

  return (
    <MainWrapper>
      <AiImageWrapper>
        <AiImage loading="lazy" src={AiProgress} alt="AI Progress" />
      </AiImageWrapper>
      <Divider />
      <StepsWrapper>
        <LeftIcon src={LeftArrow} alt="Previous Step" />
        {data.map((item) => (
          <StepButton
            key={item.id}
            isActive={item.id === activeStep}
            isInactive={!item.isActive}
            onClick={() => handleStepClick(item.id)}
          >
            <StepIcon src={item.icon} alt={`${item.title} Icon`} />
            <StepTitle>{item.title}</StepTitle>
          </StepButton>
        ))}
        <RightIcon loading="lazy" src={RightArrow} alt="Next Step" />
      </StepsWrapper>
    </MainWrapper>
  );
};

const MainWrapper = styled.section`
  background-color: #fbfdff;
  display: flex;
  width: 100%;
  gap: 20px;
  font-size: 11px;
  color: #2159a2;
  font-weight: 600;
  
  @media (max-width: 991px) {
    max-width: 100%;
    flex-wrap: wrap;
    padding-left: 20px;
  }
`;

const Divider = styled.div`
  border-color: rgba(33, 89, 162, 1);
  border-style: solid;
  border-width: 1px;
  background-color: #2159a2;
  align-self: start;
  width: 1px;
  height: 85px;
`;

const AiImageWrapper = styled.div`
  width: 125px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const AiImage = styled.img`
  aspect-ratio: 1.04;
  object-fit: auto;
  object-position: center;
  width: 69px;
`;

const StepsWrapper = styled.div`
  display: flex;
  align-items: start;
  gap: 17px;
  justify-content: space-between;
  flex-grow: 1;
  margin: auto 0;
  padding: 0 1px;
  @media (max-width: 991px) {
    flex-wrap: wrap;
  }
`;

const LeftIcon = styled.img`
  aspect-ratio: 1.05;
  object-fit: auto;
  object-position: center;
  width: 38px;
  fill: #fbfdff;
  stroke-width: 0.839px;
  stroke: #2159a2;
  filter: drop-shadow(0px 3.356px 3.356px rgba(0, 0, 0, 0.25));
`;

const RightIcon = styled.img`
  aspect-ratio: 1.05;
  object-fit: auto;
  object-position: center;
  width: 38px;
  margin-right: 10px;
  fill: #fbfdff;
  stroke-width: 0.839px;
  stroke: #2159a2;
  filter: drop-shadow(0px 3.356px 3.356px rgba(0, 0, 0, 0.25));
`;

const StepButton = styled.button`
  display: flex;
  color: #2159a2;
  align-items: center;
  gap: 15px;
  white-space: nowrap;
  text-align: center;
  margin: auto 0;
  padding: 10px 20px;
  border-radius: 20px;
  box-shadow: ${(props) =>
    props.isActive ? "1px 4px 6.6px 0 rgba(0, 0, 0, 0.25)" : "4px 4px 6px 0 rgba(0, 0, 0, 0.25)"};
  border: ${(props) => (props.isActive ? "none" : "1px solid rgba(217, 217, 217, 1)")};
  background-color: ${(props) => (props.isActive ? "#fbfdff" : "#fbfdff")};
  cursor: ${(props) => (props.isInactive ? 'not-allowed' : 'pointer')};
  opacity: ${(props) => (props.isInactive ? 0.5 : 1)};
  pointer-events: ${(props) => (props.isInactive ? 'none' : 'auto')};

  &:hover {
    ${(props) => !props.isInactive && `
      background-color: #e0f7ff;
      border: 1px solid #b3e5fc;
    `}
  }

  @media (max-width: 991px) {
    white-space: initial;
    padding: 0 20px;
  }
`;


const StepIcon = styled.img`
  aspect-ratio: 1;
  object-fit: auto;
  object-position: center;
  width: 24px;
`;

const StepTitle = styled.div`
  font-family: Inter, sans-serif;
  margin: auto 0;
`;

export default SynthFooter;
