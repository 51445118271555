import React, { useRef, useEffect, useState } from "react";
import styled from "styled-components";
import { notify, notifyError } from "../../helpers/toastNotificationService"
import { useNavigate, useLocation } from "react-router-dom";
import SynthFooter from "../../components/synth/Footer";
import SynthHeader from "../../components/synth/Header";
import Spinner from "../../components/layouts/Spinner/Spinner";
import { IconTrash, IconRepeat, IconUpload } from '@tabler/icons-react';
import { createClaude, updateClaude } from "../../api/synth/claudeTestCreate";
import { 
  getScenesById, 
  updateScenes, 
  uploadSceneAnimation,
  uploadSceneAnimationSource1,
  uploadSceneAnimationSource2,
  uploadSceneAnimationSource3,
  repltest,
 } from "../../api/synth/scenes";
import '@progress/kendo-theme-default/dist/all.css';
import EditStoryContext from './EditStoryContext';

const defaultImage = 'https://alsynthprod.s3.amazonaws.com/ProjectPlaceholderImage.jpg';

const user = {
  id: 1,
  //avatarUrl: "https://demos.telerik.com/kendo-ui/content/web/Customers/RICSU.jpg",
  //avatarAltText: "Conversational UI RICSU"
};

const bot = { id: 0 };

const initialMessages = [
  {
    author: bot,
    timestamp: new Date(),
    text: "Hello, How can I help you with your story?",
  },
];

const ButtonWithTooltip = ({ onClick, icon: Icon, tooltipText, disabled }) => {
  const [hover, setHover] = useState(false);

  return (
    <StyledButton
      onClick={onClick}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      disabled={disabled}
    >
      <Tooltip show={hover}>{tooltipText}</Tooltip>
      <Icon color="#2159a2" size={24} />
    </StyledButton>
  );
};


function AnimateColorize() {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const [isLoading, setLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState(null);
  const [sceneText, setSceneText] = useState("");
  const [act, setAct] = useState([]);
  const [scene, setScene] = useState([]);
  const [sceneAnimationSource1, setSceneAnimationSource1] = useState(scene.sceneAnimationSource1);
  const [sceneAnimationSource2, setSceneAnimationSource2] = useState(scene.sceneAnimationSource2);
  const [sceneAnimationSource3, setSceneAnimationSource3] = useState(scene.sceneAnimationSource3);
  const [sceneAnimation, setSceneAnimation] = useState(scene.sceneAnimation);
  const [sceneScriptText, setSceneScriptText] = useState(scene.sceneScriptText);
  const [editableLocation, setEditableLocation] = useState(scene.sceneLocation);
  const [editableDescription, setEditableDescription] = useState(scene.sceneDesc);
  const [editablePositionDetails, setEditablePositionDetails] = useState(scene.sceneOrder);
  const [editablePrompt, setEditablePrompt] = useState("");
  const [showGenerateButton, setShowGenerateButton] = useState(false);
  const [isLoadingResponse, setIsLoadingResponse] = React.useState(false);
  const loadingGifUrl = "https://alsynthprod.s3.amazonaws.com/3dotLoading2.gif"
  const [animationExists, setAnimationExists] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');

  const projectName = queryParams.get('projectName');
  const storyId = queryParams.get('storyId');
  const sceneId = queryParams.get('sceneId');


  React.useEffect(() => {
    // setLoading(true);
    const retrieveScene = async () => {
      setLoading(true);  
      try {
            const response = await getScenesById(sceneId);
            //console.log('Scene response:', response);
            setScene(response);
            setEditableLocation(response.sceneLocation);
            setEditableDescription(response.sceneDesc);
            setEditablePositionDetails(response.sceneOrder);
            setEditablePrompt(response.scenePrompt);
            setSceneAnimation(response.sceneAnimation);
            setSceneScriptText(response.sceneScriptText);
            setSceneAnimationSource1(response.sceneAnimationSource1);
            setSceneAnimationSource2(response.sceneAnimationSource2);
            setSceneAnimationSource3(response.sceneAnimationSource3);
            setAct(response.act);
            if (!response.sceneImage) {
              setShowGenerateButton(true);
            } else {
              setShowGenerateButton(false);
            }
            if (!response.sceneAnimation) {
              setAnimationExists(false);
            } else {
              setAnimationExists(true);
            }
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
            setLoadingMessage(null);
        }
    };
    retrieveScene();
}, [sceneId]); // Add storyId as a dependency to refetch when it changes



  const handleDescriptionChange = (event) => {
    setEditableDescription(event.target.value);
    //setScene({ ...scene, sceneDesc: event.target.value });
  };

  const handlePromptChange = (event) => {
    setEditablePrompt(event.target.value);
    //setScene({ ...scene, scenePrompt: event.target.value });
  };

  const handleScriptChange = (event) => {
    setSceneScriptText(event.target.value);
    //setScene({ ...scene, sceneScriptText: event.target.value });
  };
  

  const handleUploadClick = () => {
    document.getElementById('fileInput').click();
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      if (validateFileVideo(file)) {
        setSelectedFile(file);
        await uploadFile(file);
      }
    }
  };

  const validateFileVideo = (file) => {
    const allowedFormats = ['video/mp4'];
    const maxSizeInBytes = 5 * 1024 * 1024; // 5 MB

    if (!allowedFormats.includes(file.type)) {
      notifyError('Only .mp4 formats are allowed.');
      return false;
    }

    if (file.size > maxSizeInBytes) {
      notifyError('File size must be less than 5MB.');
      return false;
    }

    setErrorMessage('');
    return true;
  };

  const uploadFile = async (file) => {
    //console.error('Upload started');
    setLoadingMessage('Upload Scene Animation... This will take a moment.');
    setLoading(true);
    const formData = new FormData();
    formData.append('file', file);

    try {
      const response = await uploadSceneAnimation(sceneId, formData);
      console.error('Upload complete');
      setSceneAnimation(response.sceneAnimation);
      setLoading(false);
      setLoadingMessage(null);
    } catch (error) {
      console.error('Error uploading file:', error);
      notifyError('Error uploading file. Please try again.');
    }
    setLoading(false);
    setLoadingMessage(null);
  };



  //////////////////////////////////////////////////////////////////
  // Chat Testing
  const { Chat } = require('@progress/kendo-react-conversational-ui');
  const [messages, setMessages] = React.useState(initialMessages);
  
  const addNewMessage = async (event) => {
    let botResponse = Object.assign({}, event.message);
    setMessages([...messages, event.message]);
    
    // Set loading state to true
    setIsLoadingResponse(true);
  
    // Add a temporary loading message with GIF
    const loadingMessage = {
      author: bot,
      text: "",
      timestamp: new Date(),
      loading: true, // Custom property to identify loading message
    };
    setMessages((oldMessages) => [...oldMessages, loadingMessage]);
  
    // Fetching response from Claude AI interaction
    botResponse.text = await claudeInteraction(event.message.text);
    botResponse.author = bot;
  
    // Adding suggested actions to the bot response
    botResponse.suggestedActions = [
      {
        value: "Add this to my story",
        type: "reply",
      },
      {
        value: "Change the tone",
        type: "reply",
      },
    ];
  
    // Remove the loading message
    setMessages((oldMessages) => oldMessages.filter((msg) => !msg.loading));
  
    // Set loading state to false
    setIsLoadingResponse(false);
  
    setTimeout(() => {
      setMessages((oldMessages) => [...oldMessages, botResponse]);
    }, 1000);
  };
  
  
  const onAction = (event) => {
    if (event.action.type === "alert") {
      setMessages([
        ...messages,
        {
          author: user,
          text: event.action.value,
        },
      ]);
    }
  };
  

  const claudeInteraction = async (question) => {
    console.log("Question: ", question);
    const requestBody = createAiRequestBody(question);
    console.log("Request Body: ", requestBody);
    const aiResponse = await createClaude({requestBody});
    console.log("AI Response: ", aiResponse);
    return aiResponse;
  };


  const createAiRequestBody = (ideaText) => {
    const requestBody = {
      prompt: ideaText,
    };
    return requestBody;
  }


///////////////////////////////////////////////////////////////////////
  
const saveSceneAnimationColorizationChanges = async () => {
  setLoadingMessage(`Saving updates...`)
  setLoading(true);
  const requestBody = {
    storyId: storyId,
    sceneId: sceneId[0],
    sceneDesc: editableDescription,
    scenePrompt: editablePrompt,
    sceneScriptText: sceneScriptText,
  }
  
  const updateResponse = await updateScenes(sceneId, requestBody);
  setLoading(false);
}



  const handleTextChange = (e) => {
    setSceneText(e.target.value);
  };

  const handleRepromptClick = () => {
    notify("Reprompt button Disabled");
};

const handleDeleteClick = async () => {
  setLoadingMessage(`Deleting Animation...`)
  setLoading(true);
  const requestBody = {
    storyId: storyId,
    sceneId: sceneId[0],
    sceneAnimation: "",
  }
  
  const updateResponse = await updateScenes(sceneId, requestBody);
  const response = await getScenesById(sceneId);
  setSceneAnimation(response.sceneAnimation);
  setLoading(false);
};

const handleGenerateScene = async () => {
  //console.log("Trigger generate scene");
  setLoadingMessage(`Generating - This can take 1-2 minutes...`)
  setLoading(true);
  const generateScene = await repltest(sceneId);
  const response = await getScenesById(sceneId);
  setSceneAnimation(response.sceneAnimation);
  setLoading(false);
};


const handleGeneratePrompt = () => {
  notify("Generate Prompt button Disabled");
};

const handleBacktoAnimate = () => {
  navigate(`/storyboard?projectName=${encodeURIComponent(projectName)}&storyId=${encodeURIComponent(storyId)}`);
};


const validateFile = (file) => {
  const allowedFormats = ['image/png', 'image/jpeg', 'image/svg'];
  const maxSizeInBytes = 5 * 1024 * 1024; // 5 MB

  if (!allowedFormats.includes(file.type)) {
    notifyError('Only .png, .jpg, and .svg formats are allowed.');
    return false;
  }

  if (file.size > maxSizeInBytes) {
    notifyError('File size must be less than 5MB.');
    return false;
  }

  setErrorMessage('');
  return true;
};

const handleAddFile1 = async (event) => {
  const file = event.target.files[0];
  if (file) {
    if (validateFile(file)) {
      setSelectedFile(file);
      await uploadSourceFile1(file);
    }
  }
};

  const uploadSourceFile1 = async (file) => {
    //console.error('Upload started');
    setLoadingMessage('Upload Scene Animation... This will take a moment.');
    setLoading(true);
    const formData = new FormData();
    formData.append('file', file);

    try {
      const response = await uploadSceneAnimationSource1(sceneId, formData);
      console.error('Upload complete');
      setLoading(false);
      setLoadingMessage(null);
    } catch (error) {
      console.error('Error uploading file:', error);
      notifyError('Error uploading file. Please try again.');
    }
    const response = await getScenesById(sceneId);
      setSceneAnimationSource1(response.sceneAnimationSource1);   
    setLoading(false);
    setLoadingMessage(null);
  };

  const handleAddFile2 = async (event) => {
    const file = event.target.files[0];
    if (file) {
      if (validateFile(file)) {
        setSelectedFile(file);
        await uploadSourceFile2(file);
      }
    }
  };
  
    const uploadSourceFile2 = async (file) => {
      //console.error('Upload started');
      setLoadingMessage('Upload Scene Animation... This will take a moment.');
      setLoading(true);
      const formData = new FormData();
      formData.append('file', file);
  
      try {
        const response = await uploadSceneAnimationSource2(sceneId, formData);
        console.error('Upload complete');
        setLoading(false);
        setLoadingMessage(null);
      } catch (error) {
        console.error('Error uploading file:', error);
        notifyError('Error uploading file. Please try again.');
      }
      const response = await getScenesById(sceneId);
      setSceneAnimationSource2(response.sceneAnimationSource2);   
      setLoading(false);
      setLoadingMessage(null);
    };

    const handleAddFile3 = async (event) => {
    const file = event.target.files[0];
    if (file) {
      if (validateFileVideo(file)) {
        setSelectedFile(file);
        await uploadSourceFile3(file);
      }
    }
  };
  
    const uploadSourceFile3 = async (file) => {
      //console.error('Upload started');
      setLoadingMessage('Upload Scene Animation... This will take a moment.');
      setLoading(true);
      const formData = new FormData();
      formData.append('file', file);
  
      try {
        const response = await uploadSceneAnimationSource3(sceneId, formData);
        console.error('Upload complete');
        setLoading(false);
        setLoadingMessage(null);
      } catch (error) {
        console.error('Error uploading file:', error);
        notifyError('Error uploading file. Please try again.');
      }
      const response = await getScenesById(sceneId);
      setSceneAnimationSource3(response.sceneAnimationSource3);   
      setLoading(false);
      setLoadingMessage(null);
    };

const handleDeleteFile1 = async () => {
  setLoadingMessage(`Deleting Animation...`)
  setLoading(true);
  const requestBody = {
    storyId: storyId,
    sceneId: sceneId[0],
    sceneAnimationSource1: "",
  }
  
  const updateResponse = await updateScenes(sceneId, requestBody);
  const response = await getScenesById(sceneId);
  setSceneAnimationSource1(response.sceneAnimationSource1);
  setLoading(false);
};

const handleDeleteFile2 = async () => {
  setLoadingMessage(`Deleting Animation...`)
  setLoading(true);
  const requestBody = {
    storyId: storyId,
    sceneId: sceneId[0],
    sceneAnimationSource2: "",
  }
  
  const updateResponse = await updateScenes(sceneId, requestBody);
  const response = await getScenesById(sceneId);
  setSceneAnimationSource2(response.sceneAnimationSource2);
  setLoading(false);
};

const handleDeleteFile3 = async () => {
  setLoadingMessage(`Deleting Animation...`)
  setLoading(true);
  const requestBody = {
    storyId: storyId,
    sceneId: sceneId[0],
    sceneAnimationSource3: "",
  }
  
  
  const updateResponse = await updateScenes(sceneId, requestBody);
  const response = await getScenesById(sceneId);
  setSceneAnimationSource3(response.sceneAnimationSource3);
  setLoading(false);
};


  return (
    <EditStoryContext.Provider value={{ saveSceneAnimationColorizationChanges }}>
    <AppWrapper>
      <SynthHeader project={projectName} />
        <PageWrapper>
            {isLoading && (
                <ProgressOverlay>
                    <ProgressStatus>{loadingMessage}</ProgressStatus>
                    <Spinner />
                </ProgressOverlay>
            )}
            <ContentWrapper>
                <NavSidebar />
                <Main>
                    <PageTitle>Animation Colorization</PageTitle>
                    <AnimateSection>
                        <VideoContainer>
                            <VideoDetails>
                            <video
                              src={sceneAnimation}
                              autoPlay
                              muted
                              controls
                              style={{ border: 'none' }}
                            > Your browser does not support the video tag.
                              </video>
                                <Card>
                                  <PromptWrapper>
                                    {scene.act}, 
                                    Scene {editablePositionDetails}
                                  </PromptWrapper>
                                  <ButtonContainer>
                                    <ButtonWithTooltip
                                      onClick={handleRepromptClick}
                                      icon={IconRepeat}
                                      tooltipText="Reprompt"
                                      disabled={!animationExists}
                                    />
                                    <>
                                    <ButtonWithTooltip
                                      onClick={handleUploadClick}
                                      icon={IconUpload}
                                      tooltipText="Upload"
                                    />
                                    <input
                                        id="fileInput"
                                        type="file"
                                        style={{ display: 'none' }}
                                        onChange={handleFileChange}
                                      />
                                      {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
                                    </>
                                    <ButtonWithTooltip
                                      onClick={handleDeleteClick}
                                      icon={IconTrash}
                                      tooltipText="Delete"
                                      disabled={!animationExists}
                                    />
                                  </ButtonContainer>
                                </Card>
                            </VideoDetails>
                        </VideoContainer>
                        <AnimateDetails>
                            <SceneDescriptionLabel>Scene Description</SceneDescriptionLabel>
                            <SceneDescriptionValue>
                            <textarea
                            value={editableDescription}
                            onChange={handleDescriptionChange}
                            />
                            </SceneDescriptionValue>
                            <PromptLabel>
                              Scene Prompt
                              {/*<SubmitButtonSmall onClick={handleGeneratePrompt}>Autogenerate</SubmitButtonSmall>*/}
                            </PromptLabel>
                            <PromptValue>
                            <textarea
                            value={editablePrompt}
                            onChange={handlePromptChange}
                            />
                            </PromptValue>
                            <AnimationConfigContainer>
                                <FPSSection>

                                </FPSSection>
                            </AnimationConfigContainer>
                        </AnimateDetails>
                    </AnimateSection>
                    <CreateAnimationSection>
                      <CreateAnimationLabel>Color Frames</CreateAnimationLabel>
                      <ProjectCard>
                        <ProjectImageWrapper>
                          <ProjectImage src={sceneAnimationSource1 || defaultImage} />
                        </ProjectImageWrapper>
                        <ProjectHeader>
                          <ProjectActions>
                            <>
                            <ButtonWithTooltip
                              onClick={() => document.getElementById('fileInput1').click()}
                              icon={IconUpload}
                              tooltipText="Upload"
                            />
                            <input
                              id="fileInput1"
                              type="file"
                              style={{ display: 'none' }}
                              onChange={handleAddFile1}
                            />
                            <ButtonWithTooltip
                                onClick={handleDeleteFile1}
                                icon={IconTrash}
                                tooltipText="Delete"
                              />
                            </>
                          </ProjectActions>
                        </ProjectHeader>
                      </ProjectCard>
                      <ProjectCard>
                        <ProjectImageWrapper>
                          <ProjectImage src={sceneAnimationSource2 || defaultImage} />
                        </ProjectImageWrapper>
                        <ProjectHeader>
                          <ProjectActions>
                          <ButtonWithTooltip
                              onClick={() => document.getElementById('fileInput2').click()}
                              icon={IconUpload}
                              tooltipText="Upload"
                            />
                            <input
                              id="fileInput2"
                              type="file"
                              style={{ display: 'none' }}
                              onChange={handleAddFile2}
                            />
                              <ButtonWithTooltip
                                onClick={handleDeleteFile2}
                                icon={IconTrash}
                                tooltipText="Delete"
                              />
                          </ProjectActions>
                        </ProjectHeader>
                      </ProjectCard>
                      <VerticalDivider />
                      <SketchAnimationLabel>Sketch Animation</SketchAnimationLabel>
                      <ProjectCard>
                        <ProjectImageWrapper>
                          <video
                          src={sceneAnimationSource3 || defaultImage}
                          autoPlay
                          muted
                          controls
                          style={{ border: 'none' }}
                        > Your browser does not support the video tag.
                          </video>
                        </ProjectImageWrapper>
                        <ProjectHeader>
                          <ProjectActions>
                          <ButtonWithTooltip
                              onClick={() => document.getElementById('fileInput3').click()}
                              icon={IconUpload}
                              tooltipText="Upload"
                            />
                            <input
                              id="fileInput3"
                              type="file"
                              style={{ display: 'none' }}
                              onChange={handleAddFile3}
                            />
                              <ButtonWithTooltip
                                onClick={handleDeleteFile3}
                                icon={IconTrash}
                                tooltipText="Delete"
                              />
                          </ProjectActions>
                        </ProjectHeader>
                      </ProjectCard>
                      </CreateAnimationSection>
                    <NavButtons>
                    <BackButton onClick={handleBacktoAnimate}>Back To Storyboard</BackButton>
                      <SubmitButton onClick={handleGenerateScene} disabled={!sceneAnimationSource1 || !sceneAnimationSource2}>Generate Scene</SubmitButton>
                    </NavButtons>
                </Main>
                <RightSidebar>
                  <SidebarContent>
                    <AIHelpText>
                      Use AI to help you with your Scene.
                      <br />
                    </AIHelpText>
                <ChatInput>
                        <Chat
                        user={user}
                        messages={messages.map((msg) => ({
                          ...msg,
                          text: msg.loading ? <img src={loadingGifUrl} alt="Loading..." /> : msg.text,
                        }))}
                        onMessageSend={addNewMessage}
                        placeholder={"Type a message..."}
                        onActionExecute={onAction}
                        width={275}
                      />
                    </ChatInput>
                    <NavigationContainer>
                      {/*<DeleteIcon onClick={handleDeleteClick}>
                        <IconTrash color="#2159a2" size={24} />
                      </DeleteIcon>*/}
                    </NavigationContainer>
                  </SidebarContent>
                </RightSidebar>
            </ContentWrapper>
        </PageWrapper>
        <Divider />
        <SynthFooter />
    </AppWrapper>
    </EditStoryContext.Provider>
  );
}

const AppWrapper = styled.div`
  background-color: #fbfdff;
  display: flex;
  flex-direction: column;
  min-height: 100vh; // Ensure it takes the full viewport height
`;

const PageWrapper = styled.div`
  background-color: #fbfdff;
  display: flex;
  flex-direction: column;
  flex-grow: 1; // Allow it to grow and fill the remaining space
`;

const Divider = styled.div`
  border-color: rgba(33, 89, 162, 1);
  border-style: solid;
  border-width: 4px;
  background-color: #2159a2;
  z-index: 10;
  min-height: 4px;
  width: 100%;
  flex-shrink: 0; // Prevent it from shrinking
  margin-top: 37px;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const ProgressOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  right: 0;
  bottom: 0;
  background: rgba(205, 205, 205, 0.8);
  z-index: 7777;
  position: fixed;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  flex-wrap: wrap;
`;
  
const ProgressStatus = styled.div`
  display: block;
  width: 100%;
  font-weight: 400;
  font-size: 24px;
  line-height: 24px;
  margin-bottom: 8px;
  text-align: center;
  color: #011126;
`;

const ContentWrapper = styled.div`
  
  z-index: 10;
  margin-top: 6px;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-grow: 1; // Allow it to grow and fill the remaining space
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const NavSidebar = styled.aside`
  box-shadow: 3.24px 1.62px 5.832px rgba(0, 0, 0, 0.25);
  background-color: #f7f5f5;
  width: 120px;
  height: 650px;
`;

const Main = styled.main`
  align-items: flex-start;
  margin-top: 20px;
  width: 80%;
  overflow-x: auto; // Allows horizontal scrolling
  padding: 0 20px; // Add padding to prevent content from touching the edges
  flex-direction: column;
  flex-grow: 1; // Allow it to grow and fill the remaining space
  flex-shrink: 0; // Prevent it from shrinking
  display: flex;
  @media (max-width: 991px) {
    max-width: 80%;
    padding: 0 10px; // Adjust padding for smaller screens
  }
`;

const PageTitle = styled.h1`
  color: #011126;
  font: 700 24px 'Josefin Sans', sans-serif;
  text-align: center;
  width: 80%;
  margin: 0; // Remove default margin to help with centering
  display: flex;
  align-items: center; // Vertically center the text in the flex container
  justify-content: center; // Horizontally center the text in the flex container
  height: 24px;
`;

const RightSidebar = styled.aside`
    display: flex;
  flex-direction: column;
  line-height: normal;
  width: 15%;
  min-width: 300px;
  margin-left: 20px;
  flex-shrink: 0; // Prevent the sidebar from shrinking
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const AnimateSection = styled.section`
  display: flex;    
  flex-direction: row;  // Change to row to align items horizontally
  margin-top: 20px;
  height: 325px;
  width: 100%;  // Ensure it takes the full width
`;


const VideoContainer = styled.section`
    display: flex;
    width: 260px;
    height: 100%;  // Ensure it takes the full height
    padding-bottom: 20px;
    flex-direction: column;
`;

const VideoDetails = styled.section`
  background-color: #d9d9d9;
  box-shadow: 2px 4px 7px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  margin-top: auto; // Push the VideoDetails to the bottom of its container
`;

const Card = styled.article`
    border: 1px solid rgba(217, 217, 217, 1);
    background-color: #fbfdff;
    z-index: 10;
    display: flex;
    width: 100%;
    flex-direction: column;
    padding: 11px 0 11px 13px;
    margin-top: -20px; // Move it manually up by 20px
`;

const PromptWrapper = styled.p`
    font-family: Inter, sans-serif;
    display: flex;
    flex-direction: column; // Arrange children in a column
    margin-bottom: 10px;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-right: 10px;
`;

const StyledButton = styled.button`
  position: relative;
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  width: 22px;
  opacity: ${props => (props.disabled ? 0.5 : 1)};
  pointer-events: ${props => (props.disabled ? 'none' : 'auto')};
  &:hover, &:focus {
    border: none;
    outline: none; /* This ensures that the button does not show the default focus outline */
  }
`;

const Tooltip = styled.div`
  position: absolute;
  top: -30px;
  left: 50%;
  transform: translateX(-50%);
  background-color: black;
  color: white;
  padding: 5px;
  border-radius: 3px;
  white-space: nowrap;
  font-size: 12px;
  opacity: ${props => (props.show ? 1 : 0)};
  transition: opacity 0.2s;
  pointer-events: none;
`;

const AnimateDetails = styled.section`
  display: flex;
  flex-direction: column;
  width: 80%;  // Adjust the width as necessary
  height: 100%;  // Ensure it takes the full height
  padding-left: 20px;  // Add some padding for separation
`;

const SceneDescriptionLabel = styled.label`
font: 600 18px 'Josefin Sans', sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const SceneDescriptionValue = styled.div`
  background-color: #fff;
  align-items: start;
  font: 400 16px 'Josefin Sans', sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
    padding-right: 20px;
  }
`;

const PromptLabel = styled.label`
margin-top: 10px;
font: 600 18px 'Josefin Sans', sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const PromptValue = styled.div`
  background-color: #fff;
  align-items: start;
  font: 400 16px 'Josefin Sans', sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
    padding-right: 20px;
  }
`;

const AnimationConfigContainer = styled.section`
  display: flex;
  flex-direction: row;
`;

const FPSSection = styled.div`

`;

const CreateAnimationSection = styled.section`
  display: flex;    
  flex-direction: row;
  width: 100%;
  margin-top: 20px;
`;


const CreateAnimationLabel = styled.label`
margin-top: 10px;
font: 600 18px 'Josefin Sans', sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const SketchAnimationLabel = styled.label`
margin-top: 10px;
margin-left 10px;
font: 600 18px 'Josefin Sans', sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const ScriptValue = styled.div`
  background-color: #fff;
  align-items: start;
  font: 400 16px 'Josefin Sans', sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
    padding-right: 20px;
  }
`;

const NavButtons = styled.section`
  display: flex;
  width: 100%;
  flex-direction: row;
  margin-bottom: 20px;
  justify-content: space-between;
  padding-right: 10px;
  margin-top: auto; // Push NavButtons to the bottom of Main
`;

const SubmitButton = styled.button`
  font-family: 'Josefin Sans', sans-serif;
  justify-content: center;
  border-radius: 15px;
  box-shadow: 3px 4px 6.1px 0px rgba(0, 0, 0, 0.25);
  background-color: #2159a2;
  align-self: center;
  margin-top: 20px;
  color: #fbfdff;
  font-weight: 400;
  white-space: nowrap;
  text-align: center;
  padding: 10px 20px;
  border: none;
  cursor: pointer;

  @media (max-width: 991px) {
    white-space: initial;
    padding: 10px 20px;
  }

  &:hover, &:focus {
    border: none;
    outline: none; /* This ensures that the button does not show the default focus outline */
    color: #fbfdff;
  }

    &:disabled {
    background-color: #a0a0a0; /* Change to your preferred disabled background color */
    cursor: not-allowed; /* Change the cursor to indicate the button is disabled */
  }
`;

const SubmitButtonSmall = styled.button`
  justify-content: center;
  border-radius: 15px;
  box-shadow: 3px 4px 6.1px 0px rgba(0, 0, 0, 0.25);
  background-color: #2159a2;
  align-self: center;
  margin-left: 5px;
  color: #fbfdff;
  font: 400 14px 'Josefin Sans', sans-serif;
  white-space: nowrap;
  text-align: center;
  padding: 3px 10px;
  border: none;
  cursor: pointer;

  @media (max-width: 991px) {
    white-space: initial;
    padding: 10px 20px;
  }

  &:hover, &:focus {
    border: none;
    outline: none; /* This ensures that the button does not show the default focus outline */
    color: #fbfdff;
  }
`;

const BackButton = styled.button`
  font-family: 'Josefin Sans', sans-serif;
  justify-content: center;
  border-radius: 15px;
  box-shadow: 3px 4px 6.1px 0px rgba(0, 0, 0, 0.25);
  background-color: #fff;
  align-self: center;
  margin-top: 20px;
  color: #2159a2;
  font-weight: 600;
  white-space: nowrap;
  text-align: center;
  padding: 10px 20px;
  border: none;
  cursor: pointer;

  @media (max-width: 991px) {
    white-space: initial;
    padding: 10px 20px;
  }
  
  &:hover, &:focus {
    border: none;
    outline: none; /* This ensures that the button does not show the default focus outline */
    color: #2159a2;
  }
`;

const SidebarContent = styled.div`
  background-color: #f7f5f5;
  display: flex;
  width: 100%;
  flex-grow: 1; // Allow it to grow and fill the remaining space
  flex-shrink: 0; // Prevent it from shrinking
  flex-direction: column;
  margin: 0 auto;
  padding: 10px 10px;
  @media (max-width: 991px) {
    margin-top: 40px;
    padding: 0 20px;
  }
`;

const SidebarTitle = styled.h2`
  color: #000;
  font: 600 18px 'Josefin Sans', sans-serif;
`;

const DropdownButton = styled.button`
  border: 1px solid rgba(217, 217, 217, 1);
  background-color: ${(props) => (props.selected ? "#e0e0e0" : "#fff")};
  display: flex;
  margin-top: 9px;
  gap: 20px;
  font-size: 15px;
  color: #000;
  font-weight: 400;
  justify-content: space-between;
  padding: 10px 16px 10px 12px;
  cursor: pointer;
  img {
    aspect-ratio: 1.79;
    object-fit: auto;
    object-position: center;
    width: 16px;
    margin: auto 0;
  }
`;

const DropdownLabel = styled.span`
  font-family: 'Josefin Sans', sans-serif;
`;

const SectionTitle = styled.h3`
  color: #000;
  margin-top: 40px;
  font: 600 18px 'Josefin Sans', sans-serif;
`;

const AIHelpText = styled.p`
  color: #24777d;
  margin-top: 28px;
  font: 700 18px 'Josefin Sans', sans-serif;
`;

const AIHelpSubtext = styled.p`
  color: #011126;
  margin-top: 14px;
  font: 400 14px 'Josefin Sans', sans-serif;
`;

const UserInputField = styled.textarea`
  justify-content: flex-end;
  align-items: start;
  border: 1px solid rgba(129, 129, 138, 1);
  background-color: #fff;
  margin-top: 15px;
  color: #81818a;
  padding: 29px 25px 170px;
  font: 400 15px 'Josefin Sans', sans-serif;
  @media (max-width: 991px) {
    padding: 0 20px 40px;
  }
`;


const NavigationContainer = styled.nav`
  display: flex;
  margin-top: 20px;
  gap: 20px;
  justify-content: space-between;
  flex-shrink: 0; // Prevent it from shrinking
  @media (max-width: 991px) {
    margin-top: 40px;
  }
`;


const DeleteIcon = styled.button`
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  box-shadow: 3px 3px 9.2px 0px rgba(0, 0, 0, 0.25);
  border: 1px solid rgba(217, 217, 217, 1);
  background-color: #fff;
  display: flex;
  padding: 10px 20px;
  cursor: pointer;
  flex-shrink: 0; // Prevent it from shrinking
  img {
    aspect-ratio: 0.85;
    object-fit: auto;
    object-position: center;
    width: 23px;
    fill: #2159a2;
  }
`;

const ChatInput = styled.div`
  height: 500px;
`;

const ProjectCard = styled.article`
  display: flex;
  flex-direction: column;
  width: 300px;
  max-height: 275px;
  max-width: 100%;
  margin: 30px 0 0 20px;
  padding: 29px 9px 14px;
  border: 1px solid #d9d9d9;
  border-radius: 15px;
  background-color: #fff;
  box-shadow: 3px 4px 6px 0 rgba(0, 0, 0, 0.25);
  overflow: hidden;  // Ensure content does not overflow the card
`;

const ProjectHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  gap: 20px;
`;

const ProjectDetails = styled.div`
  display: flex;
  flex-direction: column;
`;

const ProjectTitle = styled.h3`
  color: #2159a2;
  font-size: 20px;
  font-weight: 700;
`;

const ProjectLastOpened = styled.p`
  color: #81818a;
  font-size: 12px;
  font-weight: 400;
`;

const ProjectActions = styled.div`
  display: flex;
  gap: 9px;
`;

const IconWrapper = styled.div`
  display: flex;
  gap: 9px;
`;

const OptionsIcon = styled.img`
  aspect-ratio: 0.32;
  object-fit: contain;
  width: 6px;
  align-self: start;
`;

const ProjectImageWrapper = styled.div`
  flex-grow: 1;  // Allow the image to take up remaining space
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;  // Ensure the image does not overflow the wrapper
  width: 100%;
`;

const ProjectImage = styled.img`
  width: 100%;
  max-width: 100%;
  height: auto;
  aspect-ratio: 1.27;
  object-fit: contain;
  display: block;
  margin-top: 21px;
`;

const VerticalDivider = styled.div`
  border-color: rgba(33, 89, 162, 1);
  border-style: solid;
  border-width: 2px;
  background-color: #2159a2;
  align-self: start;
  width: 1px;
  height: 325px;
  margin-left: 25px;
`;

export default AnimateColorize;