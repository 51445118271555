//import { CUSTOM_PAGE_SIZE } from "../../constants/constants";
import { getHeaders } from "../Item/utils";
const { REACT_APP_API_URL } = process.env;

export const getScenes = async (storyId) => {
  try {
    const { headers } = await getHeaders();
    const response = await fetch(`${REACT_APP_API_URL}story/scene/list/${storyId}`, {
      method: "GET",
      headers,
    });
    return response.json();
  } catch (err) {
    console.error(err);
  }
};

export const createScene = async ({ requestBody }) => {
  try {
    const { headers } = await getHeaders();
    const response = await fetch(`${REACT_APP_API_URL}story/scene/create`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        ...headers,
      },
      body: JSON.stringify(requestBody),
    });
    return response.json();
  } catch (error) {
    console.log(error);
  }
  };

  export const updateScene = async (id, { requestBody }) => {
    try {
      const { headers } = await getHeaders();
      const response = await fetch(`${REACT_APP_API_URL}story/scene/update/:id`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          ...headers,
        },
        body: JSON.stringify(requestBody),
      });
      return response.json();
    } catch (error) {
      console.log(error);
    }
    };

    export const importScenes = async (id) => {
      try {
        const { headers } = await getHeaders();
        const response = await fetch(`${REACT_APP_API_URL}story/scene/import/${id}`, {
          method: "POST",
          headers,
        });

        return response;
      } catch (err) {
        console.error(err);
      }
    };

    export const deleteScene = async (id) => {
      try {
        const { headers } = await getHeaders();
        const response = await fetch(`${REACT_APP_API_URL}story/scene/delete/${id}`, {
          method: "POST",
          headers,
        });
        return response;
      } catch (err) {
        console.error(err);
      }
    };

    export const getScenesById = async (id) => {
      try {
        const { headers } = await getHeaders();
        const response = await fetch(`${REACT_APP_API_URL}story/scene/list/scene/${id}`, {
          method: "GET",
          headers,
        });
        return response.json();
      } catch (err) {
        console.error(err);
      }
    };
    
      export const getScenesDesign = async (id) => {
        try {
          const { headers } = await getHeaders();
          const response = await fetch(`${REACT_APP_API_URL}story/character/design/${id}`, {
            method: "GET",
            headers,
          });
          return response.json();
        } catch (err) {
          console.error(err);
        }
      };

      export const updateScenes = async (id, requestBody) => {
        try {
          const { headers } = await getHeaders();
          const response = await fetch(`${REACT_APP_API_URL}story/scene/update/${id}`, {
            method: "POST",
          headers: {
            "Content-Type": "application/json",
            ...headers,
          },
          body: JSON.stringify(requestBody),
        });
        return response.json();
      } catch (error) {
          console.error("Scene Update Failed");
        }
      };

      export const uploadSceneImage = async (id, formData) => {
        try {
          const { headers } = await getHeaders();
          const response = await fetch(`${REACT_APP_API_URL}story/scene/update/image/${id}`, {
            method: "PUT",
            headers,
            body: formData,
          });
          return response;
        } catch (err) {
          console.error(err);
        }
      };

      export const uploadSceneAnimation = async (id, formData) => {
        try {
          const { headers } = await getHeaders();
          const response = await fetch(`${REACT_APP_API_URL}story/scene/update/animation/${id}`, {
            method: "PUT",
            headers,
            body: formData,
          });
          return response;
        } catch (err) {
          console.error(err);
        }
      };

      export const uploadSceneAnimationSource1 = async (id, formData) => {
        try {
          const { headers } = await getHeaders();
          const response = await fetch(`${REACT_APP_API_URL}story/scene/update/animationsource1/${id}`, {
            method: "PUT",
            headers,
            body: formData,
          });
          return response;
        } catch (err) {
          console.error(err);
        }
      };

      export const uploadSceneAnimationSource2 = async (id, formData) => {
        try {
          const { headers } = await getHeaders();
          const response = await fetch(`${REACT_APP_API_URL}story/scene/update/animationsource2/${id}`, {
            method: "PUT",
            headers,
            body: formData,
          });
          return response;
        } catch (err) {
          console.error(err);
        }
      };

      export const uploadSceneAnimationSource3 = async (id, formData) => {
        try {
          const { headers } = await getHeaders();
          const response = await fetch(`${REACT_APP_API_URL}story/scene/update/animationsource3/${id}`, {
            method: "PUT",
            headers,
            body: formData,
          });
          return response;
        } catch (err) {
          console.error(err);
        }
      };

      export const repltest = async (id) => {
        try {
          const { headers } = await getHeaders();
          const response = await fetch(`${REACT_APP_API_URL}story/repltest/${id}`, {
            method: "GET",
            headers,
          });
          return response;
        } catch (err) {
          console.error(err);
        }
      };