import React, { useRef, useEffect, useState } from "react";
import styled from "styled-components";
import { notify, notifyError } from "../../helpers/toastNotificationService"
import { useNavigate, useLocation } from "react-router-dom";
import SynthFooter from "../../components/synth/Footer";
import SynthHeader from "../../components/synth/Header";
import Spinner from "../../components/layouts/Spinner/Spinner";
import EditStoryContext from './EditStoryContext';
import { getStoriesById } from "../../api/synth/getstories";

const pixelOptions = ["Pixels"];
const frameRateOptions = ["32 FPS"];
const videoQualityOptions = ["HD (1080)"];
const videoFormatOptions = [".mp4"];
const audioQualityOptions = ["High"];
const audioChannelOptions = ["Stereo"];
const audioFormatOptions = [".mp3"];
const audioSampleRateOptions = ["48000 Hz"];


function Review() {
    const location = useLocation();
    const navigate = useNavigate();
    const queryParams = new URLSearchParams(location.search);
    const [isLoading, setLoading] = useState(false);
    const [loadingMessage, setLoadingMessage] = useState(null);
    const [videoText, setVideoText] = useState("");
    const [story, setStory] = React.useState("");
    const [pixel, setPixel] = React.useState(pixelOptions[0]);
    const [frameRate, setFrameRate] = React.useState(frameRateOptions[0]);
    const [videoQuality, setVideoQuality] = React.useState(videoQualityOptions[0]);
    const [videoFormat, setVideoFormat] = React.useState(videoFormatOptions[0]);
    const [audioQuality, setAudioQuality] = React.useState(audioQualityOptions[0]);
    const [audioFormat, setAudioFormat] = React.useState(audioFormatOptions[0]);
    const [audioChannel, setAudioChannel] = React.useState(audioChannelOptions[0]);
    const [audioSampleRate, setAudioSampleRate] = React.useState(audioSampleRateOptions[0]);
    const [pixelDropdownOpen, setPixelDropdownOpen] = React.useState(false);
    const [frDropdownOpen, setFRDropdownOpen] = React.useState(false);
    const [videoQualityDropdownOpen, setVideoQualityDropdownOpen] = React.useState(false);
    const [videFormatDropdownOpen, setVideoFormatDropdownOpen] = React.useState(false);
    const [audioQualityDropdownOpen, setAudioQualityDropdownOpen] = React.useState(false);
    const [audioFormatDropdownOpen, setAudioFormatDropdownOpen] = React.useState(false);
    const [audioChannelDropdownOpen, setAudioChannelDropdownOpen] = React.useState(false);
    const [audioSampleRateDropdownOpen, setAudioSampleRateDropdownOpen] = React.useState(false);
    const [videoUrl, setVideoUrl] = React.useState("");
  
    const projectName = queryParams.get('projectName');
    const storyId = queryParams.get('storyId');
    
    const togglePixelDropdown = () => {
      setPixelDropdownOpen(!pixelDropdownOpen);
    };

    const toggleFRDropdown = () => {
      setFRDropdownOpen(!frDropdownOpen);
    };

    const toggleVideoQualityDropdown = () => {
      setVideoQualityDropdownOpen(!videoQualityDropdownOpen);
    };

    const toggleVideoFormatDropdown = () => {
      setVideoFormatDropdownOpen(!videFormatDropdownOpen);
    };

    const toggleAudioQualityDropdown = () => {
      setAudioQualityDropdownOpen(!audioQualityDropdownOpen);
    };

    const toggleAudioFormatDropdown = () => {
      setAudioFormatDropdownOpen(!audioFormatDropdownOpen);
    };

    const toggleAudioChannelDropdown = () => {
      setAudioChannelDropdownOpen(!audioChannelDropdownOpen);
    };

    const toggleAudioSampleRateDropdown = () => {
      setAudioSampleRateDropdownOpen(!audioSampleRateDropdownOpen);
    };


    React.useEffect(() => {
      setLoading(true);
      const retrieveStory = async () => {
        try {
              const response = await getStoriesById(storyId);
              setStory(response);
              if (response.video) {
                setVideoUrl(response.video);
              } else {
                setVideoUrl("https://alsynthprod.s3.amazonaws.com/VideoPlaceholderImage2.png");  
              }
          } catch (error) {
              console.error(error);
              notifyError("Failed to load story");
          } finally {
              setLoading(false);
          }
      };
      retrieveStory();
  }, [storyId]); // Add storyId as a dependency to refetch when it changes


    /*React.useEffect(() => {
        const retrieveReviewSettings = async () => {
          setLoading(true);  
          try {
                console.log("Getting Review Settings");
                //const response = await getReviewSettings(storyId);
                //console.log('Review response:', response);
                //setReview(response);
                //setEditableLocation(response.sceneLocation);
                //setEditableDescription(response.sceneDesc);
                //setEditablePositionDetails(response.sceneOrder);
                //setAct(response.act);
                //if (!response.sceneImage) {
                //  setShowGenerateButton(true);
                //} else {
                //  setShowGenerateButton(false);
                //}
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false);
                setLoadingMessage(null);
            }
        };
        retrieveReviewSettings();
    }, [storyId]); // Add storyId as a dependency to refetch when it changes*/


    
    const saveReviewChanges = async () => {

    };

    const handlePixelSelect = (option) => {
      setPixel(option);
      setPixelDropdownOpen(false);
    };

    const handleFrameRateSelect = (option) => {
      setFrameRate(option);
      setFRDropdownOpen(false);
    };

    const handleVideoQualitySelect = (option) => {
      setVideoQuality(option);
      setVideoQualityDropdownOpen(false);
    };

    const handleVideoFormatSelect = (option) => {
      setVideoFormat(option);
      setVideoFormatDropdownOpen(false);
    };

    const handleAudioQualitySelect = (option) => {
      setAudioQuality(option);
      setAudioQualityDropdownOpen(false);
    };

    const handleAudioChannelSelect = (option) => {
      setAudioChannel(option);
      setAudioChannelDropdownOpen(false);
    };

    const handleAudioFormatSelect = (option) => {
      setAudioFormat(option);
      setAudioFormatDropdownOpen(false);
    };

    const handleAudioSampleRateSelect = (option) => {
      setAudioSampleRate(option);
      setAudioSampleRateDropdownOpen(false);
    };

    const handleButtonClick = () => {
      notify("This feature is not currently active")
    };

    const handleCertofAuth = () => {
      notify("This feature is not currently active")
    };

    const handleScriptExpand = () => {
      
    };
    
    const handleScriptDownload = () => {
      notify("This feature is not currently active")
    };

    const handleVideoNameChange = (event) => {
      setVideoText(event.target.value);
    };

    const handleExportVideoClick = async () => {
      if (!videoText || !videoFormat) {
        notifyError("Please enter a video name and select a video format.");
        return;
      }
    
      const fileName = `${videoText}${videoFormat}`;    
    
      try {
        const response = await fetch(videoUrl);
        if (!response.ok) {
          throw new Error("No Video was included in the response");
        }
        const blob = await response.blob();
        const blobUrl = URL.createObjectURL(blob);
    
        const link = document.createElement('a');
        link.href = blobUrl;
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    
        // Revoke the object URL after download
        URL.revokeObjectURL(blobUrl);
    
      } catch (error) {
        console.error("Failed to download video:", error);
        notifyError("Failed to download video");
      }
    };
    
    
           
  
    return (
      <EditStoryContext.Provider value={{ saveReviewChanges }}>
        <AppWrapper>
          <SynthHeader project={projectName} />
          <PageWrapper>
            {isLoading && (
              <ProgressOverlay>
                <ProgressStatus>{loadingMessage}</ProgressStatus>
                <Spinner />
              </ProgressOverlay>
            )}
          
            <SectionHeader>
              <SectionTitle>Review & Export</SectionTitle>
            </SectionHeader>
            <Main>
              <ProjectSummarySection>
                <SummaryHeader>
                  <SummaryTitle>Project Summary</SummaryTitle>
                  <ToolTip src="https://alsynthprod.s3.amazonaws.com/tooltip.png" />
                  <SectionButtons>
                    <ExportAllButton onClick={handleButtonClick}>
                      <ButtonIcon src="https://alsynthprod.s3.amazonaws.com/DownloadIcon.png" />
                      <ButtonText>Export All Files</ButtonText>
                    </ExportAllButton>
                  </SectionButtons>
                </SummaryHeader>
                <SummaryBlock>
                  <SummaryDetails>
                    <Detail>
                      AI Generated: <StrongBlue>40%</StrongBlue>
                    </Detail>
                    <Detail>
                      Time Spent: <StrongBlue>4h</StrongBlue>
                    </Detail>
                    <Detail>
                      Data Example: <StrongBlue>xx</StrongBlue>
                    </Detail>
                    <CertificateButton onClick={handleCertofAuth}>
                      Certificate of Authenticity
                    </CertificateButton>
                  </SummaryDetails>
                  <AiImageCont>
                    <AiImage src="https://alsynthprod.s3.amazonaws.com/AIProgress.jpg" />
                  </AiImageCont>
                </SummaryBlock>
                <SubSectionsDivider />
                <SubSections>
                  <SubSection>
                    <SubSectionItem onClick={handleScriptExpand}>
                      <SubSectionIcon src="https://alsynthprod.s3.amazonaws.com/downArrow.png" />
                      <SubSectionText>Script</SubSectionText>
                    </SubSectionItem>
                    <SubSectionRightIcon src="https://alsynthprod.s3.amazonaws.com/DownloadIcon.png" onClick={handleScriptDownload} />
                  </SubSection>
                  <SubSectionsDivider />
                  <SubSection>
                    <SubSectionItem onClick={handleScriptExpand}>
                      <SubSectionIcon src="https://alsynthprod.s3.amazonaws.com/downArrow.png" />
                      <SubSectionText>Characters</SubSectionText>
                    </SubSectionItem>
                    <SubSectionRightIcon src="https://alsynthprod.s3.amazonaws.com/DownloadIcon.png" onClick={handleScriptDownload} />
                  </SubSection>
                  <SubSectionsDivider />
                  <SubSection>
                    <SubSectionItem onClick={handleScriptExpand}>
                      <SubSectionIcon src="https://alsynthprod.s3.amazonaws.com/downArrow.png" />
                      <SubSectionText>Storyboard</SubSectionText>
                    </SubSectionItem>
                    <SubSectionRightIcon src="https://alsynthprod.s3.amazonaws.com/DownloadIcon.png" onClick={handleScriptDownload} />
                  </SubSection>
                  <SubSectionsDivider />
                  <SubSection>
                    <SubSectionItem onClick={handleScriptExpand}>
                      <SubSectionIcon src="https://alsynthprod.s3.amazonaws.com/downArrow.png" />
                      <SubSectionText>Animation</SubSectionText>
                    </SubSectionItem>
                    <SubSectionRightIcon src="https://alsynthprod.s3.amazonaws.com/DownloadIcon.png" onClick={handleScriptDownload} />
                  </SubSection>
                  <SubSectionsDivider />
                  <SubSection>
                    <SubSectionItem onClick={handleScriptExpand}>
                      <SubSectionIcon src="https://alsynthprod.s3.amazonaws.com/downArrow.png" />
                      <SubSectionText>Sound Design</SubSectionText>
                    </SubSectionItem>
                    <SubSectionRightIcon src="https://alsynthprod.s3.amazonaws.com/DownloadIcon.png" onClick={handleScriptDownload} />
                  </SubSection>
                  <SubSectionsDivider />
                </SubSections>
              </ProjectSummarySection>
              <ReviewandExportSection>
                <ReviewandExportHeader>
                  <SummaryTitle>Preview</SummaryTitle>
                </ReviewandExportHeader>
                <PreviewBox>
                  <PreviewVideo
                    src={videoUrl}
                    frameborder="0"
                    allow="autoplay; fullscreen"
                    allowfullscreen
                  ></PreviewVideo>
                </PreviewBox>
                <ReviewandExportHeader>
                  <SummaryTitle>Export Settings</SummaryTitle>
                  <ToolTip src="https://alsynthprod.s3.amazonaws.com/tooltip.png" />
                  <SectionButtons>
                  <ExportAllButton onClick={handleExportVideoClick}>
                    <ButtonIcon src="https://alsynthprod.s3.amazonaws.com/DownloadIcon.png" />
                    <ButtonText>Export Video</ButtonText>
                  </ExportAllButton>
                  </SectionButtons>
                </ReviewandExportHeader>
                <VideoSection>
                  <VideoNameLabel>Video Name</VideoNameLabel>
                  <VideoNameArea
                    value={videoText}
                    onChange={handleVideoNameChange}
                    placeholder="Video Name"
                  />
                </VideoSection>
                <FrameRateSection>
                  <FrameRateAndQualityContainer>
                    <FrameRateColumns>
                      <FrameRateColumn>
                        <ColumnTitle>Frame Size</ColumnTitle>
                        <FrameSizeControls>
                          <SizeInput defaultValue="1280" />
                          <SizeDivider>X</SizeDivider>
                          <SizeInput defaultValue="720" />
                          <DropDownSection>
                            <OptionValue onClick={togglePixelDropdown}>
                              <OptionText>{pixel}</OptionText>
                              <OptionIcon src="https://alsynthprod.s3.amazonaws.com/downArrow.png" alt="" />
                            </OptionValue>
                            {pixelDropdownOpen && (
                              <DropdownMenu>
                                {pixelOptions.map((option, index) => (
                                  <DropdownItem key={index} onClick={() => handlePixelSelect(option)}>
                                    {option}
                                  </DropdownItem>
                                ))}
                              </DropdownMenu>
                            )}
                          </DropDownSection>
                        </FrameSizeControls>
                        <VideoQualityControls>
                          <DropDownSection>
                          <ColumnTitle>Frame Rate</ColumnTitle>
                            <OptionValue onClick={toggleFRDropdown}>
                              <OptionText>{frameRate}</OptionText>
                              <OptionIcon src="https://alsynthprod.s3.amazonaws.com/downArrow.png" alt="" />
                            </OptionValue>
                            {frDropdownOpen && (
                              <DropdownMenu>
                                {frameRateOptions.map((option, index) => (
                                  <DropdownItem key={index} onClick={() => handleFrameRateSelect(option)}>
                                    {option}
                                  </DropdownItem>
                                ))}
                              </DropdownMenu>
                            )}
                          </DropDownSection>
                          <DropDownSection>
                          <ColumnTitle>Video Quality</ColumnTitle>
                            <OptionValue onClick={toggleVideoQualityDropdown}>
                              <OptionText>{videoQuality}</OptionText>
                              <OptionIcon src="https://alsynthprod.s3.amazonaws.com/downArrow.png" alt="" />
                            </OptionValue>
                            {videoQualityDropdownOpen && (
                              <DropdownMenu>
                                {videoQualityOptions.map((option, index) => (
                                  <DropdownItem key={index} onClick={() => handleVideoQualitySelect(option)}>
                                    {option}
                                  </DropdownItem>
                                ))}
                              </DropdownMenu>
                            )}
                          </DropDownSection>
                          <DropDownSection>
                          <ColumnTitle>Video Format</ColumnTitle>
                            <OptionValue onClick={toggleVideoFormatDropdown}>
                              <OptionText>{videoFormat}</OptionText>
                              <OptionIcon src="https://alsynthprod.s3.amazonaws.com/downArrow.png" alt="" />
                            </OptionValue>
                            {videFormatDropdownOpen && (
                              <DropdownMenu>
                                {videoFormatOptions.map((option, index) => (
                                  <DropdownItem key={index} onClick={() => handleVideoFormatSelect(option)}>
                                    {option}
                                  </DropdownItem>
                                ))}
                              </DropdownMenu>
                            )}
                          </DropDownSection>
                        </VideoQualityControls>
                          
                      </FrameRateColumn>
                    </FrameRateColumns>
                    <AudioQualitySection>
                    <AudioQualityControls1>
                        <DropDownSection>
                          <ColumnTitle>Audio Quality</ColumnTitle>
                            <OptionValue onClick={toggleAudioQualityDropdown}>
                              <OptionText>{audioQuality}</OptionText>
                              <OptionIcon src="https://alsynthprod.s3.amazonaws.com/downArrow.png" alt="" />
                            </OptionValue>
                            {audioQualityDropdownOpen && (
                              <DropdownMenu>
                                {audioQualityOptions.map((option, index) => (
                                  <DropdownItem key={index} onClick={() => handleAudioQualitySelect(option)}>
                                    {option}
                                  </DropdownItem>
                                ))}
                              </DropdownMenu>
                            )}
                          </DropDownSection>
                          <DropDownSection>
                          <ColumnTitle>Audio Channel</ColumnTitle>
                            <OptionValue onClick={toggleAudioChannelDropdown}>
                              <OptionText>{audioChannel}</OptionText>
                              <OptionIcon src="https://alsynthprod.s3.amazonaws.com/downArrow.png" alt="" />
                            </OptionValue>
                            {audioChannelDropdownOpen && (
                              <DropdownMenu>
                                {audioChannelOptions.map((option, index) => (
                                  <DropdownItem key={index} onClick={() => handleAudioChannelSelect(option)}>
                                    {option}
                                  </DropdownItem>
                                ))}
                              </DropdownMenu>
                            )}
                            
                          </DropDownSection>
                          </AudioQualityControls1>
                          <AudioQualityControls1>
                          <DropDownSection>
                          <ColumnTitle>Audio Format</ColumnTitle>
                            <OptionValue onClick={toggleAudioFormatDropdown}>
                              <OptionText>{audioFormat}</OptionText>
                              <OptionIcon src="https://alsynthprod.s3.amazonaws.com/downArrow.png" alt="" />
                            </OptionValue>
                            {audioFormatDropdownOpen && (
                              <DropdownMenu>
                                {audioFormatOptions.map((option, index) => (
                                  <DropdownItem key={index} onClick={() => handleAudioFormatSelect(option)}>
                                    {option}
                                  </DropdownItem>
                                ))}
                              </DropdownMenu>
                            )}
                          </DropDownSection>
                          <DropDownSection>
                          <ColumnTitle>Sample Rate</ColumnTitle>
                            <OptionValue onClick={toggleAudioSampleRateDropdown}>
                              <OptionText>{audioSampleRate}</OptionText>
                              <OptionIcon src="https://alsynthprod.s3.amazonaws.com/downArrow.png" alt="" />
                            </OptionValue>
                            {audioSampleRateDropdownOpen && (
                              <DropdownMenu>
                                {audioSampleRateOptions.map((option, index) => (
                                  <DropdownItem key={index} onClick={() => handleAudioSampleRateSelect(option)}>
                                    {option}
                                  </DropdownItem>
                                ))}
                              </DropdownMenu>
                            )}
                          </DropDownSection>
                          </AudioQualityControls1>
                    </AudioQualitySection>
                  </FrameRateAndQualityContainer>
                </FrameRateSection>
              </ReviewandExportSection>
            </Main>
          </PageWrapper>
          <Divider />
          <SynthFooter />
        </AppWrapper>
      </EditStoryContext.Provider>
    );    
}

const AppWrapper = styled.div`
  background-color: #fbfdff;
  display: flex;
  flex-direction: column;
  min-height: 100vh; // Ensure it takes the full viewport height
`;

const PageWrapper = styled.div`
  background-color: #fbfdff;
  display: flex;
  flex-direction: column;
  flex-grow: 1; // Allow it to grow and fill the remaining space
`;

const Divider = styled.div`
  border-color: rgba(33, 89, 162, 1);
  border-style: solid;
  border-width: 4px;
  background-color: #2159a2;
  z-index: 10;
  min-height: 4px;
  width: 100%;
  flex-shrink: 0; // Prevent it from shrinking
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Main = styled.section`
  background: #fff;
  display: flex;
  flex-direction: row;
`;

const SectionHeader = styled.header`
  margin-top: 20px;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  color: #011126;
  font-weight: 700;
  text-align: center;
  padding: 0 20px;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
  }
`;

const SectionTitle = styled.h2`
  font-family: 'Josefin Sans', sans-serif;
  align-self: center;
  font-size: 20px;
  margin: 0;
  color: #000;
`;

const ProjectSummarySection = styled.section`
  display: flex;
  margin-top: 20px;
  width: 40%;
  flex-direction: column;
  padding: 0 30px 30px 65px;
  z-index: 10;
  @media (max-width: 991px) {
    padding: 0 20px;
    max-width: 100%;
  }
`;

const SummaryHeader = styled.div`
  display: flex;
  gap: 10px; /* Adjust gap as needed */
  font-size: 16px;
  font-weight: 600;
  color: #011126;
  padding: 0 2px;
  align-items: center;
  justify-content: space-between;
`;

const SummaryTitle = styled.h3`
  font-family: 'Josefin Sans', sans-serif;
  font-size: 20px;
  margin-right: 10px; /* Adjust space between title and tooltip */
`;

const ToolTip = styled.img`
  width: 16px;
  aspect-ratio: 1;
  object-fit: auto;
  object-position: center;
`;

const SectionButtons = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-left: auto; /* Pushes buttons to the right */
`;

const ExportAllButton = styled.button`
  display: flex;
  align-items: center;
  padding: 5px 22px;
  font-weight: 600;
  font-family: 'Josefin Sans', sans-serif;
  color: #2159a2;
  border: 1px solid #d9d9d9;
  border-radius: 11px;
  background: #fff;
  text-align: center;
  box-shadow: 1.48px 2.96px 4.514px 0 rgba(0, 0, 0, 0.25);
  @media (max-width: 991px) {
    padding: 0 20px;
  }
`;

const ButtonIcon = styled.img`
  width: 13px;
  aspect-ratio: 0.93;
  object-fit: auto;
  object-position: center;
`;

const ButtonText = styled.span`
  margin-left: 8px;
  font-size: 12px;
`;

const SummaryBlock = styled.div`
  display: flex;
  gap: 10px;
  font-size: 16px;
  font-weight: 600;
  color: #011126;
  padding: 0 2px;
  align-items: center;
  justify-content: space-between;
`;

const SummaryDetails = styled.div`
  display: flex;
  width: 50%;
  flex-direction: column;
  font-size: 14px;
  color: #2159a2;
  font-weight: 400;
  margin-top: 26px;
  @media (max-width: 991px) {
    margin-top: 20px;
  }
`;
const AiImageCont = styled.div`
  display: flex;
  width: 50%;
  flex-direction: column;
  align-items: center; /* Centers items horizontally */
  justify-content: center; /* Centers items vertically */
  margin-top: 26px;
  @media (max-width: 991px) {
    margin-top: 20px;
  }
`;

const AiImage = styled.img`
  width: 150px;
  aspect-ratio: 1;
  object-fit: auto;
  object-position: center;
`;

const Detail = styled.p`
  font-family: 'Josefin Sans', sans-serif;
  margin-top: 21px;
  
`;

const StrongBlue = styled.span`
  font-weight: 700;
  color: rgba(33, 89, 162, 1);
`;

const CertificateButton = styled.button`  
  margin-top: 21px;
  padding: 10px 22px;
  font-size: 14px;
  font-weight: 600;
  color: #2159a2;
  font-family: 'Josefin Sans', sans-serif;
  border: 1px solid #d9d9d9;
  border-radius: 11px;
  background: #fff;
  text-align: center;
  justify-content: center;
  box-shadow: 1.48px 2.96px 4.514px 0 rgba(0, 0, 0, 0.25);
  @media (max-width: 991px) {
    padding: 0 20px;
  }
`;

const SubSections = styled.section`
  display: flex;
  flex-direction: column;
  max-width: 100%;
`;

const SubSectionsDivider = styled.div`
  border: 1px solid #d9d9d9;
  background: #d9d9d9;
  margin-top: 20px;
  height: 1px;
`;

const SubSection = styled.div`
  display: flex;
  gap: 20px;
  font-size: 16px;
  font-weight: 600;
  color: #011126;
  justify-content: space-between;
  margin-top: 20px;
  white-space: nowrap;
  @media (max-width: 991px) {
    flex-wrap: wrap;
    white-space: initial;
  }
`;

const SubSectionItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  font-family: 'Josefin Sans', sans-serif;
  background: none;
  border: none;
`;

const SubSectionIcon = styled.img`
  width: 18px;
  height: auto;;
  object-fit: contain; /* Ensure the icon maintains its aspect ratio */
  `;

const SubSectionRightIcon = styled.img`
  width: 22px;
  height: auto;
  margin-right: 10px;
  object-fit: contain; /* Ensure the icon maintains its aspect ratio */
`;

const SubSectionText = styled.span`
  font-family: 'Josefin Sans', sans-serif;
  font-size: 20px;
  margin-left: 25px;
`;


const ReviewandExportSection = styled.section`
  display: flex;
  margin-top: 20px;
  width: 60%;
  flex-direction: column;
  padding: 0 30px 30px 65px;
  z-index: 10;
  @media (max-width: 991px) {
    padding: 0 20px;
    max-width: 100%;
  }
`;

const ReviewandExportHeader = styled.div`
  display: flex;
  gap: 10px; /* Adjust gap as needed */
  margin-top: 10px;
  font-size: 16px;
  font-weight: 600;
  color: #011126;
  padding: 0 2px;
  align-items: center;
  justify-content: space-between;
`;

const PreviewBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
  width: 90%;
  min-width: 650px;
  margin-top: 20px;
  background: rgba(217, 217, 217, 0.5);
  position: relative;
`;

const PreviewVideo = styled.iframe`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  border: none; /* Remove default border from iframe */
`;



const VideoSection = styled.div`

`;

const VideoNameLabel = styled.label`
  margin-top: 21px;
  font: 400 12px 'Josefin Sans', sans-serif;
`;

const VideoNameArea = styled.textarea`
  border: 1px solid #d9d9d9;
  background-color: #fff;
  width: 100%;
  flex: 1;
  margin-top: 5px;
  color: #000;
  padding: 8px 34px; 
  font-size: 16px;
  font-family: 'Josefin Sans', sans-serif;
  line-height: 1.5; 
  height: calc(1.5em + 16px); 
  resize: none; 
  overflow: hidden; 
  
  @media (max-width: 991px) {
    padding: 0px 20px; 
  }

`;
const FrameRateAndQualityContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
  width: 100%;
`;


const FrameRateSection = styled.section`
  margin-top: 0px;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const FrameRateColumns = styled.div`
  gap: 20px;
  display: flex;
  @media (max-width: 991px) {
    flex-direction: column;
    align-items: stretch;
    gap: 0px;
  }
`;

const FrameRateColumn = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 12px;
  color: #011126;
  font-weight: 400;
  flex-grow: 1;
  @media (max-width: 991px) {
    margin-top: 40px;
  }
`;

const ColumnTitle = styled.h4`
  font: 400 12px 'Josefin Sans', sans-serif;
  margin-top: 15px;
  margin-bottom: 5px;
`;

const FrameSizeControls = styled.div`
  display: flex;
  gap: 6px;
  color: #807f7f;
  white-space: nowrap;
  align-items: center; /* Vertically centers all child elements */
  @media (max-width: 991px) {
    white-space: initial;
  }
`;


const SizeInput = styled.input`
  border: 1px solid #d9d9d9;
  background-color: #fff;
  max-width: 80px;
  flex: 1;
  margin-top: 0; /* Remove top margin to align with DropDownSection */
  color: #81818a;
  font-size: 11px;
  font-family: 'Josefin Sans', sans-serif;
  line-height: 1.5; 
  height: 30px; 
  resize: none; 
  overflow: hidden;
  text-align: center;
  @media (max-width: 991px) {
    padding: 0px 20px; 
  }
`;

const SizeDivider = styled.span`
  color: #011126;
  font-family: 'Josefin Sans', sans-serif;
  font-weight: 600;
  margin: 0 8px; /* Adjust margin for spacing */
  display: flex;
  align-items: center; /* Vertically centers its content */
`;

const DropDownSection = styled.div`
  display: flex;
  flex-direction: column;
  font-weight: 400;
  width: 100px;
  white-space: nowrap;
  position: relative; /* Added to serve as the reference for the dropdown */
  margin-top: 0; /* Ensure alignment with SizeInput */
  @media (max-width: 991px) {
    white-space: initial;
  }
`;


const DropdownMenu = styled.div`
  border: 1px solid rgba(217, 217, 217, 1);
  background-color: #fff;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1;
  width: 100px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const DropdownItem = styled.div`
  padding: 10px;
  font-size: 12px;
  color: #000;
  cursor: pointer;
  &:hover {
    background-color: #f1f1f1;
  }
`;

const OptionValue = styled.div`
  border: 1px solid rgba(217, 217, 217, 1);
  background-color: #fff;
  display: flex;
  margin-top: 0; /* Remove top margin */
  gap: 20px;
  font-size: 11px;
  color: #000;
  justify-content: space-between;
  padding: 8px 12px;
  cursor: pointer; /* Added cursor pointer for clarity */
  align-items: center; /* Vertically centers text and icon */
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const OptionText = styled.span`
  font-family: 'Josefin Sans', sans-serif;
`;

const OptionIcon = styled.img`
  aspect-ratio: 1.85;
  object-fit: auto;
  object-position: center;
  width: 13px;
  margin: auto 0;
`;

const VideoQualitySection = styled.section`
  display: flex;
  flex-direction: column;
`;





















const FrameRateControl = styled.div`
  display: flex;
  margin-top: 7px;
  flex-direction: column;
  background: #fff;
  justify-content: center;
`;

const FrameRateInput = styled.span`
  font-family: 'Josefin Sans', sans-serif;
  padding: 9px 10px;
  display: flex;
  gap: 20px;
  background: #fff;
  border: 1px solid #d9d9d9;
`;

const FrameRateIcon = styled.img`
  width: 9px;
  aspect-ratio: 1.28;
  border: 1px solid #011126;
  stroke-width: 1.48px;
  stroke: #011126;
`;

const AudioQualitySection = styled.section`
  display: flex;
  flex-direction: column;
`;

const VideoQualityControls = styled.div`
  display: flex;
  align-items: center;
  gap: 18px;
  margin-top: 4px;
`;
const AudioQualityControls1 = styled.div`
  display: flex;
  align-items: center;
  gap: 18px;
  margin-top: 4px;
`;

const VideoQualityControl = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
  background: #fff;
  border: 1px solid #d9d9d9;
  padding: 8px;
`;

const VideoQualityText = styled.span`
  font-family: 'Josefin Sans', sans-serif;
`;

const VideoQualityIcon = styled.img`
  width: 11px;
  aspect-ratio: 1.56;
  border: 1px solid #011126;
  stroke-width: 1.48px;
  stroke: #011126;
`;

const VideoFormatControl = styled(VideoQualityControl)`
  padding: 8px 11px;
`;

const VideoFormatText = styled(VideoQualityText)``;

const VideoFormatIcon = styled(VideoQualityIcon)`
  width: 9px;
`;

const AudioSection = styled.section`
  display: flex;
  gap: 20px;
  font-size: 12px;
  color: #011126;
  font-weight: 400;
  @media (max-width: 991px) {
    flex-direction: column;
    gap: 0px;
    margin-top: 40px;
  }
`;

const AudioColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-top: 19px;
`;

const AudioControls = styled.div`
  display: flex;
  margin-top: 5px;
  flex-direction: column;
  justify-content: center;
  white-space: nowrap;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const AudioQualityControl = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;
  background: #fff;
  border: 1px solid #d9d9d9;
  padding: 8px 12px;
`;

const AudioQualityText = styled.span`
  font-family: 'Josefin Sans', sans-serif;
`;

const AudioQualityIcon = styled.img`
  width: 9px;
  aspect-ratio: 1.28;
  border: 1px solid #011126;
  stroke-width: 1.48px;
  stroke: #011126;
`;

const AudioFormatControl = styled(AudioQualityControl)``;

const AudioFormatText = styled(AudioQualityText)``;

const AudioFormatIcon = styled(AudioQualityIcon)`
  width: 10px;
  aspect-ratio: 1.43;
`;

const AudioChannelControl = styled(AudioQualityControl)`
  padding: 9px 18px;
`;

const AudioChannelText = styled(AudioQualityText)``;

const AudioChannelIcon = styled(AudioQualityIcon)``;

const SampleRateControl = styled(AudioQualityControl)`
  padding: 9px 12px;
  gap: 13px;
`;

const SampleRateText = styled(AudioQualityText)``;

const SampleRateIcon = styled.img`
  width: 10px;
  aspect-ratio: 1.43;
  border: 1px solid #011126;
  stroke-width: 1.48px;
  stroke: #011126;
`;
const ProgressOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  right: 0;
  bottom: 0;
  background: rgba(205, 205, 205, 0.8);
  z-index: 7777;
  position: fixed;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  flex-wrap: wrap;
`;
  
const ProgressStatus = styled.div`
  display: block;
  width: 100%;
  font-weight: 400;
  font-size: 24px;
  line-height: 24px;
  margin-bottom: 8px;
  text-align: center;
  color: #011126;
`;

export default Review;