//import { CUSTOM_PAGE_SIZE } from "../../constants/constants";
import { getHeaders } from "../Item/utils";
const { REACT_APP_API_URL } = process.env;

export const createClaude = async ({ requestBody }) => {
  try {
    const { headers } = await getHeaders();
    const response = await fetch(`${REACT_APP_API_URL}story/model/claudev2`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        ...headers,
      },
      body: JSON.stringify(requestBody),
    });
    
    const responseText = await response.text();
    return responseText;
  } catch (error) {
    console.error("Error in createClaude:", error);
    // Re-throw the error to allow further handling by the caller
    throw error;
  }
 
};

export const updateClaude = async ({ requestBody }) => {
  try {
    const { headers } = await getHeaders();
    const response = await fetch(`${REACT_APP_API_URL}story/model/claudev2/amend`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        ...headers,
      },
      body: JSON.stringify(requestBody),
    });
    const responseText = await response.text();
    //console.log("Response from API:", responseText);
    return responseText;
  } catch (error) {
    console.error("Error in createClaude:", error);
    // Re-throw the error to allow further handling by the caller
    throw error;
  }
 
};