import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useNavigate, useLocation } from "react-router-dom";
import { notifyError } from "../../helpers/toastNotificationService";
import SynthFooter from "../../components/synth/Footer";
import SynthHeader from "../../components/synth/Header";
import Spinner from "../../components/layouts/Spinner/Spinner";
import { getWorkflowsAll } from "../../api/synth/workflows";
import { Modal, Button, } from 'react-bootstrap';
import WorkflowEditor from "../../components/synth/EditWFModal";
import { createWorkflow, updateWorkflow } from "../../api/synth/workflows";

const TaskManager = () => {
  const [isLoading, setLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState(null);
  const [workflows, setWorkflows] = useState([]);
  const [currentWorkflow, setCurrentWorkflow] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const MajorCategories = ["Writers Room", "Character", "Storyboard", "Animatics", "Sounds Design"];

  useEffect(() => {
    const fetchWorkflows = async () => {
      setLoadingMessage('Fetching Tasks...');
      setLoading(true);
      try {
        const response = await getWorkflowsAll();
        if (Array.isArray(response) && response.length > 0) {
          setWorkflows(response);
        }
      } catch (error) {
        console.error('Error fetching tasks:', error);
        notifyError("Error obtaining tasks. Please refresh and try again");
      } finally {
        setLoadingMessage('');
        setLoading(false);
      }
    };

    fetchWorkflows();
  }, []);

  const extractTableData = () => {
    return workflows.map(workflow => ({
      wfName: workflow.wfName,
      wfDescrip: workflow.wfDescrip,
      wfCatMajor: workflow.wfCatMajor,
      wfCatMinor: workflow.wfCatMinor,
      workflowActive: workflow.workflowActive ? 'Active' : 'Inactive',
      workflow: workflow.workflow,
      id: workflow.id
    }));
  };

  const handleEdit = (workflow) => {
    setCurrentWorkflow(workflow);
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
    setCurrentWorkflow(null);
  };

  const handleSave = async (updatedWorkflow) => {
    try {
      const requestBody = updatedWorkflow;
      const id = updatedWorkflow.id;
      console.log("Status: ", updatedWorkflow.newWF);
      if (updatedWorkflow.newWF === true) {
        await createWorkflow(requestBody);
      }
      if (updatedWorkflow.newWF === false) {
        await updateWorkflow(id, requestBody);
      }
    } catch (error) {
      console.error("Error managing workflow: ", error);
    }
    setWorkflows(workflows.map(workflow => 
      workflow.id === updatedWorkflow.id ? updatedWorkflow : workflow
    ));
    setShowModal(false);
    window.location.reload();
  };

  const tableData = extractTableData();

  const handleNew = () => {
    setCurrentWorkflow(null);
    setShowModal(true);
  };

  return (
    <AppWrapper>
      <SynthHeader>Task Manager</SynthHeader>
      <PageBody>
        <Main>
          {isLoading && (
            <ProgressOverlay>
              <ProgressStatus>{loadingMessage}</ProgressStatus>
              <Spinner />
            </ProgressOverlay>
          )}
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <h2 style={{ fontSize: '24px' }}>Workflow Task Management</h2>
            <Button style={{ fontSize: '16px' }} onClick={() => handleNew()}>New Task</Button>
          </div>
          <br /><br />
          <div className="table-responsive">
            <table className="table">
              <thead className="sticky-top">
                <tr>
                  <th style={{ fontSize: '18px' }}>Name</th>
                  <th style={{ fontSize: '18px' }}>Description</th>
                  <th style={{ fontSize: '18px' }}>Category Major</th>
                  <th style={{ fontSize: '18px' }}>Category Minor</th>
                  <th style={{ fontSize: '18px' }}>Status</th>
                  <th style={{ fontSize: '18px' }}>Manage</th>
                </tr>
              </thead>
              <tbody>
                {tableData.map((row, index) => (
                  <tr key={index}>
                    <td style={{ fontSize: '16px' }}>{row.wfName}</td>
                    <td className="text-secondary" style={{ fontSize: '16px' }}>{row.wfDescrip}</td>
                    <td className="text-secondary" style={{ fontSize: '16px' }}>{row.wfCatMajor}</td>
                    <td className="text-secondary" style={{ fontSize: '16px' }}>{row.wfCatMinor}</td>
                    <td className="text-secondary" style={{ fontSize: '16px' }}>{row.workflowActive}</td>
                    <td className="text-secondary" style={{ fontSize: '16px' }}>
                      <Button onClick={() => handleEdit(row)}>Edit</Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </Main>
      </PageBody>
      <Divider />
      <SynthFooter />
      
      {showModal && (
        <Modal show={showModal} onHide={handleClose} backdrop="static" centered>
          <WorkflowEditor
            currentWorkflow={currentWorkflow}
            onClose={handleClose}
            onSave={handleSave}
            majorCategories={MajorCategories}
          />
        </Modal>
      )}
    </AppWrapper>
  );
};

const AppWrapper = styled.div`
  background-color: #fbfdff;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const PageBody = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  flex-grow: 1;
`;

const Divider = styled.div`
  border-color: rgba(33, 89, 162, 1);
  border-style: solid;
  border-width: 4px;
  background-color: #2159a2;
  z-index: 10;
  min-height: 4px;
  margin-top: 37px;
  width: 100%;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Main = styled.main`
  align-items: flex-start;
  margin-top: 42px;
  width: 80%;
  overflow-x: auto;
  padding: 0 20px;
  flex-grow: 1;
  flex-shrink: 0;
  @media (max-width: 991px) {
    max-width: 80%;
    margin-top: 40px;
    padding: 0 10px;
  }
`;

const ProgressOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  right: 0;
  bottom: 0;
  background: rgba(205, 205, 205, 0.8);
  z-index: 7777;
  position: fixed;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  flex-wrap: wrap;
`;

const ProgressStatus = styled.div`
  display: block;
  width: 100%;
  font-weight: 400;
  font-size: 24px;
  line-height: 24px;
  margin-bottom: 8px;
  text-align: center;
  color: #011126;
`;

export default TaskManager;
