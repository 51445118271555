//import { CUSTOM_PAGE_SIZE } from "../../constants/constants";
import { getHeaders } from "../Item/utils";
const { REACT_APP_API_URL } = process.env;

export const updateStories = async ({ requestBody }) => {
  try {
    const { headers } = await getHeaders();
    const response = await fetch(`${REACT_APP_API_URL}story/script/update`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        ...headers,
      },
      body: JSON.stringify(requestBody),
    });
    return response.json();
  } catch (error) {
    console.log(error);
  }
  };

  export const uploadStoryImage = async (id, formData) => {
    try {
      const { headers } = await getHeaders();
      const response = await fetch(`${REACT_APP_API_URL}story/update/image/${id}`, {
        method: "PUT",
        headers,
        body: formData,
      });
      return response;
    } catch (err) {
      console.error(err);
    }
  };