import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { useAuth0 } from "../../auth/Auth";
import { displayNameFormatter } from "../../helpers/formatters";
import useCheckMobileScreen from "../../hooks/useCheckMobileScreen";
import { useCustomNavigator } from "../../hooks/useCustomNavigator";
import useHashconnect from "../../hooks/useHashconnect";
import { signout } from "../../store/slices/user/user";

// Define synthUserMenu function
export const synthUserMenu = (data) => [
  /*{
    id: 1,
    sub: "Profile",
    links: "#",
  },
  {
    id: 2,
    sub: "Subscription",
    links: "#",
  },*/
  {
    id: 3,
    sub: "Tasks",
    links: "/tasks",
  },
  {
    id: 4,
    sub: "Task Manager",
    links: "/taskmanager",
  },
];

// Styled components definitions
const HeaderAdmin = styled.div`
  display: ${props => props.isMobile ? 'none' : 'flex'};
  cursor: pointer;
`;

const HeaderAvatar = styled.div`
  // Add specific styles for avatar container if needed
`;

const UserName = styled.div`
color: #011126;
text-align: center;
font-family: 'Josefin Sans', sans-serif;
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: 20px; /* 100% */
letter-spacing: 0.1px;
display: flex;       // Ensures content is inline
justify-content: space-between; // Spreads out the content
align-items: center; // Centers items vertically
width: 100%;         // Takes full width of its container
min-width: 200px;
`;


const AvatarPopup = styled.div`
  margin-top: 20px;
  display: ${props => props.isVisible ? 'block' : 'none'};
`;

const HorizontalRule = styled.div`
  // Add styles for the horizontal rule
`;

const LinkItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 20px;
  width: 100%;
  padding-left: 10px;
  font-family: 'Josefin Sans', sans-serif;;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 133.333% */
  letter-spacing: 0.1px;
`;

const LogoutLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 20px;
  width: 100%;
  padding-left: 10px;
  font-family: 'Josefin Sans', sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 133.333% */
  letter-spacing: 0.1px;
`;

const Icon = styled.i`
  // Style icons here if necessary
`;

const MenuProfile = () => {
  const isMobile = useCheckMobileScreen();
  const { customNavigate } = useCustomNavigator();
  const [visible, setVisible] = useState(false);
  const { disconnect } = useHashconnect();
  const dispatch = useDispatch();
  const { logout } = useAuth0();
  const user = useSelector((state) => state.user);
  const displayName = displayNameFormatter({ user });
  const menuItems = synthUserMenu(user);
  const arrow = !visible ? <span>&#8964;</span> : <span>&#8963;</span>;

  const handleMenu = () => {
    setVisible(!visible);
  };

  const handleLogout = async () => {
    localStorage.removeItem("persist:root");
    localStorage.removeItem("cart");
    await disconnect();
    dispatch(signout);
    await logout();
    customNavigate({ path: "/" });
    window.location.reload();
  };

   return (
    <HeaderAdmin isMobile={isMobile} onClick={handleMenu}>
      <HeaderAvatar>
        <UserName>
          <span>{displayName}</span>
          {arrow}
        </UserName>

        <AvatarPopup isVisible={visible}>
          {menuItems.map(item => (
            <LinkItem key={item.id}>
              <Icon className={item.icon}></Icon>
              <Link to={item.links}>{item.sub}</Link>
            </LinkItem>
          ))}
          <LogoutLink onClick={handleLogout} id="logout">
            <span> Logout</span>
          </LogoutLink>
        </AvatarPopup>
      </HeaderAvatar>
    </HeaderAdmin>
  );
};

export default MenuProfile;
