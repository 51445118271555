import * as React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { notify, notifyError } from "../../helpers/toastNotificationService"
import {
  ERROR_MISSING_TEXT,
  ERROR_TEXT_IN_BOTH,
} from "../../constants/constants";
import { createStories } from "../../api/synth/createstories"
import { createClaude } from "../../api/synth/claudeTestCreate"
import Spinner from "../../components/layouts/Spinner/Spinner";

const projects = [
  {

    guidance: "Some Guidance",
  },
];

const formatOptions = ["Animation", "Live Action", "Documentary"];
const lengthOptions = ["8 Minutes", "15 Minutes", "22 Minutes"];

const CreateStory = () => {
  const [projectName, setProjectName] = React.useState("");
  const [format, setFormat] = React.useState(formatOptions[0]);
  const [length, setLength] = React.useState(lengthOptions[0]);
  const [guidance, setGuidance] = React.useState(projects[0].guidance);
  const [generativeAI, setGenerativeAI] = React.useState(true);
  const [scriptText, setScriptText] = React.useState("");
  const [ideaText, setIdeaText] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const [generateText, setGenerateText] = React.useState("");
  const [dropdownOpen, setDropdownOpen] = React.useState(false);
  const [lengthDropdownOpen, setLengthDropdownOpen] = React.useState(false);

  const navigate = useNavigate();

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };
  
  const handleFormatSelect = (option) => {
    setFormat(option);
    setDropdownOpen(false);
  };

  const toggleLengthDropdown = () => {
    setLengthDropdownOpen(!lengthDropdownOpen);
  };
  
  const handleLengthSelect = (option) => {
    setLength(option);
    setLengthDropdownOpen(false);
  };

  const createRequestBody = (title, scriptText) => {
    const requestBody = {
        title: title,
        scriptText: scriptText
    };
    return requestBody;
  };

  const createAiRequestBody = (ideaText) => {
    const requestBody = {
      prompt: ideaText,
    };
    return requestBody;
  }

  const handleProjectNameChange = (event) => {
    setProjectName(event.target.value);
  };

 
  const handleFormatChange = (event) => {
    setFormat(event.target.value);
  };

  const handleLengthChange = (event) => {
    setLength(event.target.value);
  };

  const handleGuidanceChange = (event) => {
    setGuidance(event.target.value);
  };

  const handleGenerativeAIToggle = () => {
    setGenerativeAI(!generativeAI);
  };

  const handleScriptChange = (event) => {
    setScriptText(event.target.value);
  };

  const handleIdeaChange = (event) => {
    setIdeaText(event.target.value);
  };

  const handleUploadScript = () => {
    // Handle script upload logic here
    notify("This feature is not currently active")
  };

  const handleStartStory = async () => {
    setIsLoading(true);
    try {
        //console.log("Start story button clicked");

        if (!projectName) {
          //console.log("No Project Name provided");
          notifyError("Please enter a Project Name to Continue");
          setIsLoading(false);
          return; // Stop execution if no data is provided
        }
        
        if (!ideaText && !scriptText) {
            //console.log("No Text or AI Gen text data provided");
            notifyError(ERROR_MISSING_TEXT);
            setIsLoading(false);
            return; // Stop execution if no data is provided
        }

        if (ideaText && scriptText) {
            //console.log("Both text fields are populated");
            notifyError(ERROR_TEXT_IN_BOTH);
            setIsLoading(false);
            return; // Stop execution if both fields are populated
        }

        if (ideaText) {
          //console.log("AI Process started");
          const requestBody = createAiRequestBody(ideaText);
          const aiResponse = await createClaude({requestBody});
          //console.log("AI Prompt:", requestBody);
          const scriptTextFromAI = `"${aiResponse}"`;
          //console.log("AI Response:", scriptTextFromAI);
          const newRequestBody = createRequestBody(projectName, scriptTextFromAI);
          //console.log("newRequestBody:", newRequestBody);
          const response = await createStories({requestBody: newRequestBody});
          //console.log("response:", response);
          const storyId = response.id;
          //console.log("storyId:", storyId);
          navigate(`/editstory?projectName=${encodeURIComponent(projectName)}&storyId=${encodeURIComponent(storyId)}`);
          return;
        }

        if (scriptText) {
          //console.log("Pase Script Process started");
          const requestBody = createRequestBody(projectName, scriptText);
          const response = await createStories({requestBody});
          const storyId = response.id;
          //console.log("Response", response.id);
          navigate(`/editstory?projectName=${encodeURIComponent(projectName)}&storyId=${encodeURIComponent(storyId)}`);
          return;
        }
    } catch (error) {
        console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCancel = () => {
    navigate(`/synthprojects`);
  };

  return (
    <Container>
      <Title>Create A New Story</Title>
      <Subtitle>Project Name:</Subtitle>
      <ProjectNameInput
        value={projectName}
        onChange={handleProjectNameChange}
        placeholder="Example Project"
      />
      {isLoading && (
        <ProgressOverlay>
          <ProgressStatus>
            Creating Story... This will take a moment.
          </ProgressStatus>
          <Spinner />
        </ProgressOverlay>
      )}
      <HorizontalDivider />
      <MainContent>
        <UploadSection>
          <UploadTitle>Already have your story figured out?</UploadTitle>
          <UploadButton onClick={handleUploadScript}>
            Upload Your Script
          </UploadButton>
          <UploadText>
            <Bold>OR</Bold>
            <br />
            Paste your story or script here
          </UploadText>
          <PasteTextArea
            value={scriptText}
            onChange={handleScriptChange}
            placeholder="Copy and paste your story here..."
          />
        </UploadSection>
        <Divider />
        <GenerateSection>
          <GenerateTitle>Starting from scratch?</GenerateTitle>
          <GenerateSubtitle>AI can help you!</GenerateSubtitle>
          <GenerateText>Give us an idea of what you want.</GenerateText>
          <GenerateTextArea
            value={ideaText}
            onChange={handleIdeaChange}
            placeholder="Let us know about any characters, storylines, styles, and more that you would want to be included in your story. Remember to be as specific as possible, so that our AI can make your vision come true!"
          />
        </GenerateSection>
      </MainContent>
      <HorizontalDivider />
      <OptionsSection>
        <FormatSection>
          <OptionLabel>Format</OptionLabel>
          <OptionValue onClick={toggleDropdown}>
            <OptionText>{format}</OptionText>
            <OptionIcon src="https://alsynthprod.s3.amazonaws.com/downArrow.png" alt="" />
          </OptionValue>
          {dropdownOpen && (
            <DropdownMenu>
              {formatOptions.map((option, index) => (
                <DropdownItem key={index} onClick={() => handleFormatSelect(option)}>
                  {option}
                </DropdownItem>
              ))}
            </DropdownMenu>
          )}
        </FormatSection>
        <LengthSection>
          <OptionLabel>Length</OptionLabel>
          <OptionValue onClick={toggleLengthDropdown}>
            <OptionText>{length}</OptionText>
            <OptionIcon src="https://alsynthprod.s3.amazonaws.com/downArrow.png" alt="" />
          </OptionValue>
          {lengthDropdownOpen && (
            <DropdownMenu>
              {lengthOptions.map((option, index) => (
                <DropdownItem key={index} onClick={() => {
                  handleLengthSelect(option);
                  setLengthDropdownOpen(false); // Close the dropdown after selection
                }}>
                  {option}
                </DropdownItem>
              ))}
            </DropdownMenu>
          )}
        </LengthSection>
        {/*<GuidanceSection>
          <GuidanceLabel>What experience are you looking for?</GuidanceLabel>
          <GuidanceOptions>
            <GuidanceOption>Low Guidance</GuidanceOption>
            <GuidanceSlider>
              <GuidanceSliderTrack>
                <GuidanceSliderThumb>
                  <GuidanceSliderThumbTop />
                  <GuidanceSliderThumbBottom />
                </GuidanceSliderThumb>
                <GuidanceSliderDot />
              </GuidanceSliderTrack>
              <GuidanceSliderLabels>
                <GuidanceSliderLine />
                <GuidanceSliderText>Guided</GuidanceSliderText>
              </GuidanceSliderLabels>
            </GuidanceSlider>
            <GuidanceOption>{guidance}</GuidanceOption>
          </GuidanceOptions>
        </GuidanceSection>
        <GenerativeAISection>
          <GenerativeAILabel>
            <GenerativeAIText>Generative AI</GenerativeAIText>
            <GenerativeAIIcon src="http://b.io/ext_6-" alt="" />
          </GenerativeAILabel>
          <GenerativeAIToggle onClick={handleGenerativeAIToggle}>
            <GenerativeAIDot active={generativeAI} />
          </GenerativeAIToggle>
        </GenerativeAISection>*/}
        </OptionsSection>
      <HorizontalDivider />
      <ButtonSection>
        <CancelButton onClick={handleCancel}>Cancel</CancelButton>
        <StartButton onClick={handleStartStory}>Start My Story</StartButton>
      </ButtonSection>
    </Container>
  );
}

const Container = styled.div`
  background-color: #fbfdff;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 50px;
  @media (max-width: 991px) {
    padding: 0 20px;
  }
`;

const Title = styled.h1`
  color: #2159a2;
  text-align: center;
  font-weight: 900;
  font-size: 28px;
  font-family: 'Josefin Sans', sans-serif;
`;

const Subtitle = styled.h2`
  color: #011126;
  text-align: center;
  margin-top: 13px;
  font-weight: 400;
  font-size: 19px;
  font-family: 'Josefin Sans', sans-serif;
`;

const ProjectNameInput = styled.input`
  border: 1px solid rgba(129, 129, 138, 1);
  background-color: #fff;
  margin-top: 21px;
  width: 767px;
  max-width: 100%;
  color: #000;
  text-align: center;
  padding: 15px 23px;
  font-weight: 600;
  font-size: 18px;
  font-family: 'Josefin Sans', sans-serif;
  @media (max-width: 991px) {
    padding: 0 20px;
  }
`;

const MainContent = styled.div`
  display: flex;
  margin-top: 10px;
  width: 100%;
  align-items: flex-start;
  gap: 20px;
  font-size: 15px;
  color: #011126;
  font-weight: 400;
  justify-content: center;
  height: calc(100vh - 400px); /* Adjust as needed */
  @media (max-width: 991px) {
    max-width: 100%;
    flex-wrap: wrap;
    height: auto; /* Ensure responsive behavior on small screens */
  }
`;

const UploadSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  flex: 1;
  margin-top: 10px;
  height: 100%;
`;

const UploadTitle = styled.h3`
  width: 251px;
  font-weight: 700;
  font-size: 19px;
  font-family: 'Josefin Sans', sans-serif;
`;

const UploadButton = styled.button`
  font-family: 'Josefin Sans', sans-serif;
  justify-content: center;
  border-radius: 9.155px;
  box-shadow: 1.831px 2.441px 3.723px 0px rgba(0, 0, 0, 0.25);
  background-color: #2159a2;
  margin-top: 16px;
  color: #f7f5f5;
  font-weight: 600;
  padding: 9px 27px;
  @media (max-width: 991px) {
    padding: 0 20px;
  }
`;

const UploadText = styled.p`
  font-family: 'Josefin Sans', sans-serif;
  margin-top: 16px;
`;

const Bold = styled.span`
  font-weight: 700;
`;

const PasteTextArea = styled.textarea`
  border: 1px solid rgba(129, 129, 138, 1);
  background-color: #fff;
  width: 100%;
  flex: 1;
  margin-top: 16px;
  color: #000;
  padding: 23px 34px 8px;
  font-size: 16px;
  font-family: 'Josefin Sans', sans-serif;
  resize: none; /* Prevent resizing by user */
  overflow: auto; /* Enable scrolling if content overflows */
  @media (max-width: 991px) {
    padding: 0 20px;
    min-width: 50%; /* Adjust minimum width for smaller screens */
    min-height: 20%; /* Adjust minimum height for smaller screens */
  }
`;


const Divider = styled.div`
  border: 1px solid rgba(217, 217, 217, 1);
  background-color: #d9d9d9;
  width: 1px;
  height: 100%;
`;

const GenerateSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  margin-top: 10px;
  height: 100%;
`;

const GenerateTitle = styled.h3`
  text-align: center;
  font-weight: 700;
  font-size: 19px;
  font-family: 'Josefin Sans', sans-serif;
`;

const GenerateSubtitle = styled.p`
  color: #24777d;
  text-align: center;
  font-family: 'Josefin Sans', sans-serif;
  font-style: italic;
  font-weight: 600;
  margin-top: 8px;
`;

const GenerateText = styled.p`
  text-align: center;
  font-family: 'Josefin Sans', sans-serif;
  margin-top: 8px;
`;

const GenerateTextArea = styled.textarea`
  border: 1px solid rgba(129, 129, 138, 1);
  background-color: #fff;
  width: 100%;
  flex: 1;
  margin-top: 11px;
  color: #000;
  padding: 22px 28px 152px;
  font-size: 16px;
  font-family: 'Josefin Sans', sans-serif;
  resize: none; /* Prevent resizing by user */
  overflow: auto; /* Enable scrolling if content overflows */
  @media (max-width: 991px) {
    padding: 0 20px 40px;
  }
`;

const Italic = styled.span`
  font-style: italic;
`;

const HorizontalDivider = styled.div`
  border: 1px solid rgba(217, 217, 217, 1);
  background-color: #d9d9d9;
  width: 100%;
  height: 1px;
  margin-top: 7px;
  @media (max-width: 991px) {
    margin-right: 2px;
  }
`;

const OptionsSection = styled.section`
  display: flex;
  margin-top: 15px;
  width: 100%;
  max-width: 970px;
  align-items: start;
  gap: 20px;
  justify-content: space-between;
  @media (max-width: 991px) {
    max-width: 100%;
    flex-wrap: wrap;
  }
`;

const FormatSection = styled.div`
  display: flex;
  flex-direction: column;
  font-weight: 400;
  width: 250px;
  white-space: nowrap;
  position: relative; /* Added to serve as the reference for the dropdown */
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const OptionLabel = styled.label`
  color: #011126;
  font-size: 12px;
  font-family: 'Josefin Sans', sans-serif;
`;

const OptionValue = styled.div`
  border: 1px solid rgba(217, 217, 217, 1);
  background-color: #fff;
  display: flex;
  margin-top: 10px;
  gap: 20px;
  font-size: 11px;
  color: #000;
  justify-content: space-between;
  padding: 8px 12px 8px 9px;
  cursor: pointer; /* Added cursor pointer for clarity */
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const OptionText = styled.span`
  font-family: 'Josefin Sans', sans-serif;
`;

const OptionIcon = styled.img`
  aspect-ratio: 1.85;
  object-fit: auto;
  object-position: center;
  width: 13px;
  margin: auto 0;
`;

const LengthSection = styled.div`
  display: flex;
  flex-direction: column;
  font-weight: 400;
  width: 250px;
`;

const GuidanceSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const GuidanceLabel = styled.label`
  color: #011126;
  text-align: center;
  font-weight: 400;
  font-size: 12px;
  font-family: 'Josefin Sans', sans-serif;
`;

const GuidanceOptions = styled.div`
  display: flex;
  margin-top: 9px;
  gap: 11px;
`;

const GuidanceOption = styled.span`
  color: #000;
  flex-grow: 1;
  font-weight: 400;
  font-size: 9px;
  font-family: 'Josefin Sans', sans-serif;
`;

const GuidanceSlider = styled.div`
  display: flex;
  flex-direction: column;
`;

const GuidanceSliderTrack = styled.div`
  display: flex;
  gap: 0px;
`;

const GuidanceSliderThumb = styled.div`
  display: flex;
  gap: 0px;
`;

const GuidanceSliderThumbTop = styled.div`
  border: 1px solid rgba(0, 0, 0, 1);
  background-color: #000;
  z-index: 10;
  height: 25px;
`;

const GuidanceSliderThumbBottom = styled.div`
  border: 3px solid rgba(0, 0, 0, 1);
  background-color: #000;
  height: 3px;
`;

const GuidanceSliderDot = styled.div`
  background-color: #807f7f;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  margin: auto 0;
`;

const GuidanceSliderLabels = styled.div`
  display: flex;
  gap: 9px;
  font-size: 9px;
  color: #000;
  font-weight: 400;
  white-space: nowrap;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const GuidanceSliderLine = styled.div`
  border: 1px solid rgba(0, 0, 0, 1);
  background-color: #000;
  width: 1px;
  height: 25px;
`;

const GuidanceSliderText = styled.span`
  font-family: 'Josefin Sans', sans-serif;
  margin: auto 0;
`;

const GenerativeAISection = styled.div`
  display: flex;
  flex-direction: column;
`;

const GenerativeAILabel = styled.div`
  display: flex;
  gap: 6px;
  font-size: 12px;
  color: #011126;
  font-weight: 400;
  text-align: center;
`;

const GenerativeAIText = styled.span`
  font-family: 'Josefin Sans', sans-serif;
`;

const GenerativeAIIcon = styled.img`
  aspect-ratio: 1;
  object-fit: auto;
  object-position: center;
  width: 12px;
`;

const GenerativeAIToggle = styled.button`
  border-radius: 7.29px;
  background-color: #24777d;
  align-self: center;
  display: flex;
  margin-top: 17px;
  width: 32px;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  padding: 2px 18px;
`;

const GenerativeAIDot = styled.div`
  background-color: #fff;
  border-radius: 50%;
  width: 100%;
  height: 12px;
  transform: ${({ active }) => (active ? "translateX(100%)" : "translateX(0)")};
  transition: transform 0.3s ease-in-out;
`;

const ButtonSection = styled.div`
  display: flex;
  margin-top: 36px;
  gap: 20px;
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  justify-content: space-between;
`;

const CancelButton = styled.button`
  font-family: 'Josefin Sans', sans-serif;
  justify-content: center;
  border-radius: 9.002px;
  box-shadow: 1.8px 2.4px 3.661px 0px rgba(0, 0, 0, 0.25);
  border: 1px solid rgba(217, 217, 217, 1);
  background-color: #fff;
  color: #2159a2;
  white-space: nowrap;
  padding: 9px 27px;
  @media (max-width: 991px) {
    white-space: initial;
    padding: 0 20px;
  }
`;

const StartButton = styled.button`
  font-family: 'Josefin Sans', sans-serif;
  justify-content: center;
  border-radius: 9.002px;
  box-shadow: 1.8px 2.4px 3.661px 0px rgba(0, 0, 0, 0.25);
  background-color: #2159a2;
  color: #f7f5f5;
  padding: 9px 27px;
  @media (max-width: 991px) {
    padding: 0 20px;
  }
`;

const ProgressOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  right: 0;
  bottom: 0;
  background: rgba(205, 205, 205, 0.8);
  z-index: 7777;
  position: fixed;

  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  flex-wrap: wrap;
  `;
  
  const ProgressStatus = styled.div`
    display: block;
    width: 100%;
    font-weight: 400;
    font-size: 24px;
    line-height: 24px;
    margin-bottom: 8px;
    text-align: center;
    color: #011126;
`;

const DropdownMenu = styled.div`
  border: 1px solid rgba(217, 217, 217, 1);
  background-color: #fff;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1;
  width: 100%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const DropdownItem = styled.div`
  padding: 10px;
  font-size: 12px;
  color: #000;
  cursor: pointer;
  &:hover {
    background-color: #f1f1f1;
  }
`;
export default CreateStory;