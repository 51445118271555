import React from "react";
import styled from "styled-components";
import { useNavigate, useLocation } from "react-router-dom";
import EditStory from "../../pages/Synth/editStory";
import EditStoryContext from "../../pages/Synth/EditStoryContext"

const SynthHeader = ({ project } ) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { saveScriptChanges } = React.useContext(EditStoryContext);
  const { saveCharChanges } = React.useContext(EditStoryContext);
  const { saveSceneChanges } = React.useContext(EditStoryContext);
  const { saveReviewChanges } = React.useContext(EditStoryContext);
  const { saveSceneAnimationChanges } = React.useContext(EditStoryContext);
  const { saveSceneAnimationColorizationChanges } = React.useContext(EditStoryContext);

  const handleIconClick = () => {
    navigate(`/synthprojects`);
  };

  const handleStartOverClick = () => {
    navigate(`/createstory`);
  };

  const handleSaveClick = async () => {
    const currentPage = location.pathname.split("/").pop();
    //console.log("Current page:", currentPage);
    if (currentPage === 'editstory') {
      await saveScriptChanges();
  };
  if (currentPage === 'editchardetail') {
    await saveCharChanges();
  };
  if (currentPage === 'editscene') {
    await saveSceneChanges();
  };
  if (currentPage === 'review') {
    await saveReviewChanges();
  };
  if (currentPage === 'animate1') {
    await saveSceneAnimationChanges();
  };
  if (currentPage === 'animatecolorize') {
    await saveSceneAnimationColorizationChanges();
  };

}

  return (
    <Container>
      <Header>
      <Title>{project}</Title>
      <Controls>
          <HomeButton onClick={handleIconClick}>
            <Icon src="https://alsynthprod.s3.amazonaws.com/homeIcon.svg" alt="Icon" />
          </HomeButton>
        <StartOverButton onClick={handleStartOverClick}>
            Start Over</StartOverButton>
        <SaveButton onClick={handleSaveClick}>Save</SaveButton>
        <HistoryButton src="https://alsynthprod.s3.amazonaws.com/history.png" alt="History" />
      </Controls>
    </Header>
    <Divider />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
const Header = styled.header`
  border: 1px solid rgba(217, 217, 217, 1);
  background-color: #fbfdff;
  display: flex;
  width: 100%;
  gap: 20px;
  padding: 19px 34px;
  @media (max-width: 991px) {
    max-width: 100%;
    flex-wrap: wrap;
    padding: 0 20px;
  }
`;

const Title = styled.h1`
  color: #2159a2;
  text-align: center;
  flex-grow: 1;
  margin: auto 0;
  font: 700 24px Inter, sans-serif;
`;

const Controls = styled.div`
  display: flex;
  align-items: start;
  gap: 12px;
`;

const HomeButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  box-shadow: 3px 4px 6.1px 0px rgba(0, 0, 0, 0.25);
  background-color: #fff;
  padding: 10px 16px;
  border: none;
  cursor: pointer;
`;

  
const Icon = styled.img`
  width: 24px;
  height: 24px;
  object-fit: contain;
  object-position: center;
`;


const Button = styled.button`
  justify-content: center;
  border-radius: 15px;
  box-shadow: 3px 4px 6.1px 0px rgba(0, 0, 0, 0.25);
  text-align: center;
  padding: 10px 30px;
  font: 700 20px Inter, sans-serif;
  border: none;
  cursor: pointer;

  @media (max-width: 991px) {
    padding: 0 20px;
  }
`;

const StartOverButton = styled(Button)`
  background-color: #fff;
  color: #2159a2;
`;


const SaveButton = styled(Button)`
background-color: #2159a2;
  color: #fbfdff;
  white-space: nowrap;
  @media (max-width: 991px) {
    white-space: initial;
    padding: 0 20px;
  }
`;

const HistoryButton = styled.img`
  width: 38px;
  height: 38px;
  object-fit: contain;
  object-position: center;
  margin-top: 5px;
  margin-right: 5px;
`;

const Divider = styled.div`
  border-color: #D9D9D9;
  border-style: solid;
  border-width: 1px;
  background-color: #2159a2;
  z-index: 10;
  min-height: 4px;
  width: 100%;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

export default SynthHeader;