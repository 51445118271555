import React, { useRef, useEffect, useState } from "react";
import { useAuth0 } from "../../auth/Auth";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { notify, notifyError } from "../../helpers/toastNotificationService"
import MenuProfile from "../../components/header/MenuProfile_Synth";
import Spinner from "../../components/layouts/Spinner/Spinner";
import { SideBar } from '../../components/SynthHeader/SideBar';
import { getWorkflowsAll } from "../../api/synth/workflows";

const { REACT_APP_API_URL } = process.env;

const SynthTasks = () => {
  const [isLoading, setLoading] = useState(false);
  const [tasks, setTasks] = useState([]);
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedTaskId, setSelectedTaskId] = useState(null);
  
  const { isAuthenticated } = useAuth0();
  const navigate = useNavigate();

  useEffect(() => {
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  }, []);

  useEffect(() => {
    setLoading(true);
    const fetchTasks = async () => {
      try {
        const response = await getWorkflowsAll();
        //const sortedTasks = response.sort((a, b) => new Date(b.lastUpdated) - new Date(a.lastUpdated));
        setTasks(response);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };

    fetchTasks();
  }, []); // Empty dependency array means this effect runs once on mount

  const formatDate = (dateString) => {
    const options = {
      year: '2-digit',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      timeZoneName: 'short' // Adds short timezone name (e.g., PST, EST, GMT)
    };
    return new Date(dateString).toLocaleString(undefined, options);
  }

  const toggleModal = () => {
    setModalOpen(!isModalOpen);
  };

  const Modal = ({ isOpen, onClose, onAddImageAction, onDeleteAction, children, position }) => {
    if (!isOpen || !position) return null;

    const style = {
      top: position.y,
      left: position.x,
    };

    return (
      <PopoverContainer style={style} onClick={e => e.stopPropagation()}>
        {children}
        <OptionsText onClick={onAddImageAction}>Add Image</OptionsText>
        <OptionsText onClick={onDeleteAction}>Delete</OptionsText>
      </PopoverContainer>
    );
  };

  const handleTaskClick = (taskId, taskTitle) => {
    navigate(`/editstory?taskName=${encodeURIComponent(taskTitle)}&storyId=${encodeURIComponent(taskId)}`);
  };

  const defaultImage = 'https://alsynthprod.s3.amazonaws.com/TaskPlaceholderImage.jpg';

  const isSticky = (e) => {
    const header = document.querySelector(".js-header");
    if (header) {
      const scrollTop = window.scrollY;
      scrollTop >= 300
        ? header.classList.add("is-fixed")
        : header.classList.remove("is-fixed");
      scrollTop >= 400
        ? header.classList.add("is-small")
        : header.classList.remove("is-small");
    }
  };

  return (
    <AppContainer>
      <AppLayout>
        <MainContent>
          <Header>
          {isLoading && (
          <ProgressOverlay>
            <ProgressStatus>Getting Tasks....
            </ProgressStatus>
            <Spinner />
          </ProgressOverlay>
          )}
            <AppTitle>
              <SynthLogo src="https://alsynthprod.s3.amazonaws.com/ACMESynthLogo.png" alt="ACME Synthesizer"/>
            </AppTitle>
          </Header>
          <Divider />
          
          <RecentTasksHeader>
            <RecentTasksTitle>Active Tasks</RecentTasksTitle>
          </RecentTasksHeader>
          <TasksView>
            {!tasks ? (
              <NoTasks>An Error has Occurred</NoTasks>
            ) : (tasks.length === 0 && isLoading === false) ? (
              <NoTasks>No Tasks</NoTasks>
            ) : (
              tasks.map((task, index) => (
                <TaskCard 
                  key={task.id}
                  onClick={() => handleTaskClick(task.id)}
                >
                  <TaskHeader>
                    <TaskDetails>
                      <TaskTitle>{task.wfName}</TaskTitle>
                      <TaskLastOpened>Last Updated: {formatDate(task.lastUpdatedDate)}</TaskLastOpened>
                      <TaskText><u>Category:</u> <b>{task.wfCatMajor}</b></TaskText>
                      <TaskText><u>Subcategory:</u> <b>{task.wfCatMinor}</b></TaskText>
                      <TaskText><u>Description:</u> {task.wfDescrip}</TaskText>
                    </TaskDetails>
                  </TaskHeader>
                </TaskCard>
              ))
            )}
          </TasksView>
        </MainContent>
        <Sidebar>
          <SidebarContent>
            <UserInfo>
              <UserDetails>
                <Username>{!isAuthenticated ? null : <MenuProfile />}</Username>
              </UserDetails>
            </UserInfo>
            <NavItem active>
              <NavIcon src="https://alsynthprod.s3.amazonaws.com/resultsIcon.svg" alt="Recents Icon" />
              <NavText>Recents</NavText>
            </NavItem>
          </SidebarContent>
        </Sidebar>
      </AppLayout>
    </AppContainer>
  );
}


const AppContainer = styled.div`
  background-color: #fbfdff;
  font-family: 'Josefin Sans', sans-serif;
`;

const AppLayout = styled.div`
  display: flex;
  gap: 20px;
`;

const SynthLogo = styled.img`
  width: 200px;
`;

const MainContent = styled.main`
  flex: 1;
  margin-left: 0;
`;

const TasksView = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  
  gap: 20px; 
`;

const Header = styled.header`
  display: flex;
  margin-top: 26px;
`;

const AppTitle = styled.h1`
  color: #2159a2;
  flex: 1;
  font-size: 32px;
  font-weight: 700;
  letter-spacing: 0.1px;
  margin-left: 35px;
`;

const NoTasks = styled.h2`
  flex: 1;
  color: #2159a2;
  font-size: 32px;
  font-weight: 700;
  text-align: center;
  margin-left: 35px;
  padding-top: 10%;
  wordWrap: break-word;
  `;
const Divider = styled.hr`
  height: 1px;
  margin-top: 23px;
  border: none;
  background-color: #d9d9d9;
`;

const RecentTasksHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  margin: 25px 30px 0 0;
`;

const RecentTasksTitle = styled.h2`
  flex: 1;
  color: #011126;
  font-size: 24px;
  font-weight: 700;
  text-align: center;
  color: #2159a2;
`;

const TaskCard = styled.article`
  display: flex;
  flex-direction: column;
  width: 300px;
  max-height: 275px;
  max-width: 100%;
  margin: 30px 0 0 20px;
  padding: 29px 9px 14px;
  border: 1px solid #d9d9d9;
  border-radius: 15px;
  background-color: #fff;
  box-shadow: 3px 4px 6px 0 rgba(0, 0, 0, 0.25);
  overflow: hidden;  // Ensure content does not overflow the card
`;

const TaskHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  gap: 20px;
`;

const TaskDetails = styled.div`
  display: flex;
  flex-direction: column;
`;

const TaskTitle = styled.h3`
  color: #2159a2;
  font-size: 20px;
  font-weight: 700;
`;

const TaskLastOpened = styled.p`
  color: #81818a;
  font-size: 12px;
  font-weight: 400;
`;

const TaskText = styled.p`
  color: #2159a2;
  font-size: 14px;
  font-weight: 400;
`;

const Sidebar = styled.aside`
  width: 15%;
  min-wdith: 200px;
  margin-left: 20px;
`;

const SidebarContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 80px;
  background-color: #f5f5f7;
  font-size: 14px;
  text-align: center;
  white-space: nowrap;
`;

const UserInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  padding: 28px 22px;
  border: 1px solid #d9d9d9;
  background-color: #fbfdff;
  color: #011126;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0.1px;
  line-height: 100%;
`;

const UserDetails = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const Username = styled.span`
  font-family: 'Josefin Sans', sans-serif;
`;

const SettingsIcon = styled.img`
  width: 15px;
  aspect-ratio: 1.67;
  object-fit: contain;
`;

const NavItem = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  padding: ${(props) => (props.active ? "12px 21px" : "0 21px")};
  margin: ${(props) => (props.active ? "0" : "13px 0 0 0")};
  background-color: ${(props) => (props.active ? "#2159a2" : "transparent")};
  color: ${(props) => (props.active ? "#fff" : "#000")};
  font-weight: ${(props) => (props.active ? "700" : "400")};
`;

const NavIcon = styled.img`
  width: ${(props) => (props.active ? "22px" : "25px")};
  aspect-ratio: ${(props) => (props.active ? "1" : "1.04")};
  object-fit: contain;
  align-self: ${(props) => (props.active ? "flex-start" : "center")};
`;

const NavText = styled.span`
  font-family: 'Josefin Sans', sans-serif;
  margin: auto 0;
`;
const ProgressOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  right: 0;
  bottom: 0;
  background: rgba(205, 205, 205, 0.8);
  z-index: 7777;
  position: fixed;

  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  flex-wrap: wrap;
  `;
  
  const ProgressStatus = styled.div`
    display: block;
    width: 100%;
    font-weight: 400;
    font-size: 24px;
    line-height: 24px;
    margin-bottom: 8px;
    text-align: center;
    color: #011126;
`;

const PopoverContainer = styled.div`
  position: absolute;
  width: 203px;
  
  border-radius: 7px;
  border: 1px solid #D9D9D9;
  background: #FBFDFF;
  box-shadow: 3px 4px 7.8px 0px rgba(0, 0, 0, 0.25);
  margin-top: -5px;
    z-index: 100; // Ensure it's above other content
`;

const OptionsText = styled.div`
  color: #2159a2;
  font-family: 'Josefin Sans', sans-serif;
  font-size: 12px;
  font-weight: 500;
  min-height: 25px;
  font-weight: bold;
  line-height: normal;
  display: flex; // Use flexbox for alignment
  align-items: center; // Vertically center the text
  padding-left: 30px; // Add padding to the left
  height: 100%; // Take full height to align text vertically
  cursor: pointer; // Add this to show it's clickable
  background-color: #FBFDFF; // Default background color

  &:hover {
    background-color: #CCEDFF; // Change to desired hover color
  }
`;



export default SynthTasks;