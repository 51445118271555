//import { CUSTOM_PAGE_SIZE } from "../../constants/constants";
import { getHeaders } from "../Item/utils";
const { REACT_APP_API_URL } = process.env;

export const sdxlCreateCharacter = async ({ requestBody }) => {
  try {
    const { headers } = await getHeaders();
    const response = await fetch(`${REACT_APP_API_URL}story/character/generate`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        ...headers,
      },
      body: JSON.stringify(requestBody),
    });
    
    return await response.text();
  } catch (error) {
    console.error("Error in sdxlCreateCharacter:", error);
    // Re-throw the error to allow further handling by the caller
    throw error;
  }
};
 
  export const sdxlCreateScene = async ({ requestBody }) => {
    try {
      const { headers } = await getHeaders();
      const response = await fetch(`${REACT_APP_API_URL}story/scene/generate`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          ...headers,
        },
        body: JSON.stringify(requestBody),
      });
      
      return await response.text();
    } catch (error) {
      console.error("Error in sdxlCreateScene:", error);
      // Re-throw the error to allow further handling by the caller
      throw error;
    }
};
