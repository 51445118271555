import { useState } from "react";
import { getIdTokenClaims } from "../helpers/auth0Service";
/**
 *
 * @param {*} url: URL to hit in Database
 * @param options: you must include audience, scope and fetch options params
 * @returns state: {error: null | boolean, loading: boolean, data: null | Response}, refresh()
 */

export const useApi = (/* url, options = {} */) => {
  const [state, setState] = useState({
    error: null,
    loading: true,
    data: null,
  });

  const [refreshIndex, setRefreshIndex] = useState(0);

  const executeApiRequest = async (url, options = {}) => {
    /*console.log(
      "🚀 ~ file: useApi.js ~ line 23 ~ executeApiRequest ~ options",
      options
    );
    console.log(
      "🚀 ~ file: useApi.js ~ line 23 ~ executeApiRequest ~ url",
      url
    );*/
    try {
      const idToken = await getIdTokenClaims();
      const token = idToken?.__raw ?? localStorage.getItem("token");

      if (token) {
        localStorage.setItem("token", token);
        if (!options.headers) {
          options.headers = {};
        }

        options.headers.Authorization = `Bearer ${token}`;
      }

      const res = await fetch(url, {
        method: options.method,
        body: options.body,
        headers: options.headers,
      });

      let data;

      try {
        data = await res.json();
      } catch (e) {}

      setState({
        ...state,
        data,
        error: null,
        loading: false,
      });
      console.log(data);
    } catch (error) {
      setState({
        ...state,
        error,
        loading: false,
      });

      throw error;
    }
  };
  const executeApiRequestTwo = async (url, options = {}) => {
    /*console.log(
      "🚀 ~ file: useApi.js ~ line 53 ~ executeApiRequestTwo ~ url",
      url
    );
    console.log(
      "🚀 ~ file: useApi.js ~ line 53 ~ executeApiRequestTwo ~ options",
      options
    );*/
    try {
      const idToken = await getIdTokenClaims();
      const token = idToken?.__raw ?? localStorage.getItem("token");

      if (token) localStorage.setItem("token", token);

      const res = await fetch(url, {
        method: options.method,
        body: options.body,
        headers: {
          ...options.headers,
          Authorization: options?.idToken
            ? `Bearer ${options?.idToken}`
            : `Bearer ${token}`,
        },
      });

      let data;
      try {
        data = await res.json();
      } catch (e) {}
      return data;
    } catch (error) {
      console.log(error);

      throw error;
    }
  };

  const refresh = () => {
    executeApiRequest();
    setRefreshIndex(refreshIndex + 1);
  };

  return {
    ...state,
    executeApiRequest,
    executeApiRequestTwo,
    refresh,
  };
};
