import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  auth0UserId: "",
  id: "", // id
  displayName: "", // displayName
  picture: "", // picture
  pictureURL: "",
  profileFileName: "",
  firstName: "", // firstName
  lastName: "", // lastName
  nickname: "", // nickName
  coverFileName: "", //coverFileName
  email_verified: "", // email_verified
  biography: "",
  username: "",
  email: "",
  password: "",
  state: "", // state
  city: "",
  streetAddress: "",
  streetAddressContd: "", //streetAddressContd
  zipCode: "",
  stripeAccountId: "",
  stripeAccountVerification: "",
  facebookUrl: "", //
  discordUrl: "", //
  twitterUrl: "",
  tiktokUrl: "", // tiktokUrl
  instagramUrl: "",
  virtualPlaceCustomUrl: "",
  shippingAddress: "",
  defaultWalletType: "",
  vaultWalletId: "",
  roleId: "",
  wallets: {
    owner: {},
    walletDetails: {},
  },
  hashpackWallets: {},
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    editUser: (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    editCoverFile: (state, action) => {
      return {
        ...state,
        coverFileName: action.payload,
      };
    },
    editPicture: (state, action) => {
      return {
        ...state,
        pictureURL: action.payload,
        profileFileName: action.payload,
      };
    },
    signin: (state, action) => {
      /*console.log(
        "🚀 ~ file: user.js ~ line 41 ~ action.payload",
        action.payload
      );*/
      return {
        ...state,
        ...action.payload,
      };
    },

    signout: () => initialState,
    logoutWallet: (state) => {
      return {
        ...state,
        wallets: {
          ...state.wallets,
          owner: {},
          walletDetails: {},
        },
      };
    },
    logoutHashpackWallet: (state) => {
      return {
        ...state,
        hashpackWallets: {},
      };
    },
    updateWalletDetail: (state, action) => {
      return {
        ...state,
        wallets: {
          ...state.wallets,
          walletDetails: action.payload,
        },
      };
    },
    updateHashPackWallets: (state, action) => {
      return {
        ...state,
        hashpackWallets: action.payload,
      };
    },
    updateOwnerWallet: (state, action) => {
      return {
        ...state,
        wallets: {
          ...state.wallets,
          owner: action.payload,
        },
      };
    },
  },
});

export const {
  editUser,
  updateWalletDetail,
  logoutWallet,
  logoutHashpackWallet,
  updateOwnerWallet,
  updateHashPackWallets,
  signout,
  editCoverFile,
  editPicture,
  signin,
} = userSlice.actions;
export default userSlice.reducer;
