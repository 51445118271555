import React, { useState } from "react";
import styled from "styled-components";
import SynthFooter from "../../components/synth/Footer";
import SynthHeader from "../../components/synth/Header";
import { useNavigate, useLocation } from "react-router-dom";
import { notify, notifyError } from "../../helpers/toastNotificationService";
import Spinner from "../../components/layouts/Spinner/Spinner";
import { getScenes, importScenes } from "../../api/synth/scenes";
import ImportSceneModal from "../../components/synth/ImportSceneModal";
import { DndContext, closestCenter } from "@dnd-kit/core";
import { SortableContext, verticalListSortingStrategy, arrayMove } from "@dnd-kit/sortable";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { sdxlCreateScene } from "../../api/synth/sdxl";
import { getCharactersDesign } from "../../api/synth/getCharacters";
import { IconPalette, IconPencil, IconKeyframes } from '@tabler/icons-react';

const StoryBoard = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const [isLoading, setLoading] = useState(false);
  const [scenes, setScenes] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState({});
  const [loadingMessage, setLoadingMessage] = useState(null);
  const [showGenerateButton, setShowGenerateButton] = useState(false);
  const [actVisibility, setActVisibility] = useState({
    act1: true,
    act2: false,
    act3: false,
  });

  const projectName = queryParams.get('projectName');
  const storyId = queryParams.get('storyId');
  const imageUrl = "https://alsynthprod.s3.amazonaws.com/AddImage.png";
  const [errorMessage, setErrorMessage] = useState('');

  // Obtain all scenes for this story
  React.useEffect(() => {
    const fetchScenes = async () => {
      setLoadingMessage('Fetching Scenes... This will take a moment.');
      setLoading(true);
      try {
        const response = await getScenes(storyId);
        if (Array.isArray(response) && response.length > 0) {
          setScenes(response);
          const missingImages = response.some(scene => 
            !scene.sceneImage || scene.sceneImage === imageUrl,
          );
          setShowGenerateButton(missingImages);
          setShowModal(false);
        } else {
          setScenes([]);
          setShowModal(true); // Show modal if no scenes are found
        }
      } catch (error) {
        console.error('Error fetching scenes:', error);
        notifyError("Error obtaining scenes. Please refresh and try again");
      } finally {
        setLoading(false);
      }
    };

    fetchScenes();
  }, [storyId]); // Dependency array to refetch if storyId changes

  // SCENE IMPORT
  const handleSceneImport = async () => {
    setShowModal(false);
    setLoadingMessage('Importing Scenes... This will take a moment.');
    setLoading(true);
    try {
      const response = await importScenes(storyId);
      const responseText = await response.text();
      if (responseText == "Error: The story is not a valid formatted screenplay") {
        setLoading(false);
        notifyError("The story is not a valid formatted screenplay. Please 'Return to the Writers Room' to update the story to script format before importing scenes.");
        setShowModal(false);
      }
    } catch (error) {
      setLoading(false);
      notifyError("Failed to import Scenes", error);
    } finally {
      setLoading(false);
      window.location.reload();
    }
  }


  /////////////////////////////////////////////////  
  // Generate Missing AI Character Images
  const handleGenerateMissingImages = async () => {
    //console.log("Clicked Generate Scene Images Button");
    setLoadingMessage('Generating Scenes... This will take a moment.');
    setLoading(true); // Initial loading message
  
    try {
      const response = await getScenes(storyId);
      if (response && response.length > 0) {
        const imageUrls = [];
        
        const charDesignData = await getCharactersDesign(storyId); 
                
          for (let i = 0; i < response.length; i++) {
          const scene = response[i];
          // Update the loading message with the character's name
          setLoadingMessage(`Generating image for ${scene.sceneLocation}...`);
  

          if (!scene.sceneImage || scene.sceneImage === imageUrl) {
            console.log(`Scene ${i} does not have an image, triggering image generation`);
            const requestBody = {
              storyId: storyId,
              sceneId: scene.id,
              prompt: `${scene.sceneLocation}, ${scene.sceneDesc}`,
              seed: 0,
              stylePreset: charDesignData.artStyling,
            };
            const genAiImage = await sdxlCreateScene({ requestBody });
            const imageUrl = genAiImage;
            //console.log(`Scene ${i} created successfully`, imageUrl);
          } else {
            console.log(`Scene ${i} has an image`, scene.sceneImage);
          }
        }
  
        //console.log("Collected Image URLs:", imageUrls);
        window.location.reload();
      } else {
        console.error("No characters found or response is not an array:", response);
      }
    } catch (error) {
      console.error("Failed to fetch characters:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleEditScene = (scene) => {
    navigate(`/editscene?projectName=${encodeURIComponent(projectName)}&storyId=${encodeURIComponent(storyId)}&sceneId=${encodeURIComponent(scene.id)}`);
  };

  const handleAnimateScene = (scene) => {
    navigate(`/animate1?projectName=${encodeURIComponent(projectName)}&storyId=${encodeURIComponent(storyId)}&sceneId=${encodeURIComponent(scene.id)}`);
  };

  const handleColorizeScene = (scene) => {
    navigate(`/animatecolorize?projectName=${encodeURIComponent(projectName)}&storyId=${encodeURIComponent(storyId)}&sceneId=${encodeURIComponent(scene.id)}`);
  };
  
  const handleSceneClick = (scene) => {
    navigate(`/editscene?projectName=${encodeURIComponent(projectName)}&storyId=${encodeURIComponent(storyId)}&sceneId=${encodeURIComponent(scene.id)}`);
  };

  const handleAddScene = async () => {
    notify("This feature is not currently active");
  };

  const handleReturnToStory = () => {
    navigate(`/editstory?projectName=${encodeURIComponent(projectName)}&storyId=${encodeURIComponent(storyId)}`);
  }

  const toggleVisibility = (act) => {
    setActVisibility(prevState => ({
      ...prevState,
      [act]: !prevState[act]
    }));
  }

  const ButtonWithTooltip = ({ onClick, icon: Icon, tooltipText, disabled }) => {
    const [hover, setHover] = useState(false);
  
    return (
      <StyledButton
        onClick={onClick}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        disabled={disabled}
      >
        <Tooltip show={hover}>{tooltipText}</Tooltip>
        <Icon color="#2159a2" size={24} />
      </StyledButton>
    );
  };

  const handle1 = {

  };

  const handleDragEnd = (event) => {
    const { active, over } = event;
    if (active.id !== over.id) {
      setScenes((items) => {
        const oldIndex = items.findIndex(item => item.id === active.id);
        const newIndex = items.findIndex(item => item.id === over.id);
        return arrayMove(items, oldIndex, newIndex);
      });
    }
  }
  
  const SortableSceneCard = ({ id, scene, handleClick }) => {
    const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({ id });
    const style = {
      transform: CSS.Transform.toString(transform),
      transition,
      zIndex: isDragging ? 1000 : "auto", // Higher z-index when dragging
    };

    

    return (
      <StyledSceneCard
        ref={setNodeRef}
        style={style}
        {...attributes}
        {...listeners}
        
        className={isDragging ? "dragging" : ""}
      >
        <SceneImage src={scene.sceneImage || "Add Image"} alt="" />
        {scene.sceneDesc && (
          <SceneDescriptionContainer>
            <SceneDescription>{scene.sceneDesc}</SceneDescription>
            {scene.additionalImages && (
              <SceneImageGroup>
                {scene.additionalImages.map((imgSrc, imgIndex) => (
                  <SceneAdditionalImage key={imgIndex} src={imgSrc} alt="" />
                ))}
              </SceneImageGroup>
            )}
            <ButtonContainer>
              <ButtonWithTooltip onClick={() => handleEditScene(scene)} icon={IconPencil} tooltipText="Edit" />
              <ButtonWithTooltip onClick={() => handleAnimateScene(scene)} icon={IconKeyframes} tooltipText="Animate" />
              <ButtonWithTooltip onClick={() => handleColorizeScene(scene)} icon={IconPalette} tooltipText="Colorize" />
            </ButtonContainer>
          </SceneDescriptionContainer>
        )}
      </StyledSceneCard>
    );
  };

  return (
    <AppWrapper>
      {isLoading && (
        <ProgressOverlay>
          <ProgressStatus>{loadingMessage}</ProgressStatus>
          <Spinner />
        </ProgressOverlay>
      )}
      <SynthHeader project={projectName} />
      <PageBody>
        <Main>
          <SectionContainer>
            <MainContent>
              <SidePanel />
              <ColumnContainer>
                <StoryBoardContainer>
                  <Title>Story Board Design</Title>
                  <ActHeader onClick={() => toggleVisibility('act1')}>
                    <ActTitle>Act I</ActTitle>
                    <ActIcon
                      src={actVisibility.act1 ? "https://alsynthprod.s3.amazonaws.com/upArrow.png" : "https://alsynthprod.s3.amazonaws.com/downArrow.png"}
                      alt=""
                    />
                  </ActHeader>
                  {actVisibility.act1 && (
                    <SceneSection>
                    {/*<DndContext collisionDetection={closestCenter} onDragEnd={handleDragEnd}>*/}
                    <SortableContext items={scenes.filter(scene => scene.act === 'Act 1')} strategy={verticalListSortingStrategy}>
                      <SceneRow>
                        {scenes.filter(scene => scene.act === 'Act 1').map((scene, index) => (
                          <SortableSceneCard key={scene.id} id={scene.id} scene={scene} handleClick={handleSceneClick}>
                          </SortableSceneCard>
                        ))}
                        <SceneCard>
                          <AddSceneCard>
                            <AddSceneButton onClick={handleAddScene}>
                              <AddSceneIcon>+</AddSceneIcon>
                              <AddSceneText>Add Scene</AddSceneText>
                            </AddSceneButton>
                          </AddSceneCard>
                        </SceneCard>
                      </SceneRow>
                    </SortableContext>
                    {/*</DndContext>*/}
                  </SceneSection>
                  )}
                  <Divider2 />
                  <ActHeader onClick={() => toggleVisibility('act2')}>
                    <ActTitle>Act II</ActTitle>
                    <ActIcon
                      src={actVisibility.act2 ? "https://alsynthprod.s3.amazonaws.com/upArrow.png" : "https://alsynthprod.s3.amazonaws.com/downArrow.png"}
                      alt=""
                    />
                  </ActHeader>
                  {actVisibility.act2 && (
                    <SceneSection>
                      {/*<DndContext collisionDetection={closestCenter} onDragEnd={handleDragEnd}>*/}
                        <SortableContext items={scenes.filter(scene => scene.act === 'Act 2')} strategy={verticalListSortingStrategy}>
                          <SceneRow>
                            {scenes.filter(scene => scene.act === 'Act 2').map((scene, index) => (
                              <SortableSceneCard key={scene.id} id={scene.id} scene={scene} handleClick={handleSceneClick} />
                            ))}
                            <SceneCard>
                              <AddSceneCard>
                                <AddSceneButton onClick={handleAddScene}>
                                  <AddSceneIcon>+</AddSceneIcon>
                                  <AddSceneText>Add Scene</AddSceneText>
                                </AddSceneButton>
                              </AddSceneCard>
                            </SceneCard>
                          </SceneRow>
                        </SortableContext>
                      {/*</DndContext>*/}
                    </SceneSection>
                  )}
                  <Divider2 />
                  <ActHeader onClick={() => toggleVisibility('act3')}>
                    <ActTitle>Act III</ActTitle>
                    <ActIcon
                      src={actVisibility.act3 ? "https://alsynthprod.s3.amazonaws.com/upArrow.png" : "https://alsynthprod.s3.amazonaws.com/downArrow.png"}
                      alt=""
                    />
                  </ActHeader>
                  {actVisibility.act3 && (
                    <SceneSection>
                      {/*<DndContext collisionDetection={closestCenter} onDragEnd={handleDragEnd}>*/}
                        <SortableContext items={scenes.filter(scene => scene.act === 'Act 3')} strategy={verticalListSortingStrategy}>
                          <SceneRow>
                            {scenes.filter(scene => scene.act === 'Act 3').map((scene, index) => (
                              <SortableSceneCard key={scene.id} id={scene.id} scene={scene} handleClick={handleSceneClick} />
                            ))}
                            <SceneCard>
                              <AddSceneCard>
                                <AddSceneButton onClick={handleAddScene}>
                                  <AddSceneIcon>+</AddSceneIcon>
                                  <AddSceneText>Add Scene</AddSceneText>
                                </AddSceneButton>
                              </AddSceneCard>
                            </SceneCard>
                          </SceneRow>
                        </SortableContext>
                      {/*</DndContext>*/}
                    </SceneSection>
                  )}
                  <Divider2 />
                </StoryBoardContainer>
                <AiHelpText>
                  Use Generative AI to help you with your characters.
                  <br />
                {showGenerateButton && (
                      <SubmitButton onClick={handleGenerateMissingImages}>
                          Generate Missing Scene Images
                      </SubmitButton>
                  )}
                  </AiHelpText>
              </ColumnContainer>
            </MainContent>
          </SectionContainer>
        </Main>
        {showModal && (
          <ImportSceneModal
            onReturn={handleReturnToStory}
            onImport={handleSceneImport}
          />
        )}
      </PageBody>
      <Divider />
      <SynthFooter />
    </AppWrapper>
  );
};

const AppWrapper = styled.div`
  background-color: #fbfdff;
  display: flex;
  flex-direction: column;
  min-height: 100vh; // Ensure it takes the full viewport height
`;


const PageBody = styled.div`
display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  flex-grow: 1; // Allow it to grow and fill the remaining space
`;


const Main = styled.main`
align-items: flex-start;
  margin-top: 42px;
  width: 80%;
  overflow-x: auto; // Allows horizontal scrolling
  padding: 0 20px; // Add padding to prevent content from touching the edges
  flex-grow: 1; // Allow it to grow and fill the remaining space
  flex-shrink: 0; // Prevent it from shrinking
  @media (max-width: 991px) {
    max-width: 80%;
    margin-top: 40px;
    padding: 0 10px; // Adjust padding for smaller screens
  }
`;

const SectionContainer = styled.section`
  display: flex;
  flex-direction: column;
  background-color: #fff;
`;

const MainContent = styled.div`
  display: flex;
  width: 100%;
  gap: 20px;
  z-index: 10;
  @media (max-width: 991px) {
    max-width: 100%;
    flex-wrap: wrap;
  }
`;

const SidePanel = styled.aside`
  width: 120px;
  height: 100%;
  min-height: 500px;
  background-color: #f7f5f5;
  box-shadow: 3.24px 1.62px 5.832px 0px rgba(0, 0, 0, 0.25);
  align-self: start;
`;

const ColumnContainer = styled.div`
  display: flex;
  flex-grow: 1;
  gap: 20px;
  @media (max-width: 991px) {
    flex-direction: column;
    align-items: stretch;
    gap: 0px;
  }
`;

const StoryBoardContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 77%;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const Title = styled.h2`
  color: #011126;
  text-align: center;
  font: 700 20px 'Josefin Sans', sans-serif;
`;

const SceneSection = styled.section`
  margin-top: 41px;
  @media (max-width: 991px) {
    margin-top: 40px;
  }
`;

const SceneRow = styled.div`
  display: flex;
  gap: 20px;
  overflow-x: auto; // Allows horizontal scrolling
  padding-bottom: 20px;
  @media (max-width: 991px) {
    flex-direction: column;
    align-items: stretch;
    gap: 0px;
  }
`;

const ActSection = styled.section`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const ActHeader = styled.div`
  display: flex;
  align-items: center; /* Align items vertically centered */
  gap: 10px; /* Adjust the gap between the title and icon as needed */
  font-size: 16px;
  color: #000;
  font-weight: 700;
`;

const ActTitle = styled.h3`
  font-family: 'Josefin Sans', sans-serif;
  margin: 0; /* Remove default margin */
`;

const ActIcon = styled.img`
  width: 18px;
  height: auto;
  margin-left: 70px;
  object-fit: contain; /* Ensure the icon maintains its aspect ratio */
`;

const Divider = styled.div`
  border-color: rgba(33, 89, 162, 1);
  border-style: solid;
  border-width: 4px;
  background-color: #2159a2;
  z-index: 10;
  min-height: 4px;
  margin-top: 37px;
  width: 100%;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Divider2 = styled.hr`
border-color: #D9D9D9;
  border-style: solid;
  border-width: 1px;
  background-color: #D9D9D9;
  z-index: 10;
  min-height: 1px;
  width: 100%;
  margin-top: 25px;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const SceneCard = styled.article`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background-color: #d9d9d9;
  min-width: 175px; // Minimum width for the card
  max-height: 175px;
  max-width: 175px;
  box-shadow: 1.191px 2.382px 3.99px 0px rgba(0, 0, 0, 0.25);
  overflow: hidden; // Ensure content does not overflow
`;

const StyledSceneCard = styled.article`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background-color: #d9d9d9;
  min-width: 175px; // Minimum width for the card
  max-height: 175px;
  max-width: 175px;
  box-shadow: 1.191px 2.382px 3.99px 0px rgba(0, 0, 0, 0.25);
  overflow: hidden; // Ensure content does not overflow
  z-index: 1;

  &.dragging {
    z-index: 1000; // Higher z-index when dragging
  }
`;

const SceneImage = styled.img`
height: 60%; // Take up 60% of the card's height
width: 100%;
object-fit: cover; // Ensure the image covers the space without distortion
align-self: start; // Align to the top
`;

const SceneDescriptionContainer = styled.div`
display: flex;
flex-direction: column;
flex-grow: 1; // Take up the remaining space
background-color: #fbfdff;
border-top: 1px solid rgba(217, 217, 217, 1); // Adjusted to fit within the card
padding: 8px; // Adjusted padding
overflow: auto; // Allow scrolling if content overflows
z-index: 10;
`;

const SceneDescription = styled.p`
color: #011126;
margin: 0; // Remove default margin
font: 400 9px 'Josefin Sans', sans-serif;
height: 80%; // Ensure it takes the available height
overflow: auto; // Allow scrolling if content overflows
@media (max-width: 991px) {
  margin-right: 5px;
}
`;

const SceneImageGroup = styled.div`
display: flex;
gap: 6px;
align-self: end;
margin-top: 4px;
`;

const SceneAdditionalImage = styled.img`
width: 100%;
object-fit: auto;
object-position: center;
`;

const AddSceneCard = styled(SceneCard)`
border: 1px solid rgba(217, 217, 217, 1);
background-color: #fbfdff;
display: flex;
flex-direction: column;
align-items: center; /* Center items horizontally */

@media (max-width: 991px) {
  padding: 0 20px;
}
`;

const AddSceneButton = styled.article`
padding-top: 30px;
color: #2159a2;
font: 57px 'Josefin Sans', sans-serif;
background: none;
border: none;
cursor: pointer;
display: flex;
flex-direction: column; /* Align items vertically */
align-items: center; /* Center items horizontally */

@media (max-width: 991px) {
  font-size: 40px;
}
`;

const AddSceneIcon = styled.span`
color: #2159a2;
font: 57px 'Josefin Sans', sans-serif; /* Corrected "ont" to "font" */
margin-bottom: 10px; /* Adjusted margin for spacing */

@media (max-width: 991px) {
  font-size: 40px;
  margin-bottom: 20px;
}
`;

const AddSceneText = styled.span`
color: #011126;
font: 18px 'Josefin Sans', sans-serif;
`;

const ProgressOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  right: 0;
  bottom: 0;
  background: rgba(205, 205, 205, 0.8);
  z-index: 7777;
  position: fixed;

  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  flex-wrap: wrap;
  `;
  
  const ProgressStatus = styled.div`
    display: block;
    width: 100%;
    font-weight: 400;
    font-size: 24px;
    line-height: 24px;
    margin-bottom: 8px;
    text-align: center;
    color: #011126;
`;

const SubmitButton = styled.button`
  font-family: 'Josefin Sans', sans-serif;
  justify-content: center;
  border-radius: 15px;
  box-shadow: 3px 4px 6.1px 0px rgba(0, 0, 0, 0.25);
  background-color: #24777d;
  align-self: center;
  margin-top: 20px;
  color: #fbfdff;
  font-weight: 600;
  white-space: nowrap;
  text-align: center;
  padding: 10px 20px;
  border: none;
  cursor: pointer;

  @media (max-width: 991px) {
    white-space: initial;
    padding: 10px 20px;
  }
`;

const AiHelpText = styled.p`
  color: #24777d;
  margin: 28px 0 13px 12px;
  font: 700 18px 'Josefin Sans', sans-serif;

  @media (max-width: 991px) {
    margin-left: 10px;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-right: 10px;
`;

const StyledButton = styled.button`
  position: relative;
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  width: 22px;
  opacity: ${props => (props.disabled ? 0.5 : 1)};
  pointer-events: ${props => (props.disabled ? 'none' : 'auto')};
  &:hover, &:focus {
    border: none;
    outline: none; /* This ensures that the button does not show the default focus outline */
  }
`;

const Tooltip = styled.div`
  position: absolute;
  top: -30px;
  left: 50%;
  transform: translateX(-50%);
  background-color: black;
  color: white;
  padding: 5px;
  border-radius: 3px;
  white-space: nowrap;
  font-size: 12px;
  opacity: ${props => (props.show ? 1 : 0)};
  transition: opacity 0.2s;
  pointer-events: none;
`;

export default StoryBoard;