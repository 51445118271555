import React, { useRef, useEffect, useState } from "react";
import styled from "styled-components";
import { notify, notifyError } from "../../helpers/toastNotificationService"
import { useNavigate, useLocation } from "react-router-dom";
import SynthFooter from "../../components/synth/Footer";
import SynthHeader from "../../components/synth/Header";
import Spinner from "../../components/layouts/Spinner/Spinner";
import { getScenesById, getScenes, getScenesDesign, deleteScene, uploadSceneImage, updateScenes } from "../../api/synth/scenes";
//import { sdxlCreateScene } from "../../api/synth/sdxl";
import EditStoryContext from './EditStoryContext';
import { createClaude, updateClaude } from "../../api/synth/claudeTestCreate";
import '@progress/kendo-theme-default/dist/all.css';


const user = {
  id: 1,
  //avatarUrl: "https://demos.telerik.com/kendo-ui/content/web/Customers/RICSU.jpg",
  //avatarAltText: "Conversational UI RICSU"
};

const bot = { id: 0 };

const initialMessages = [
  {
    author: bot,
    timestamp: new Date(),
    text: "Hello, How can I help you with your story?",
  },
];

const styleOptions = [
  { label: "Composite", value: "example1" },
  { label: "Environment", value: "example2" },
  { label: "Characters", value: "example2" },
  { label: "Props", value: "example2" },
];

//const sceneVideo = "";
const sceneVideo = "https://alsynthprod.s3.amazonaws.com/Landing+Page+vid_v1_HQ.mp4";

function SceneEditPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const [isLoading, setLoading] = useState(false);
  const [selectedStyle, setSelectedStyle] = useState(styleOptions[0].value);
  const [userInput, setUserInput] = useState("");
  const [scene, setScene] = useState([]);
  const [editableLocation, setEditableLocation] = useState(scene.sceneLocation);
  const [editableDescription, setEditableDescription] = useState(scene.sceneDesc);
  const [editablePositionDetails, setEditablePositionDetails] = useState(scene.sceneOrder);
  const [showGenerateButton, setShowGenerateButton] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [act, setAct] = useState([]);
  const [isLoadingResponse, setIsLoadingResponse] = React.useState(false);
  const loadingGifUrl = "https://alsynthprod.s3.amazonaws.com/3dotLoading2.gif"

  const projectName = queryParams.get('projectName');
  const storyId = queryParams.get('storyId');
  const sceneId = queryParams.get('sceneId');


  React.useEffect(() => {
    // setLoading(true);
    const retrieveScene = async () => {
      setLoading(true);  
      try {
            const response = await getScenesById(sceneId);
            //console.log('Scene response:', response);
            setScene(response);
            setEditableLocation(response.sceneLocation);
            setEditableDescription(response.sceneDesc);
            setEditablePositionDetails(response.sceneOrder);
            setAct(response.act);
            if (!response.sceneImage) {
              setShowGenerateButton(true);
            } else {
              setShowGenerateButton(false);
            }
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
            setLoadingMessage(null);
        }
    };
    retrieveScene();
}, [sceneId]); // Add storyId as a dependency to refetch when it changes



 ///////////////////////////////////////////////////////////////////
  // Chat Testing
  const { Chat } = require('@progress/kendo-react-conversational-ui');
  const [messages, setMessages] = React.useState(initialMessages);
  
  const addNewMessage = async (event) => {
    let botResponse = Object.assign({}, event.message);
    setMessages([...messages, event.message]);
    
    // Set loading state to true
    setIsLoadingResponse(true);
  
    // Add a temporary loading message with GIF
    const loadingMessage = {
      author: bot,
      text: "",
      timestamp: new Date(),
      loading: true, // Custom property to identify loading message
    };
    setMessages((oldMessages) => [...oldMessages, loadingMessage]);
  
    // Fetching response from Claude AI interaction
    botResponse.text = await claudeInteraction(event.message.text);
    botResponse.author = bot;
  
    // Adding suggested actions to the bot response
    botResponse.suggestedActions = [
      {
        value: "Add this to my story",
        type: "reply",
      },
      {
        value: "Change the tone",
        type: "reply",
      },
    ];
  
    // Remove the loading message
    setMessages((oldMessages) => oldMessages.filter((msg) => !msg.loading));
  
    // Set loading state to false
    setIsLoadingResponse(false);
  
    setTimeout(() => {
      setMessages((oldMessages) => [...oldMessages, botResponse]);
    }, 1000);
  };
  
  
  const onAction = (event) => {
    if (event.action.type === "alert") {
      setMessages([
        ...messages,
        {
          author: user,
          text: event.action.value,
        },
      ]);
    }
  };
  

  const claudeInteraction = async (question) => {
    console.log("Question: ", question);
    const requestBody = createAiRequestBody(question);
    console.log("Request Body: ", requestBody);
    const aiResponse = await createClaude({requestBody});
    console.log("AI Response: ", aiResponse);
    return aiResponse;
  };


  const createAiRequestBody = (ideaText) => {
    const requestBody = {
      prompt: ideaText,
    };
    return requestBody;
  }


///////////////////////////////////////////////////////////////////////

/////////////////////////////////////////////////  
  // Generate Missing AI Scene Images
  const handleGenerateMissingImages = async () => {
    //console.log("Clicked Generate Scene Images Button");
    setLoadingMessage('Generating Scenes... This will take a moment.');
    setLoading(true); // Initial loading message
  
    const charDesignData = await getScenesDesign(storyId);
    const response = await getScenesById(sceneId);
    setLoadingMessage(`Generating image for ${response.sceneLocation}...`);

    const requestBody = {
      storyId: storyId,
      sceneId: response.id,
      prompt: response.sceneDesc,
      seed: 0,
      stylePreset: charDesignData.artStyling,
    }
    
    //const genAiImage = await sdxlCreateScene({ requestBody });
    setLoading(false);
    setLoadingMessage(null);
    navigate(`/editchardetail?projectName=${encodeURIComponent(projectName)}&storyId=${encodeURIComponent(storyId)}&charId=${encodeURIComponent(scene.id)}`);
  };

  const saveSceneChanges = async () => {
    setLoadingMessage(`Saving updates...`)
    setLoading(true);
    const requestBody = {
      storyId: storyId,
      sceneId: sceneId[0],
      sceneLocation: editableLocation,
      sceneDesc: editableDescription,
      sceneOrder: editablePositionDetails,
    }
    console.log("Saving Scene...");
    const updateResponse = await updateScenes(sceneId, requestBody);
    setLoading(false);
  }

const handleStyleChange = (value) => {
    setSelectedStyle(value);
  };

  const handleUserInput = (event) => {
    setUserInput(event.target.value);
  };

  const handleLocationChange = (event) => {
    setEditableLocation(event.target.value);
    setScene({ ...scene, sceneLocation: event.target.value });
  };

  const handleDescriptionChange = (event) => {
    setEditableDescription(event.target.value);
    setScene({ ...scene, sceneDesc: event.target.value });
  };

  const handlePositionDetailsChange = (event) => {
    setEditablePositionDetails(event.target.value);
    setScene({ ...scene, sceneOrder: event.target.value });
  };

    const handleSubmit = () => {
    //console.log("Submit clicked with user input:", userInput);
    notify("This feature is not currently active");
  };

  const handleBackToScenes = () => {
    navigate(`/storyboard?projectName=${encodeURIComponent(projectName)}&storyId=${encodeURIComponent(storyId)}`);
  };

  const handleDeleteClick = async () => {
    //console.log("Delete icon clicked");
    const id = sceneId;
    //const response = await deleteScene(id);
    //navigate(`/storyboard?projectName=${encodeURIComponent(projectName)}&storyId=${encodeURIComponent(storyId)}`);
    //return;
    notify("This feature is not currently active");
  };

  ////////////////////////////////////////////////////////////
  // File Upload for Scene Image
  const handleAddImageClick = () => {
    document.getElementById('fileInput').click();
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      if (validateFile(file)) {
        setSelectedFile(file);
        await uploadFile(file);
      }
    }
  };


  const validateFile = (file) => {
    const allowedFormats = ['image/png', 'image/jpeg', 'image/svg'];
    const maxSizeInBytes = 5 * 1024 * 1024; // 5 MB

    if (!allowedFormats.includes(file.type)) {
      notifyError('Only .png, .jpg, and .svg formats are allowed.');
      return false;
    }

    if (file.size > maxSizeInBytes) {
      notifyError('File size must be less than 5MB.');
      return false;
    }

    setErrorMessage('');
    return true;
  };

  const uploadFile = async (file) => {
    //console.error('Upload started');
    setLoadingMessage('Upload Scene Image... This will take a moment.');
    setLoading(true);
    const formData = new FormData();
    formData.append('file', file);

    try {
      const response = await uploadSceneImage(sceneId, formData);
      console.error('Upload complete');
      setLoading(false);
      setLoadingMessage(null);
      navigate(`/editscene?projectName=${encodeURIComponent(projectName)}&storyId=${encodeURIComponent(storyId)}&sceneId=${encodeURIComponent(scene.id)}`);
    } catch (error) {
      console.error('Error uploading file:', error);
      notifyError('Error uploading file. Please try again.');
    }
    setLoading(false);
    setLoadingMessage(null);
  };


const handleGenerateClick = () => {

};

const handleGenerateAgainClick = () => {

};
const handleRepromptClick = () => {
  
};




  //////////////////////////////////////////////

  return (
    <EditStoryContext.Provider value={{ saveSceneChanges }}>
    <AppWrapper>
      <SynthHeader project={projectName} />
    <PageWrapper>
    {isLoading && (
          <ProgressOverlay>
            <ProgressStatus>{loadingMessage}</ProgressStatus>
            <Spinner />
          </ProgressOverlay>
        )}
      <ContentWrapper>
        <ContentContainer>
          <MainContent>
            <SceneDetails>
            <SceneImageWrapper>
              <SceneImage>
                {scene.sceneImage ? (
                  <img src={scene.sceneImage} alt="Scene" />
                ) : (
                  <>
                    <SubmitButton onClick={handleAddImageClick}>Add Image</SubmitButton>
                    <input
                      id="fileInput"
                      type="file"
                      style={{ display: 'none' }}
                      onChange={handleFileChange}
                    />
                    {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
                  </>
                )}
              </SceneImage>
              {scene.sceneImage ? (
                  <>
                    <SubmitButton onClick={handleAddImageClick}>Update Image</SubmitButton>
                    <input
                      id="fileInput"
                      type="file"
                      style={{ display: 'none' }}
                      onChange={handleFileChange}
                    />
                    {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
                  </>
                ): null }
            </SceneImageWrapper>
              <SceneInfo>
                  <SceneHeading>Edit Scene</SceneHeading>
                  <PositionDetailsLabel>{act}</PositionDetailsLabel>
                <PositionDetailsValue>
                  <textarea
                    value={editablePositionDetails}
                    onChange={handlePositionDetailsChange}
                  />
                </PositionDetailsValue>
                  <SceneNameLabel>Location:</SceneNameLabel>
                  <SceneNameValue>
                  <textarea
                      value={editableLocation}
                      onChange={handleLocationChange}
                    />
                  </SceneNameValue>
                <SceneDescriptionLabel>
                  Scene Description:
                </SceneDescriptionLabel>
                <SceneDescriptionValue>
                <textarea
                  value={editableDescription}
                  onChange={handleDescriptionChange}
                />
                </SceneDescriptionValue>
                {/*<SceneModelContainer>
                  <SceneModelLabel>Scene Model</SceneModelLabel>
                  <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/e2f2124071295e4272351a520e8c2f1f6841c334b685dd4b177a9f1446cd0f62?apiKey=6ef1b98222454dfe8bba8941b6ab01b5&" alt="Scene Model" />
                </SceneModelContainer>
                <SceneModel>
                    <img src={scene.charModel} alt="Scene" />
                </SceneModel>*/}
              </SceneInfo>
            </SceneDetails>
          </MainContent>
          <Sidebar>
            <SidebarContent>
              {/*<SidebarTitle>Scene Details</SidebarTitle>
              {styleOptions.map((option) => (
                <DropdownButton
                  key={option.value}
                  onClick={() => handleStyleChange(option.value)}
                  selected={selectedStyle === option.value}
                >
                  <DropdownLabel>{option.label}</DropdownLabel>
                  <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/e4ac5980bb131a5b8a88af422228b1ef331b85f15ea4655b64f28f607ec47f0c?apiKey=6ef1b98222454dfe8bba8941b6ab01b5&" alt="Dropdown Arrow" />
                </DropdownButton>
              ))}*/}
              {/*<SectionTitle>Section Title</SectionTitle>
              <DropdownButton>
                <DropdownLabel>Example Dropdown</DropdownLabel>
                <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/e4ac5980bb131a5b8a88af422228b1ef331b85f15ea4655b64f28f607ec47f0c?apiKey=6ef1b98222454dfe8bba8941b6ab01b5&" alt="Dropdown Arrow" />
              </DropdownButton>
              <SectionTitle>Section Title</SectionTitle>
              <DropdownButton>
                <DropdownLabel>Example Dropdown</DropdownLabel>
                <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/e4ac5980bb131a5b8a88af422228b1ef331b85f15ea4655b64f28f607ec47f0c?apiKey=6ef1b98222454dfe8bba8941b6ab01b5&" alt="Dropdown Arrow" />
            </DropdownButton>*/}
              <AIHelpText>
                Use AI to help you with your Scene.
                <br />
              </AIHelpText>
              {showGenerateButton && (
                <SubmitButton onClick={handleGenerateMissingImages}>
                    Generate Missing Scene Images
                </SubmitButton>
            )}
              {/*<AIHelpSubtext>
                Say what you want to change in your story, and AI will implement
                those changes.{" "}
              </AIHelpSubtext>
              <UserInputField
                value={userInput}
                onChange={handleUserInput}
                placeholder="User inputs text here"
              />
          <SubmitButton onClick={handleSubmit}>Submit</SubmitButton>*/}
          <ChatInput>
                  <Chat
                  user={user}
                  messages={messages.map((msg) => ({
                    ...msg,
                    text: msg.loading ? <img src={loadingGifUrl} alt="Loading..." /> : msg.text,
                  }))}
                  onMessageSend={addNewMessage}
                  placeholder={"Type a message..."}
                  onActionExecute={onAction}
                  width={275}
                />
              </ChatInput>
              <NavigationContainer>
                <BackButton onClick={handleBackToScenes}>
                  Back To Storyboard
                </BackButton>
                <DeleteIcon onClick={handleDeleteClick}>
                  <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/62a1b46d-beb6-46cb-ae19-49eb5030fbe8?apiKey=6ef1b98222454dfe8bba8941b6ab01b5&" alt="Delete" />
                </DeleteIcon>
              </NavigationContainer>
            </SidebarContent>
          </Sidebar>
        </ContentContainer>
      </ContentWrapper>
    </PageWrapper>
    <Divider />
    <SynthFooter />
    </AppWrapper>
    </EditStoryContext.Provider>
  );
}

const AppWrapper = styled.div`
  background-color: #fbfdff;
  display: flex;
  flex-direction: column;
  min-height: 100vh; // Ensure it takes the full viewport height
`;

const PageWrapper = styled.div`
  background-color: #fbfdff;
  display: flex;
  flex-direction: column;
  flex-grow: 1; // Allow it to grow and fill the remaining space
`;

const Divider = styled.div`
  border-color: rgba(33, 89, 162, 1);
  border-style: solid;
  border-width: 4px;
  background-color: #2159a2;
  z-index: 10;
  min-height: 4px;
  width: 100%;
  flex-shrink: 0; // Prevent it from shrinking
  margin-top: 37px;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const ContentWrapper = styled.div`
  align-self: center;
  z-index: 10;
  margin-top: 6px;
  width: 100%;
  flex-grow: 1; // Allow it to grow and fill the remaining space
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const ContentContainer = styled.div`
  gap: 20px;
  display: flex;
  flex-grow: 1; // Allow it to grow and fill the remaining space
  @media (max-width: 991px) {
    flex-direction: column;
    align-items: stretch;
    gap: 0px;
  }
`;

const MainContent = styled.main`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 85%;
  margin-left: 20px;
  margin-top: 40px;
  flex-grow: 1; // Allow it to grow and fill the remaining space
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const SceneDetails = styled.div`
display: flex; 
align-items: center;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
  }
`;
const SceneImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding-bottom: 20px;
  width: 800px;
  height: 100%;
  flex-grow: 1; // Allows the wrapper to expand to fill available space
`;

const SceneImage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center; // Centers vertically
  align-items: center; // Centers horizontally
  line-height: normal;
  width: 550px;
  height: 290px;
  margin-left: 5%; // Adjusted to center the container
  min-height: 250px;
  @media (max-width: 991px) {
    width: 100%;
    margin-left: 0; // Adjust for smaller screens
  }

  img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain; // Ensures the image scales to fit within the container without distorting
  }
`;



const SceneModel = styled.div`
display: flex;
width: 245;
height: 213;
`;

const SceneInfo = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 70%;
  
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const SceneHeading = styled.h1`
  color: #011126;
  text-align: center;
  margin-left: 20px;
  font: 700 32px 'Josefin Sans', sans-serif;
  @media (max-width: 991px) {
    margin-left: 10px;
  }
`;

const SceneNameLabel = styled.label`
font: 600 24px 'Josefin Sans', sans-serif;
  margin-top: 42px;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
  }
`;

const SceneNameValue = styled.div`
  background-color: #fff;
  align-items: start;
  justify-content: center;
  font: 400 20px 'Josefin Sans', sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
    padding-right: 20px;
  }
`;

const SceneDescriptionLabel = styled.label`
font: 600 24px 'Josefin Sans', sans-serif;
  margin-top: 32px;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const SceneDescriptionValue = styled.div`
  background-color: #fff;
  align-items: start;
  font: 400 20px 'Josefin Sans', sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
    padding-right: 20px;
  }
`;

const PositionDetailsLabel = styled.label`
font: 600 24px 'Josefin Sans', sans-serif;
  margin-top: 18px;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const PositionDetailsValue = styled.div`
  background-color: #fff;
  font: 400 20px 'Josefin Sans', sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
    padding-right: 20px;
  }
`;

const SceneModelContainer = styled.div`
  align-self: start;
  display: flex;
  margin-top: 27px;
  gap: 14px;
`;

const SceneModelLabel = styled.span`
font: 600 24px 'Josefin Sans', sans-serif;
  flex-grow: 1;
`;

const Sidebar = styled.aside`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 15%;
  min-width: 300px;
  margin-left: 20px;
  flex-shrink: 0; // Prevent the sidebar from shrinking
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const SidebarContent = styled.div`
  background-color: #f7f5f5;
  display: flex;
  width: 100%;
  flex-grow: 1; // Allow it to grow and fill the remaining space
  flex-shrink: 0; // Prevent it from shrinking
  flex-direction: column;
  margin: 0 auto;
  padding: 10px 10px;
  @media (max-width: 991px) {
    margin-top: 40px;
    padding: 0 20px;
  }
`;

const SidebarTitle = styled.h2`
  color: #000;
  font: 600 18px 'Josefin Sans', sans-serif;
`;

const DropdownButton = styled.button`
  border: 1px solid rgba(217, 217, 217, 1);
  background-color: ${(props) => (props.selected ? "#e0e0e0" : "#fff")};
  display: flex;
  margin-top: 9px;
  gap: 20px;
  font-size: 15px;
  color: #000;
  font-weight: 400;
  justify-content: space-between;
  padding: 10px 16px 10px 12px;
  cursor: pointer;
  img {
    aspect-ratio: 1.79;
    object-fit: auto;
    object-position: center;
    width: 16px;
    margin: auto 0;
  }
`;

const DropdownLabel = styled.span`
  font-family: 'Josefin Sans', sans-serif;
`;

const SectionTitle = styled.h3`
  color: #000;
  margin-top: 40px;
  font: 600 18px 'Josefin Sans', sans-serif;
`;

const AIHelpText = styled.p`
  color: #24777d;
  margin-top: 28px;
  font: 700 18px 'Josefin Sans', sans-serif;
`;

const AIHelpSubtext = styled.p`
  color: #011126;
  margin-top: 14px;
  font: 400 14px 'Josefin Sans', sans-serif;
`;

const UserInputField = styled.textarea`
  justify-content: flex-end;
  align-items: start;
  border: 1px solid rgba(129, 129, 138, 1);
  background-color: #fff;
  margin-top: 15px;
  color: #81818a;
  padding: 29px 25px 170px;
  font: 400 15px 'Josefin Sans', sans-serif;
  @media (max-width: 991px) {
    padding: 0 20px 40px;
  }
`;

const SubmitButton = styled.button`
  font-family: 'Josefin Sans', sans-serif;
  justify-content: center;
  border-radius: 15px;
  box-shadow: 3px 4px 6.1px 0px rgba(0, 0, 0, 0.25);
  background-color: #24777d;
  align-self: center;
  margin-top: 20px;
  color: #fbfdff;
  font-weight: 600;
  white-space: nowrap;
  text-align: center;
  padding: 10px 45px;
  border: none;
  cursor: pointer;

  @media (max-width: 991px) {
    white-space: initial;
    padding: 10px 20px;
  }
`;

const NavigationContainer = styled.nav`
  display: flex;
  margin-top: 20px;
  gap: 20px;
  justify-content: space-between;
  flex-shrink: 0; // Prevent it from shrinking
  @media (max-width: 991px) {
    margin-top: 40px;
  }
`;

const BackButton = styled.button`
  justify-content: center;
  border-radius: 15px;
  box-shadow: 3px 4px 6.1px 0px rgba(0, 0, 0, 0.25);
  border: 1px solid rgba(217, 217, 217, 1);
  background-color: #fff;
  color: #2159a2;
  text-align: center;
  padding: 13px 29px;
  font: 600 18px 'Josefin Sans', sans-serif;
  cursor: pointer;
  @media (max-width: 991px) {
    padding: 0 20px;
  }
`;

const DeleteIcon = styled.button`
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  box-shadow: 3px 3px 9.2px 0px rgba(0, 0, 0, 0.25);
  border: 1px solid rgba(217, 217, 217, 1);
  background-color: #fff;
  display: flex;
  padding: 10px 20px;
  cursor: pointer;
  flex-shrink: 0; // Prevent it from shrinking
  img {
    aspect-ratio: 0.85;
    object-fit: auto;
    object-position: center;
    width: 23px;
    fill: #2159a2;
  }
`;


const ProgressOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  right: 0;
  bottom: 0;
  background: rgba(205, 205, 205, 0.8);
  z-index: 7777;
  position: fixed;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  flex-wrap: wrap;
`;
  
const ProgressStatus = styled.div`
  display: block;
  width: 100%;
  font-weight: 400;
  font-size: 24px;
  line-height: 24px;
  margin-bottom: 8px;
  text-align: center;
  color: #011126;
`;

const DropdownOptions = styled.ul`
  display: ${(props) => (props.open ? 'block' : 'none')};
  list-style: none;
  padding: 0;
  margin: 0;
  border: 1px solid #ccc;
  background-color: #fff;
`;

const DropdownOption = styled.li`
  padding: 8px 16px;
  cursor: pointer;
  &:hover {
    background-color: #f0f0f0;
  }
`;

const ChatInput = styled.div`
  height: 500px;
`;

export default SceneEditPage;