/*-------ENV Variables----*/
export const {
  REACT_APP_API_URL,
  REACT_APP_FRONTEND_URL,
  REACT_APP_HEDERA_ESCROW_ACCOUNT_ID,
  REACT_APP_HEDERA_ACCOUNT_ID,
  REACT_APP_ENVIRONMENT,
  REACT_APP_HASH_CONNECT_NETWORK,
  REACT_APP_HEDERA_API,
  REACT_APP_SQUARE_APPLICATION_ID,
  REACT_APP_SQUARE_LOCATION_ID,
  REPLICATE_API_TOKEN,
} = process.env;

/*-------General Page----*/
export const BASE_URL = window.location.origin.toString();
export const REQUIRED_MESSAGE = "This field is required";
export const NAVBAR_TRANSITION_TIME = 500;
export const SUCCESFUL_SUBSCRIPTION = "You have successfully subscribed";
export const HEADER_HEIGHT = 100;
export const MOBILE_HEADER_HEIGHT = 120;
export const AFFILIATE_QUERY_STRING = "affl";
export const ONE_DAY_MS = 1000 * 60 * 60 * 24;
export const AFFILIATE_CODE_EXPIRACIY_IN_DAYS = 7;
/*-------Home Page----*/
export const MOBILE_TOP_TREND_LIMIT = 6;
export const DESKTOP_TOP_TREND_LIMIT = 8;

/*-------Create Collection Page----*/
export const ROOM_POST_DATA = {
  baseFields: {
    parent: 0,
    count: null,
    menuOrder: null,
  },
  acf: {
    proppoints: [],
    spawnpoints: [
      {
        id: 0,
        type: "single",
        desc: "grp_0",
        threed: 0,
        tx: -10.105,
        ty: 2,
        tz: -8.634793281555176,
        rx: 0,
        ry: 270,
        rz: 0,
        sx: 1,
        sy: 1,
        sz: 1,
      },
      {
        id: 1,
        type: "single",
        desc: "grp_0",
        threed: 0,
        tx: -10.105,
        ty: 2,
        tz: -4.532785415649414,
        rx: 0,
        ry: 270,
        rz: 0,
        sx: 1,
        sy: 1,
        sz: 1,
      },
      {
        id: 2,
        type: "single",
        desc: "grp_0",
        threed: 0,
        tx: -10.105,
        ty: 2,
        tz: -0.5230994820594788,
        rx: 0,
        ry: 270,
        rz: 0,
        sx: 1,
        sy: 1,
        sz: 1,
      },
      {
        id: 3,
        type: "single",
        desc: "grp_0",
        threed: 0,
        tx: -10.105,
        ty: 2,
        tz: 3.505892276763916,
        rx: 0,
        ry: 270,
        rz: 0,
        sx: 1,
        sy: 1,
        sz: 1,
      },
      {
        id: 4,
        type: "single",
        desc: "grp_0",
        threed: 0,
        tx: -10.105,
        ty: 2,
        tz: 7.6220903396606445,
        rx: 0,
        ry: 270,
        rz: 0,
        sx: 1,
        sy: 1,
        sz: 1,
      },
      {
        id: 5,
        type: "single",
        desc: "grp_0",
        threed: 0,
        tx: 10,
        ty: 2,
        tz: -8.634793281555176,
        rx: 0,
        ry: 90,
        rz: 0,
        sx: 1,
        sy: 1,
        sz: 1,
      },
      {
        id: 6,
        type: "single",
        desc: "grp_0",
        threed: 0,
        tx: 10,
        ty: 2,
        tz: -4.532785415649414,
        rx: 0,
        ry: 90,
        rz: 0,
        sx: 1,
        sy: 1,
        sz: 1,
      },
      {
        id: 7,
        type: "single",
        desc: "grp_0",
        threed: 0,
        tx: 10,
        ty: 2,
        tz: -0.5230994820594788,
        rx: 0,
        ry: 90,
        rz: 0,
        sx: 1,
        sy: 1,
        sz: 1,
      },
      {
        id: 8,
        type: "single",
        desc: "grp_0",
        threed: 0,
        tx: 10,
        ty: 2,
        tz: 3.505892276763916,
        rx: 0,
        ry: 90,
        rz: 0,
        sx: 1,
        sy: 1,
        sz: 1,
      },
      {
        id: 9,
        type: "single",
        desc: "grp_0",
        threed: 0,
        tx: 10,
        ty: 2,
        tz: 7.6220903396606445,
        rx: 0,
        ry: 90,
        rz: 0,
        sx: 1,
        sy: 1,
        sz: 1,
      },
      {
        id: 10,
        type: "single",
        desc: "grp_0",
        threed: 0,
        tx: -8.239852905273438,
        ty: 2,
        tz: -9.953,
        rx: 0,
        ry: 180,
        rz: 0,
        sx: 1,
        sy: 1,
        sz: 1,
      },
      {
        id: 11,
        type: "single",
        desc: "grp_0",
        threed: 0,
        tx: -5.074534579769535,
        ty: 2,
        tz: -9.953,
        rx: 0,
        ry: 180,
        rz: 0,
        sx: 1,
        sy: 1,
        sz: 1,
      },
      {
        id: 12,
        type: "single",
        desc: "grp_0",
        threed: 0,
        tx: 5.60907652721048,
        ty: 2,
        tz: -9.953,
        rx: 0,
        ry: 180,
        rz: 0,
        sx: 1,
        sy: 1,
        sz: 1,
      },
      {
        id: 13,
        type: "single",
        desc: "grp_0",
        threed: 0,
        tx: 8.017030715942383,
        ty: 2,
        tz: -9.953,
        rx: 0,
        ry: 180,
        rz: 0,
        sx: 1,
        sy: 1,
        sz: 1,
      },
      {
        id: 14,
        type: "single",
        desc: "grp_0",
        threed: 0,
        tx: 8.239852905273438,
        ty: 2,
        tz: 10.154,
        rx: 180,
        ry: 180,
        rz: 180,
        sx: 1,
        sy: 1,
        sz: 1,
      },
      {
        id: 15,
        type: "single",
        desc: "grp_0",
        threed: 0,
        tx: 4.137845039367676,
        ty: 2,
        tz: 10.154,
        rx: 180,
        ry: 180,
        rz: 180,
        sx: 1,
        sy: 1,
        sz: 1,
      },
      {
        id: 16,
        type: "single",
        desc: "grp_0",
        threed: 0,
        tx: 0.12815915048122406,
        ty: 2,
        tz: 10.154,
        rx: 180,
        ry: 180,
        rz: 180,
        sx: 1,
        sy: 1,
        sz: 1,
      },
      {
        id: 17,
        type: "single",
        desc: "grp_0",
        threed: 0,
        tx: -3.9008326530456543,
        ty: 2,
        tz: 10.154,
        rx: 180,
        ry: 180,
        rz: 180,
        sx: 1,
        sy: 1,
        sz: 1,
      },
      {
        id: 18,
        type: "single",
        desc: "grp_0",
        threed: 0,
        tx: -8.017030715942383,
        ty: 2,
        tz: 10.154,
        rx: 180,
        ry: 180,
        rz: 180,
        sx: 1,
        sy: 1,
        sz: 1,
      },
    ],
    storename: "",
    decalpoints: "",
    defaultsearchterm: "",
    envprefab: "Gallery_v7",
    menu_buttons: false,
    videos: [{ url: "" }],
    store_ecal: false,
    store_sponsor: true,
    audiotrack: "",
    storepass: "",
    cubemap: "",
    cdn_env: "https://dooadqailrtb2.cloudfront.net/webxr/gallery_v8l.gltf",
    playerspawnpoint: "0,1,0,0,0,0,0",
    nft: "acmelabs",
    coin: false,
    isStore: true,
  },
  image: {
    id: 1234,
    src: "",
    name: "",
    alt: "",
  },
};

/*-------Create Item Page----*/
export const PHYSICAL_PRODUCT_TYPE = "physical";
export const DIGITAL_PRODUCT_TYPE = "digital";
export const SUCCESFUL_ITEM_CREATION = "Item created successfully";
export const ERROR_CREATING_ITEM = "There was an error creating the item";
export const THUMBNAIL_QUALITY_COMPRESSION_FACTOR = 0.7;
export const CURRENCY_SELECTION_DROPDOWN = [
  { label: "USD", value: "USD" },
  { label: "HBAR", value: "HBAR" },
];
export const RANDOM_SELECTION_DROPDOWN = [
  { label: "No", value: "N" },
  { label: "Yes", value: "Y" },
];
export const UNIQUE_SELECTION_DROPDOWN = [
  { label: "Generic", value: "N" },
  { label: "Unique", value: "Y" },
];
export const COA_SELECTION_DROPDOWN = [
  { label: "No", value: "N" },
  { label: "Yes", value: "Y" },
];
export const WHO_PAYS_SELECTION_DROPDOWN = [
  { label: "Buyer", value: "BUYER" },
  { label: "Seller", value: "SELLER" },
];
export const UNIQUE_SELECTION_DEFAULT_VALUE = "N";
export const COA_SELECTION_DEFAULT_VALUE = "N";
export const WHO_PAYS_DEFAULT_VALUE = "BUYER";
export const CRATE_IMAGE =
  "https://nftstorage.link/ipfs/bafybeidtdnfyb4vbgzlx3habg7jwnls4ga6mtvpvpuvicb2z64geluzvtm/crate";
export const TRAITS_ENABLED = true;
export const DATES_ENABLED = true;
export const MAX_ALLOWED_FILE_SIZE = 3 * 1024 * 1024; //3 MB
export const MAX_ALLOWED_HIGH_RES_FILE_SIZE = 25 * 1024 * 1024; //25 MB
export const HIGH_RES_SIZE_ERROR = "Maximum allowed file size is 25 MB";
export const FILE_TYPES_ALLOWED_MESSAGE =
  ".png, .jpg, .mp4 or gif files maximum: 3mb";

/*-------Spaces Page----*/
export const IS_FOLLOW_ENABLED = false;
export const IS_SHARE_ENABLED = true;
export const IS_OTHER_OPTIONS_ENABLED = false;
export const IS_TESTING_DESCRIPTION_UI = false;
export const PLACEHOLDER_DESCRIPTION = `Lorem ipsum dolor sit amet consectetur adipisicing elit.
Impedit, doloremque! Deserunt aperiam tempore provident et,
rem amet ipsam deleniti, odit, eius aliquam officiis
corporis dolore consectetur dolorem ducimus ipsum magni.`;

/*-------Collection Page----*/
export const IS_VIRTUAL_SPACE_ENABLED = true;
export const CUSTOM_URL_PATTERN = /^[a-zA-Z0-9]+$/;
export const CUSTOM_URL_INVALID_MESSAGE =
  "Custom URL must not contain special characters";

/*-------Sign In Page----*/
export const EMAIL_PATTERN =
  /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/g;
export const EMAIL_INVALID_MESSAGE = "*This email is invalid.";
export const PASSWORD_PATTERN =
  /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
export const PASSWORD_INVALID_MESSAGE =
  "*Password must have a minimum eight characters, at least one letter, one number and one special character";
export const EXISTENT_USER_ERROR_CODE = "user_exists";
export const EXISTENT_EMAIL_MESSAGE = "*This email is already in use.";
export const EXISTENT_EMAIL_NOTIFICATION =
  "It appears this email address is already in use. Please reset your password for the existing account or use a different email address.";
export const FORGOT_PASSWORD = "Forgot password?";
export const PASSWORD_INVALID_LOGIN_MESSAGE = "*This password is invalid.";
export const PASSWORDS_NOT_MATCHING = "*Passwords do not match.";
export const SIGNUP_ERRORS_PRESENT =
  "*There was an error in the highlighted field. Please try again.";
export const AUTH0_LOGIN_ERROR_STATUS_CODE = 403;
export const AUTH0_MANY_ATTEMPTS_CODE = "too_many_attempts";
export const AUTH0_MANY_ATTEMPTS_SOLUTION =
  "*Your account has been blocked after multiple consecutive login attempts. We've sent you an email with instructions on how to unblock it.";
export const LOGIN_ERROR_MESSAGE = "*Invalid Email and/or Password.";
export const FORGOT_PASSWORD_EMAIL_SENT =
  "We've just sent you an email to reset your password.";
export const TODAYS_PICKS_DESCRIPTION = `A showcase of new, interesting and notable releases`;
export const EXPLORE_COLLECTIONS_URL = "/explore-items";
export const REQUIRED_ACCEPT_MESSAGE = "Must accept to create an account";
export const FIRST_NAME_CLAIM = "https://acmelabs.io/claims/firstName";
export const LAST_NAME_CLAIM = "https://acmelabs.io/claims/lastName";
export const IS_SOCIAL_ENABLED = false;

/*-------Edit Profile Page----*/
export const VIRTUAL_PLACE_INDICATIONS =
  "This is your personal space displaying any items and collections you own.";
export const SUCCESFUL_PROFILE_EDITING = "Profile Editing was succesful";

/*-------Create Collection Page----*/
export const SUCCESFUL_COLLECTION_CREATION = "Collection created successfully";
export const ERROR_CREATING_COLLECTION =
  "There was an error creating the collection";

export const ROYALTY_FEES = [
  { label: "0%", value: 0 },
  { label: "2.5%", value: 2.5 },
  { label: "5%", value: 5 },
  { label: "7.5%", value: 7.5 },
  { label: "10%", value: 10 },
];

export const LIMITED_COLLECTION_DROPDOWN = [
  { label: "No", value: false },
  { label: "Yes", value: true },
];
/*-------Contact Us Page-----*/
export const CONTACT_CATEGORY_SELECTION_DROPDOWN = [
  { label: "Buying", value: "Buying" },
  { label: "Selling", value: "Selling" },
  { label: "Registration", value: "Registration" },
  { label: "Connecting Wallet", value: "Connecting Wallet" },
  { label: "Other", value: "Other" },
];
export const CONTACT_ERROR_MESSAGE =
  "Unable to send feedback. Please contact support for additional help";
export const CONTACT_SUCCESFUL_MESSAGE = "Help message sent successfully";

/*-------Checkout PAGE----*/
export const FIXED_SHIPPING_HBAR_COST = 200;
export const FIXED_SHIPPING_USD_COST = 8;
export const HBAR_CURRENCY_TYPE = "HBAR";
export const USD_CURRENCY_TYPE = "USD";
export const LIMITED_PRODUCT_QUANTITY_MESSAGE =
  "Presale is limited to a single item per customer";
export const SALE_TRANSACTION_FEE = 0.025;
export const CONVERTED_PRICE_ENABLED = false;
export const BASE_ITEM_TYPE = "BASEITEM";
export const RESALE_ITEM_TYPE = "ITEM";
export const ADDON_TYPE = "ADDON";
export const RESERVATION_VALID_DURATION = 300;
export const RESERVATION_TIMER_INTERVAL = 1000;
export const VAULT_WALLET_TYPE = "VAULT";
export const HASHPACK_WALLET_TYPE = "HASHPACK";
export const VENLY_WALLET_TYPE = "VENLY";
export const ALL_WALLET_TYPES = [
  VAULT_WALLET_TYPE,
  HASHPACK_WALLET_TYPE,
  VENLY_WALLET_TYPE,
];
export const US_COUNTRY_CODE = "US";
export const TAX_CALCULATION_MESSAGE =
  "Unable to calculate taxes. Please contact support for additional help";
export const ZIP_CODE_STATE_ERROR_MESSAGE =
  "Please ensure ZIP Code and State are correct.";

/*-------Filters component----*/
export const FOR_SALE_ID = "FOR_SALE";
export const OWNED_ID = "Purchased";

export const NEW_ID = "NEW";
export const RESALE_ID = "RESALE";

export const VAULT_ID = "VAULT";

/*-------Item Details PAGE----*/
export const TOKEN_STANDARD = "HBAR";
export const BLOCKCHAIN = "HEDERA";
export const IS_VIEW_AT_HOME_ENABLED = true;

/*-------Cart PAGE----*/
export const RESERVATION_ERROR =
  "There was an issue creating your reservation, please contact support";

/*-------My Items PAGE----*/
export const OWNED_TYPE = "owned";
export const OWNED_TYPE_FORMATTED = "Purchased";
export const PHYSICAL_PRINTS_TYPE = "printedItems";
export const WALLET_NOT_CONNECTED_ERROR =
  "You must connect your wallet to sell items.";
export const CUSTOM_PAGE_SIZE = 100;
export const OUT_OF_STOCK_ADDON = "Configuration combination is out of stock";
export const VALID_COMBINATION = "VALID COMBINATION";
export const INVALID_CONFIGURATION_COMBINATION =
  "Invalid Configuration combination:";
export const NOT_FOUND_CONFIGURATION_COMBINATION =
  "Configuration combination not found in SKU look-up";
export const QID = "questionId=",
  QDESC = "questionDescription=",
  VID = "ValueId=",
  VDESC = "ValueDescription=";

/*-------Wallet Connect PAGE----*/
export const VENLY_WIDGET_BLOCKED =
  "Something went wrong while trying to open the popup";

export const HASH_CONNECT_APP_METADATA = {
  name: "ACME LABS™",
  description:
    "Connect a wallet to receive an ACME LABS™ Place and be ready to buy, sell or show off your art and collections. ",
  icon: "https://acmelabs.xyz/static/media/logo_dark.690368bb2961539616b5.png",
};

export const HASHPACK_CHROME_EXTENSION_URL =
  "https://chrome.google.com/webstore/detail/hashpack/gjagmgiddbbciopjhllkdnddhcglnemk";

  /*-------ACME SYNTHESIZER----*/
export const SUCCESFUL_STORY_CREATION = "Story created successfully";
export const ERROR_CREATING_STORY = "There was an error creating the story";
export const ERROR_MISSING_TEXT = "Please provide either an existing story concept or a prompt to allow AI to help you create one";
export const ERROR_TEXT_IN_BOTH = "Please populate only one of the story options";


/* Add Character Prompt */
export const ADD_CHARACTER_PROMPT = "You are an AI screenwriting coach with a deep understanding of story structure, character development, dialogue, formatting, and genre conventions. Your task is to guide and support users in creating compelling, well-crafted screenplays by offering expert advice, constructive feedback, and practical suggestions. You also possess a highly creative mindset, capable of generating unique ideas, exploring unconventional storytelling techniques, and pushing the boundaries of traditional screenwriting. Create a character description that follows these steps. 1.	Carefully review the content of the existing story, analyzing the story structure, pacing, and narrative arcs. 2. Generate a concise physical description that captures their key features, such as age, build, hair, eyes, and any distinctive characteristics. Ensure these descriptions are suitable for use with a text-to-image AI model.";
export const ADD_SCENE_PROMPT = "You are an AI screenwriting coach with a deep understanding of story structure, character development, dialogue, formatting, and genre conventions. Your task is to guide and support users in creating compelling, well-crafted screenplays by offering expert advice, constructive feedback, and practical suggestions. You also possess a highly creative mindset, capable of generating unique ideas, exploring unconventional storytelling techniques, and pushing the boundaries of traditional screenwriting. Add the new scene information provided here into th eexisting story and ensure it maintains its existing format including scene headings, action lines, dialogue, and transitions.";
export const ADD_TO_STORY_PROMPT = "You are an AI screenwriter with a deep understanding of story structure, character development, dialogue, formatting, and genre conventions. Your task is to take story elements provided by the user and incorproate them into the existing story text in a compelling way that improves the story while maintaing the existing story formatting. When a user submits this update, follow these steps: 1. Carefully review the content, analyzing the story structure, pacing, and narrative arcs. 2. Add dialogue where appropriate that is effective, with distinct character voices, subtext, and realistic conversations. 3. Ensure proper screenplay formatting, including scene headings, action lines, dialogue, and transitions. Once complete, return the full, complete version of the story with the included changes";


/* Character Design */
export const ARTSTYLING = [
  { label: "Anime", value: "Anime" },
  { label: "Digital Art", value: "Digital Art" },
  { label: "Neon Punk", value: "Neon Punk" },
];
export const GENRE = [
  { label: "Action", value: "Action" },
  { label: "Fantasy", value: "Fantasy" },
  { label: "Western", value: "Western" },
  { label: "Science Fiction", value: "Science Fiction" },
];


