import Activity from "./Activity/Activity";
import CreateArtist from "./Artist/CreateArtist";
import MyArtists from "./Artist/MyArtists";
import Authors02 from "./ArtistPage/Authors02";
import Authors01 from "./Authors01";
import BaseItemDetails from "./BaseItems/BaseItemsDetails";
import Blank from "./BlankPage/Blank";
import Blog from "./Blog";
import BlogDetails from "./BlogDetails";
import Cart from "./Cart/Cart";
import Collection from "./Collection/Collection";
import Contact01 from "./Contact01";
import Contact02 from "./Contact02";
import CreateCollection from "./CreateCollection/CreateCollection";
import CreateItem from "./CreateItem/CreateItem";
import Explore04 from "./Explore/Explore04";
import ExploreQuantityNFT from "./ExploreQuantityNFT/ExploreQuantityNFT";
import FAQ from "./FAQ";
import HelpCenter from "./HelpCenter";
import Home from "./Home";
//import Home01 from "./Home01";
import Marketplace from "./Marketplace"
import ItemDetails02 from "./ItemDetails02";
import LiveAuctions from "./LiveAuctions";
import Login from "./Login/Login";
import MyCollections from "./MyCollections/MyCollections";
import EditItem from "./MyItems/EditItem";
import MyItems from "./MyItems/MyItems";
import NoResult from "./NoResult";
import OrderConfirmation from "./OrderConfirmation/OrderConfirmation";
import PrintItems from "./Print/PrintItems";
import Ranking from "./Ranking/Ranking";
import SignUp from "./Sign-up/SignUp";
import StripePageWrapper from "./Stripe/StripePageWrapper";
import EditProfile from "./editProfile/EditProfile";
import WalletConnect from "./walletConnect/WalletConnect";
//import StripeCheckoutWrapper from './Stripe/StripeCheckoutWrapper';
import AboutUs from "./AboutUs/AboutUs";
import AdministrationPage from "./AdministrationPage/AdministrationPage";
import AnnouncementDetail from "./AnnouncementDetail/AnnouncementDetail";
import ContactUs from "./ContactUs/ContactUs";
import CreateActivity from "./CreateActivity/CreateActivity";
import ForgotPassword from "./ForgotPassword/ForgotPassword";
import ForgotPasswordEmailSent from "./ForgotPassword/ForgotPasswordEmailSent";
import CookieSettings from "./Legal/CookieSettings";
import PrivacyPolicy from "./Legal/PrivacyPolicy";
import TermsAndConditions from "./Legal/TermsAndConditions";
import MyOrders from "./MyOrders/MyOrders";
import ResetPassword from "./ResetPassword/ResetPassword";
import SelectProduct from "./SelectProduct/SelectProduct";
import EmailVerified from "./VerificationEmail/EmailVerified";
import VerificationEmailSent from "./VerificationEmail/VerificationEmailSent";
import AdditionalPurchaseOptions from "./AdditionalPurchase/AdditionalPurchaseOptions";
import ExploreCollections from "./ExploreCollections/ExploreCollections";
import LandingPage from "./LandingPage/LandingPage"
import SynthProjects from "./Synth/synthProjects"
import CreateStory from "./Synth/createStory"
import EditStory from "./Synth/editStory"
import SynthHome from "./Synth/synthHome";
import EditChar from "./Synth/editChar";
import EditCharDetail from "./Synth/editCharDetail";
import CreateChar from "./Synth/createCharacter";
import StoryBoard from "./Synth/storyboard"
import EditScene from "./Synth/editScene"
import Review from "./Synth/review"
import Animate1Page from "./Synth/animate1";
import AnimateColorize from "./Synth/animateColorize";
import StoryBoardAnimation from "./Synth/storyboardAnimation";
import TaskManager from "./Synth/taskMgmt";
import SynthTasks from "./Synth/synthTasks";

const routes = [
  /*{ path: "/", component: <LandingPage />, reqAuth: false },*/
  { path: "/home", component: <Home />, reqAuth: false },  
  { path: "/", component: <SynthHome />, reqAuth: false },
  { path: "/about-us", component: <AboutUs />, reqAuth: false },
  { path: "/contact-us", component: <ContactUs />, reqAuth: false },
  { path: "/explore-04", component: <Explore04 />, reqAuth: false },
  { path: "/live-auctions", component: <LiveAuctions />, reqAuth: false },
  {
    path: "/item-details-01/:baseItemId",
    component: <BaseItemDetails />,
    reqAuth: false,
  },
  {
    path: "/item-details/:baseItemId",
    component: <BaseItemDetails />,
    reqAuth: false,
  },
  { path: "/item-details-02", component: <ItemDetails02 />, reqAuth: false },
  { path: "/activity-02", component: <Activity />, reqAuth: false },
  {
    path: "/announcement-details/:announcementId",
    component: <AnnouncementDetail />,
    reqAuth: false,
  },
  { path: "/synthhome", component: <SynthHome />, reqAuth: false },
  { path: "/marketplace", component: Marketplace, reqAuth: true },
  { path: "/synthprojects", component: SynthProjects, reqAuth: true },
  { path: "/createstory", component: CreateStory, reqAuth: true },
  { path: "/editstory", component: EditStory, reqAuth: true },
  { path: "/editchar", component: EditChar, reqAuth: true },
  { path: "/editchardetail", component: EditCharDetail, reqAuth: true },
  { path: "/createchar", component: CreateChar, reqAuth: true },
  { path: "/storyboard", component: StoryBoard, reqAuth: true },
  { path: "/editscene", component: EditScene, reqAuth: true },
  { path: "/review", component: Review, reqAuth: true },
  { path: "/storyboardanimation", component: StoryBoardAnimation, reqAuth: true },
  { path: "/animate1", component: Animate1Page, reqAuth: true },
  { path: "/animatecolorize", component: AnimateColorize, reqAuth: true },
  { path: "/taskmanager", component: TaskManager, reqAuth: true },
  { path: "/tasks", component: SynthTasks, reqAuth: true },

  { path: "/blog", component: <Blog />, reqAuth: false },
  { path: "/privacy-policy", component: <PrivacyPolicy />, reqAuth: false },
  { path: "/terms", component: <TermsAndConditions />, reqAuth: false },
  { path: "/cookies", component: <CookieSettings />, reqAuth: false },
  { path: "/blog-details", component: <BlogDetails />, reqAuth: false },
  { path: "/help-center", component: <HelpCenter />, reqAuth: false },
  { path: "/authors-01", component: <Authors01 />, reqAuth: false },
  { path: "/wallet-connect", component: WalletConnect, reqAuth: true },
  { path: "/my-items", component: MyItems, reqAuth: true },
  { path: "/my-collections", component: MyCollections, reqAuth: true },
  { path: "/my-artists", component: MyArtists, reqAuth: true },
  { path: "/my-orders", component: MyOrders, reqAuth: true },
  { path: "/create-item/:productType", component: CreateItem, reqAuth: true },
  { path: "/select-product", component: SelectProduct, reqAuth: true },
  { path: "/administrative", component: AdministrationPage, reqAuth: true },
  { path: "/create-activity", component: CreateActivity, reqAuth: true },
  {
    path: "/additional-purchase-options/:itemId",
    component: AdditionalPurchaseOptions,
    reqAuth: true,
  },
  {
    path: "/create-artist",
    component: CreateArtist,
    reqAuth: true,
  },
  {
    path: "/create-collection",
    component: CreateCollection,
    reqAuth: true,
  },
  {
    path: "/collection/:collectionId",
    component: <Collection />,
    reqAuth: false,
  },
  {
    path: "/wrabbit",
    component: <Collection alias={"wrabbit"} />,
    reqAuth: false,
  },
  {
    path: "/animationmasterpieces",
    component: <Collection alias={"animationmasterpieces"} />,
    reqAuth: false,
  },
  {
    path: "/print/:itemId",
    component: PrintItems,
    reqAuth: true,
  },
  {
    path: "/edit-item/:itemId",
    component: <EditItem />,
    reqAuth: false,
  },
  {
    path: "/spaces/:customUrl",
    component: <Authors02 />,
  },
  {
    path: "/cart",
    component: Cart,
    reqAuth: true,
  },
  {
    path: "/order/:id/confirmation",
    component: OrderConfirmation,
    reqAuth: true,
  },
  { path: "/explore-items", component: <ExploreQuantityNFT />, reqAuth: false },
  {
    path: "/explore-collections",
    component: <ExploreCollections />,
    reqAuth: false,
  },
  { path: "/blank-page", component: <Blank />, reqAuth: false },
  { path: "/edit-profile", component: EditProfile, reqAuth: true },
  { path: "/ranking", component: <Ranking />, reqAuth: false },
  { path: "/login", component: <Login />, reqAuth: false },
  { path: "/sign-up", component: <SignUp />, reqAuth: false },
  {
    path: "/verification-email-sent",
    component: <VerificationEmailSent />,
    reqAuth: false,
  },
  { path: "/email-verified", component: <EmailVerified />, reqAuth: false },
  { path: "/forgot-password", component: <ForgotPassword />, reqAuth: false },
  {
    path: "/forgot-password-sent",
    component: <ForgotPasswordEmailSent />,
    reqAuth: false,
  },
  { path: "/reset-password", component: <ResetPassword />, reqAuth: false },
  { path: "*", component: <NoResult />, reqAuth: false },
  { path: "/faq", component: <FAQ />, reqAuth: false },
  { path: "/contact-01", component: <Contact01 />, reqAuth: false },
  { path: "/contact-02", component: <Contact02 />, reqAuth: false },
  {
    path: "/checkout/:operationType/:itemId",
    component: StripePageWrapper,
    reqAuth: true,
  },

];

export default routes;
