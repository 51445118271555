import { getHeaders } from "../Item/utils";
const { REACT_APP_API_URL } = process.env;

export const getWorkflowsAll = async (id) => {
    try {
      const { headers } = await getHeaders();
      const response = await fetch(`${REACT_APP_API_URL}story/workflow/list`, {
        method: "GET",
        headers,
      });
      return response.json();
    } catch (err) {
      console.error(err);
    }
  };

  export const getWorkflowsById = async (id) => {
    try {
      const { headers } = await getHeaders();
      const response = await fetch(`${REACT_APP_API_URL}story/workflow/list/${id}`, {
        method: "GET",
        headers,
      });
      return response.json();
    } catch (err) {
      console.error(err);
    }
  };

  export const createWorkflow = async (requestBody) => {
    try {
      const { headers } = await getHeaders();
      const response = await fetch(`${REACT_APP_API_URL}story/workflow/create`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          ...headers,
        },
        body: JSON.stringify(requestBody),
      });
      
      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`HTTP error! status: ${response.status}, ${errorText}`);
      }
  
      const responseText = await response.text();
      if (!responseText) {
        return {}; // Handle empty response appropriately
      }
  
      return JSON.parse(responseText);
    } catch (error) {
      console.log(error);
      throw error; // Optionally rethrow the error for further handling
    }
  };

  export const updateWorkflow = async (id, requestBody) => {
    try {
      const { headers } = await getHeaders();
      const response = await fetch(`${REACT_APP_API_URL}story/workflow/update/${id}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          ...headers,
        },
        body: JSON.stringify(requestBody),
      });
      
      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`HTTP error! status: ${response.status}, ${errorText}`);
      }
  
      const responseText = await response.text();
      if (!responseText) {
        return {}; // Handle empty response appropriately
      }
  
      return JSON.parse(responseText);
    } catch (error) {
      console.log(error);
      throw error; // Optionally rethrow the error for further handling
    }
  };
  
  
