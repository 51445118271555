import React, { useEffect, useState } from "react";
import styled from "styled-components";


const WorkflowEditor = ({ currentWorkflow, onClose, onSave, majorCategories }) => {
  const [newWF, setNewWF] = useState(false);
  const [workflowName, setWorkflowName] = useState("");
  const [workflowDescription, setWorkflowDescription] = useState("");
  const [majorCategory, setMajorCategory] = useState("");
  const [minorCategory, setMinorCategory] = useState("");
  const [isActive, setIsActive] = useState(false);
  const [workflow, setWorkflow] = useState("");

  useEffect(() => {
    if (!currentWorkflow) {
      setNewWF(true);
      setWorkflowName("");
      setWorkflowDescription("");
      setMajorCategory("");
      setMinorCategory("");
      setIsActive(false);
      setWorkflow("");
    } else {
      setNewWF(false);
      setWorkflowName(currentWorkflow.wfName || "");
      setWorkflowDescription(currentWorkflow.wfDescrip || "");
      setMajorCategory(currentWorkflow.wfCatMajor || "");
      setMinorCategory(currentWorkflow.wfCatMinor || "");
      setIsActive(currentWorkflow.workflowActive === "Active");
      setWorkflow(currentWorkflow.workflow || "");
    }
  }, [currentWorkflow]);

  const handleSave = () => {
    const updatedWorkflow = {
      ...currentWorkflow,
      wfName: workflowName,
      wfDescrip: workflowDescription,
      wfCatMajor: majorCategory,
      wfCatMinor: minorCategory,
      workflowActive: isActive,
      workflow: workflow,
      newWF: newWF,
    };
    onSave(updatedWorkflow);
  };

  return (
    <EditorContainer>
      <EditorWrapper>
        <EditorContent>
          <LeftColumn>
            <EditWorkflowForm onSubmit={(e) => e.preventDefault()}>
              <FormTitle>Edit Workflow</FormTitle>
              <FormField>
                <FormLabel htmlFor="workflowName">Name</FormLabel>
                <FormInput
                  id="workflowName"
                  type="text"
                  value={workflowName}
                  onChange={(e) => setWorkflowName(e.target.value)}
                />
              </FormField>
              <FormField>
                <FormLabel htmlFor="workflowDescription">Description</FormLabel>
                <FormTextarea
                  id="workflowDescription"
                  value={workflowDescription}
                  onChange={(e) => setWorkflowDescription(e.target.value)}
                />
              </FormField>
              <FormField>
                <FormLabel htmlFor="majorCategory">Major Category</FormLabel>
                <SelectWrapper>
                  <FormSelect
                    id="majorCategory"
                    value={majorCategory}
                    onChange={(e) => setMajorCategory(e.target.value)}
                  >
                    <option value="">Select major category</option>
                    {majorCategories.map((category, index) => (
                      <option key={index} value={category}>
                        {category}
                      </option>
                    ))}
                  </FormSelect>
                  <SelectArrow src="https://cdn.builder.io/api/v1/image/assets/TEMP/15c5c10f5c03fa041de9ebd09284146f1e1eaa25c2be9c41ec3778770378e4a9?apiKey=6ef1b98222454dfe8bba8941b6ab01b5&" alt="" />
                </SelectWrapper>
              </FormField>
              <FormField>
                <FormLabel htmlFor="minorCategory">Minor Category</FormLabel>
                <FormInput
                  id="minorCategory"
                  type="text"
                  value={minorCategory}
                  onChange={(e) => setMinorCategory(e.target.value)}
                />
              </FormField>
              <FormField>
                <CheckboxWrapper>
                  <FormLabel htmlFor="activeStatus">Active</FormLabel>
                  <FormCheckbox
                    id="activeStatus"
                    type="checkbox"
                    checked={isActive}
                    onChange={(e) => setIsActive(e.target.checked)}
                  />
                </CheckboxWrapper>
              </FormField>
            </EditWorkflowForm>
          </LeftColumn>
          <RightColumn>
          <WorkflowSection>
              <SectionTitle>Workflow</SectionTitle>
              <FormTextarea2
                id="workflowDetails"
                value={workflow}
                onChange={(e) => setWorkflow(e.target.value)}
              />
              <ButtonGroup>
                <CloseButton onClick={onClose}>Close</CloseButton>
                <SaveButton onClick={handleSave}>Save</SaveButton>
              </ButtonGroup>
            </WorkflowSection>
          </RightColumn>
        </EditorContent>
      </EditorWrapper>
    </EditorContainer>
  );
};

const EditorContainer = styled.main`
  display: flex;
  justify-content: center;
  width: 700px;
  flex-direction: column;
`;

const EditorWrapper = styled.section`
  border-radius: 5px;
  background-color: #fbfdff;
  width: 100%;
  padding: 12px 16px 20px;
  @media (max-width: 991px) {
    max-width: 100%;
    padding-left: 20px;
  }
`;

const EditorContent = styled.div`
  display: flex;
  gap: 20px;
  @media (max-width: 991px) {
    flex-direction: column;
    align-items: stretch;
    gap: 0;
  }
`;

const LeftColumn = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 50%;
  margin-left: 0;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const EditWorkflowForm = styled.form`
  display: flex;
  flex-direction: column;
  @media (max-width: 991px) {
    margin-top: 40px;
  }
`;

const FormTitle = styled.h1`
  color: #2159a2;
  text-align: center;
  font: 600 24px Inter, sans-serif;
`;

const FormField = styled.div`
  margin-top: 14px;
`;

const FormLabel = styled.label`
  color: #2159a2;
  font: 600 16px Inter, sans-serif;
  display: block;
  margin-bottom: 5px;
`;

const FormInput = styled.input`
  border: 1px solid rgba(129, 129, 138, 1);
  background-color: #fff;
  width: 100%;
  height: 22px;
  padding: 5px;
`;

const FormTextarea = styled.textarea`
  border: 1px solid rgba(129, 129, 138, 1);
  background-color: #fff;
  width: 100%;
  height: 137px;
  padding: 5px;
`;

const FormTextarea2 = styled.textarea`
  border: 1px solid rgba(129, 129, 138, 1);
  background-color: #fff;
  flex-grow: 1; // Allows the textarea to grow and fill available space
  padding: 5px;
`;

const SelectWrapper = styled.div`
  position: relative;
`;

const FormSelect = styled.select`
  border: 1px solid rgba(129, 129, 138, 1);
  background-color: #fff;
  width: 100%;
  height: 38px;
  padding: 5px;
  appearance: none;
`;

const SelectArrow = styled.img`
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  width: 11px;
  height: 6px;
`;

const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const FormCheckbox = styled.input`
  width: 17px;
  height: 17px;
`;

const RightColumn = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 50%;
  margin-left: 20px;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const WorkflowSection = styled.section`
  display: flex;
  flex-direction: column;
  flex-grow: 1; // Allows the section to grow and fill available space
  margin-top: 38px;
  @media (max-width: 991px) {
    margin-top: 40px;
  }
`;

const SectionTitle = styled.h2`
  color: #2159a2;
  font: 600 16px Inter, sans-serif;
  align-self: start;
  margin-left: 11px;
  @media (max-width: 991px) {
    margin-left: 10px;
  }
`;

const WorkflowCanvas = styled.div`
  border: 1px solid rgba(129, 129, 138, 1);
  background-color: #fff;
  margin-top: 8px;
  flex-grow: 1; // Allows the textarea to grow and fill available space
  min-height: 298px; // Ensures a minimum height
  display: flex;
  flex-direction: column;
`;

const ButtonGroup = styled.div`
  align-self: flex-end;
  display: flex;
  margin-top: 16px;
  gap: 16px;
`;

const Button = styled.button`
  font: 600 16px Inter, sans-serif;
  border-radius: 15px;
  box-shadow: 3px 4px 6.1px 0px rgba(0, 0, 0, 0.25);
  padding: 8px 32px;
  cursor: pointer;
  @media (max-width: 991px) {
    padding: 8px 20px;
  }
`;

const CloseButton = styled(Button)`
  border: 1px solid rgba(217, 217, 217, 1);
  background-color: #fff;
  color: #2159a2;
`;

const SaveButton = styled(Button)`
  background-color: #2159a2;
  color: #fbfdff;
  border: none;
`;

export default WorkflowEditor;
