//import { CUSTOM_PAGE_SIZE } from "../../constants/constants";
import { getHeaders } from "../Item/utils";
const { REACT_APP_API_URL } = process.env;

export const createCharacter = async ({ requestBody }) => {
  try {
    const { headers } = await getHeaders();
    const response = await fetch(`${REACT_APP_API_URL}story/character/create`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        ...headers,
      },
      body: JSON.stringify(requestBody),
    });
    return response.json();
  } catch (error) {
    console.log(error);
  }
  };

export const getCharacters = async (storyId) => {
  try {
    const { headers } = await getHeaders();
    const response = await fetch(`${REACT_APP_API_URL}story/character/listByStory/${storyId}`, {
      method: "GET",
      headers,
    });
    return response.json();
  } catch (err) {
    console.error(err);
  }
};

export const getCharactersById = async (id) => {
  try {
    const { headers } = await getHeaders();
    const response = await fetch(`${REACT_APP_API_URL}story/character/list/${id}`, {
      method: "GET",
      headers,
    });
    return response.json();
  } catch (err) {
    console.error(err);
  }
};

  export const getCharactersDesign = async (id) => {
    try {
      const { headers } = await getHeaders();
      const response = await fetch(`${REACT_APP_API_URL}story/character/design/${id}`, {
        method: "GET",
        headers,
      });
      return response.json();
    } catch (err) {
      console.error(err);
    }
  };
  
  



