import React from "react";
import Footer from "../footer/Footer";
//import Header from "../header/Header";
import Header_LP from "../header/Header_LP";
import Footer_LP from "../footer/Footer_LP";

const Layout = ({children}) => {
  return (
    <>
      <Header_LP />
      <main>{children}</main>
      <Footer_LP />
    </>
  );
};

export default Layout;
