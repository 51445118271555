import React from "react";
import styled from "styled-components";

const ImportSceneModal = ({ onImport, onReturn }) => {
  return (
    <ModalWrapper>
      <ModalContent>
        <ModalTitle>Are you ready to import your scenes?</ModalTitle>
        <ModalDescription>
          You can import your scenes now or return to the Writers Room to work on your story.
        </ModalDescription>
        <ButtonGroup>
          <ImportButton onClick={onImport}>Import My Scenes</ImportButton>
          <ReturnButton onClick={onReturn}>Return to Writers Room</ReturnButton>
        </ButtonGroup>
      </ModalContent>
    </ModalWrapper>
  );
};

const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); // Semi-transparent overlay
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; // Ensure it's above other content
  padding: 0; // Remove padding here if using full-screen overlay
  overflow: auto; // Enables scrolling if the modal content is too long
`;


const ModalContent = styled.div`
  align-self: center;
  display: flex;
  width: 100%;
  max-width: 659px;
  flex-direction: column;
  align-items: center;
  background-color: #fff; // Background color for the modal content
  border: 2px solid #2159a2; // Add a border with the desired color and width
  border-radius: 15px; // Optional: add a border-radius for rounded corners
  font-weight: 600;
  margin: auto;
  padding: 20px; // Add padding inside the modal content
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5); // Optional: add a shadow for 3D effect
  z-index: 1001; // Ensure the content is above the overlay
`;


const ModalTitle = styled.h2`
  color: #2159a2;
  align-text: center;
  font: 36px Inter, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const ModalDescription = styled.p`
  color: #000;
  font: 400 20px Inter, sans-serif;
  margin: 43px 0 0 45px;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 20px;
  font-size: 24px;
  justify-content: center; // Center the buttons horizontally
  width: 100%; // Ensure the button group takes the full width of its container
  margin-top: 40px; // Adjust the top margin as needed
`;


const Button = styled.button`
  font-family: Inter, sans-serif;
  justify-content: center;
  border-radius: 15px;
  box-shadow: 3px 4px 6.1px 0px rgba(0, 0, 0, 0.25);
  padding: 15px 30px;
  white-space: nowrap;
  @media (max-width: 991px) {
    white-space: initial;
    padding: 0 20px;
  }
`;

const ImportButton = styled(Button)`
  background-color: #2159a2;
  color: #fbfdff;
  border: none;
`;

const ReturnButton = styled(Button)`
  background-color: #2159a2;
  color: #fbfdff;
  border: none;
`;

export default ImportSceneModal;