import React, { useRef, useEffect, useState } from "react";
import styled from "styled-components";
import { useNavigate, useLocation } from "react-router-dom";
import { notify, notifyError } from "../../helpers/toastNotificationService"
import SynthFooter from "../../components/synth/Footer";
import SynthHeader from "../../components/synth/Header";
import { getStoriesById, getScriptById } from "../../api/synth/getstories";
import { updateStories } from "../../api/synth/updatestories";
import { 
  ADD_CHARACTER_PROMPT,
  ADD_SCENE_PROMPT,
  ADD_TO_STORY_PROMPT,
} from "../../constants/constants";
import Spinner from "../../components/layouts/Spinner/Spinner";
import { createClaude, updateClaude } from "../../api/synth/claudeTestCreate";
import EditStoryContext from './EditStoryContext';
import VersionHistory from "../../components/synth/VersionHistory";
import '@progress/kendo-theme-bootstrap/dist/all.scss';

const projectData = [
  {
    id: 1,
    name: "Project 1",
    image: "https://cdn.builder.io/api/v1/image/assets/TEMP/22f56a7f7797014e3fe79f2e2ecdb4d5278b0840ac4813c075a47d93e0644e8d?apiKey=6ef1b98222454dfe8bba8941b6ab01b5&",
  },
];

const user = {
  id: 1,
  //avatarUrl: "https://demos.telerik.com/kendo-ui/content/web/Customers/RICSU.jpg",
  //avatarAltText: "Conversational UI RICSU"
};

const bot = { id: 0 };

const initialMessages = [
  {
    author: bot,
    timestamp: new Date(),
    text: "Hello, How can I help you with your story?",
  },
];

const EditStory = () => {
  const [selectedProject, setSelectedProject] = React.useState(projectData[0]);
  const [aiAssistantText, setAiAssistantText] = React.useState("");
  const [script, setScript] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const [isLoadingResponse, setIsLoadingResponse] = React.useState(false);
  const loadingGifUrl = "https://alsynthprod.s3.amazonaws.com/3dotLoading2.gif";
  const [lastBotResponse, setLastBotResponse] = React.useState("");


  const location = useLocation();
  const navigate = useNavigate();

  const queryParams = new URLSearchParams(location.search);
  const projectName = queryParams.get('projectName');
  const storyId = queryParams.get('storyId');

  React.useEffect(() => {
    // setLoading(true);
    const retrieveStory = async () => {
      setIsLoading(true);  
      try {
            const response = await getStoriesById(storyId);
            if (response && response.scriptId) {
                const scriptData = await getScriptById(response.scriptId);
                if (scriptData && scriptData.scriptText) {
                    setScript(scriptData.scriptText); // Update the script state with the fetched data
                }
            }
        } catch (error) {
            console.error(error);
            notifyError("Failed to load story");
        } finally {
            setIsLoading(false);
        }
    };
    retrieveStory();
}, [storyId]); // Add storyId as a dependency to refetch when it changes




  ///////////////////////////////////////////////////////////////////
  // Chat Testing
  const { Chat } = require('@progress/kendo-react-conversational-ui');
  const [messages, setMessages] = React.useState(initialMessages);
  
  const addNewMessage = async (event) => {
    let botResponse = Object.assign({}, event.message);
    setMessages([...messages, event.message]);
    
    // Set loading state to true
    setIsLoadingResponse(true);
  
    // Add a temporary loading message with GIF
    const loadingMessage = {
      author: bot,
      text: "",
      timestamp: new Date(),
      loading: true, // Custom property to identify loading message
    };
    setMessages((oldMessages) => [...oldMessages, loadingMessage]);

    const userMessageText = event.message.text.toLowerCase();
  
    // Fetching response from Claude AI interaction
    if (userMessageText.includes("add a character")) {
      // custom add character prompt and Claude update call
      const previousPrompt = script;
      const prompt = ADD_CHARACTER_PROMPT + " " + userMessageText;
      const finalPrompt = `Read the following text inside <text></text> XML tags, and then answer the question: <text>${previousPrompt}</text>${prompt}`
      botResponse.text = await claudeInteraction(finalPrompt);
      setLastBotResponse(botResponse.text);
      botResponse.suggestedActions = [
        {
          value: "Add this character to my story",
          type: "reply",
        },
      ];
    }
    
    if (userMessageText.includes("add a scene")) {
      const previousPrompt = script;
      const prompt = ADD_SCENE_PROMPT + " " + userMessageText;
      const finalPrompt = `Read the following text inside <text></text> XML tags, and then answer the question: <text>${previousPrompt}</text>${prompt}`
      botResponse.text = await claudeInteraction(finalPrompt);
      setLastBotResponse(botResponse.text);
      botResponse.suggestedActions = [
        {
          value: "Add this scene to my story",
          type: "reply",
        },
      ];
    }

    if (userMessageText.includes("add this character to my story") || userMessageText.includes("add this scene to my story")) {
      
      const prompt = ADD_TO_STORY_PROMPT + " " + lastBotResponse;
      
      const requestBody = {
        prompt: prompt,
        previousPrompt: script,
      };
      
      try {
        const aiResponse = await updateClaude({ requestBody });
        botResponse.text = aiResponse;
        //botResponse.text = "Your story has been updated.";
      } catch (error) {
        console.error("Error updating Story:", error);
      }
    }
    
    if (!userMessageText.includes("add a scene") && !userMessageText.includes("add a character") && !userMessageText.includes("add this character to my story") && !userMessageText.includes("add this scene to my story")) {
      // custom add character prompt and Claude update call
      botResponse.text = await claudeInteraction(event.message.text);
      botResponse.suggestedActions = [
        /*{
          value: "Add this to my story",
          type: "reply",
        },*/
      ];
    }

    botResponse.author = bot;
  
  
    // Remove the loading message
    setMessages((oldMessages) => oldMessages.filter((msg) => !msg.loading));
  
    // Set loading state to false
    setIsLoadingResponse(false);
  
    setTimeout(() => {
      setMessages((oldMessages) => [...oldMessages, botResponse]);
    }, 1000);
  };
  
  
  const onAction = (event) => {
    if (event.action.type === "alert") {
      setMessages([
        ...messages,
        {
          author: user,
          text: event.action.value,
        },
      ]);
    }
  };
  

  const claudeInteraction = async (question) => {
    console.log("Question: ", question);
    const requestBody = createAiRequestBody(question);
    console.log("Request Body: ", requestBody);
    const aiResponse = await createClaude({requestBody});
    console.log("AI Response: ", aiResponse);
    return aiResponse;
  };


  const createAiRequestBody = (ideaText) => {
    const requestBody = {
      prompt: ideaText,
    };
    return requestBody;
  }

  /*const handleAiAssistantSubmit = async () => {
    //setIsLoading(true);
    if (event.message.text) {
      const scriptText = script;
      
      
      
      
      const prompt = event.message.text;
      //console.log("Script Text", scriptText)
      const requestBody = updateAiRequestBody(prompt, script);
      //console.log("AI Prompt:", requestBody);
      // Pass the new and previous prompts to Claude
      const aiUpdateResponse = await updateClaude({requestBody});
      const scriptTextFromAI = `"${aiUpdateResponse}"`;
      //console.log("AI Response:", scriptTextFromAI);
      // Craete a new request format for updating the saved script
      const newRequestBody = createRequestBody(storyId, scriptTextFromAI);
      //console.log("newRequestBody:", newRequestBody);
      //console.log("Story ID: ", storyId);
      // Update the saved script entry
      const response = await updateStories({requestBody: newRequestBody});
      //console.log("response:", response);
      navigate(`/editstory?projectName=${encodeURIComponent(projectName)}&storyId=${encodeURIComponent(storyId)}`);
    } else {
      //console.log("About to notify error");
      notifyError("Please add prompt text to continue");
    }
    setIsLoading(false);

  };*/


///////////////////////////////////////////////////////////////////////

  const createRequestBody = (storyId, scriptText) => {
    const requestBody = {
        storyId: storyId,
        scriptText: scriptText
    };
    return requestBody;
  };

  const updateAiRequestBody = (prompt, previousPrompt) => {
    const requestBody = {
      prompt: aiAssistantText,
      previousPrompt: script,
    };
    return requestBody;
  }

  
const saveScriptChanges = async () => {
  setIsLoading(true);
  const scriptText = script;
  const newRequestBody = createRequestBody(storyId, scriptText);
  const response = await updateStories({requestBody: newRequestBody});
  setIsLoading(false);
}

  const handleProjectChange = (project) => {
    setSelectedProject(project);
  };


  return (
    <EditStoryContext.Provider value={{ saveScriptChanges }}>
    <AppWrapper>
      <SynthHeader project={projectName} />
      <Main>
      {isLoading && (
          <ProgressOverlay>
            <ProgressStatus>Updating Story...  This will take a moment.
            </ProgressStatus>
            <Spinner />
          </ProgressOverlay>
        )}
        <ScriptEditorSection>
          <ScriptEditorColumn>
            <ScriptEditorTitle>
              <ScriptEditorHeading>Writers Room</ScriptEditorHeading>
              <ScriptEditorTextArea
                value={script}
                onChange={(event) => setScript(event.target.value)}
                /> 
            </ScriptEditorTitle>
          </ScriptEditorColumn>
          <AIAssistantColumn>
            <AIAssistantContent>
              <AIAssistantDescription>
                Use AI to help you with your story.
                <br />
              </AIAssistantDescription>
              <AIAssistantInstructions>
                Say what you want to change in your story, and AI will implement
                those changes.{" "}
              </AIAssistantInstructions>
              {/*<AIAssistantTextArea
                placeholder="What would you like to change about your story?"
                value={aiAssistantText}
                onChange={handleAiAssistantTextChange}
              />
              <AIAssistantSubmitButton onClick={handleAiAssistantSubmit}>
                Submit
              </AIAssistantSubmitButton>*/}
            <Chat
              user={user}
              messages={messages.map((msg) => ({
                ...msg,
                text: msg.loading ? <img src={loadingGifUrl} alt="Loading..." /> : msg.text,
              }))}
              onMessageSend={addNewMessage}
              placeholder={"Type a message..."}
              onActionExecute={onAction}
              width={400}
            />
            </AIAssistantContent>
          </AIAssistantColumn>
        </ScriptEditorSection>
      </Main>
      <Divider />
      <SynthFooter />
    </AppWrapper>
    </EditStoryContext.Provider>
  );
}


const AppWrapper = styled.div`
  background-color: #fbfdff;
  display: flex;
  flex-direction: column;
`;

const Header = styled.header`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  min-height: 111px;
  width: 100%;
  gap: 20px;
  padding: 31px 64px;
  @media (max-width: 991px) {
    max-width: 100%;
    flex-wrap: wrap;
    padding: 0 20px;
  }
`;

const ProjectInfo = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  gap: 20px;
`;

const ProjectImage = styled.img`
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 50%;
`;

const ProjectName = styled.h1`
  position: relative;
  color: #2159a2;
  text-align: center;
  flex-grow: 1;
  margin: 0;
  font: 700 32px 'Josefin Sans', sans-serif;
`;

const ProjectActions = styled.div`
  position: relative;
  display: flex;
  gap: 13px;
  align-items: center;
`;

const StartOverButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  box-shadow: 3px 4px 6.1px 0px rgba(0, 0, 0, 0.25);
  background-color: #fff;
  padding: 10px 16px;
  border: none;
  cursor: pointer;
`;

const StartOverIcon = styled.img`
  width: 29px;
  height: 29px;
`;

const StartOverText = styled.span`
  color: #2159a2;
  text-align: center;
  padding: 10px 30px;
  font: 600 24px 'Josefin Sans', sans-serif;
  @media (max-width: 991px) {
    padding: 0 20px;
  }
`;

const SaveButton = styled.button`
  justify-content: center;
  border-radius: 15px;
  box-shadow: 3px 4px 6.1px 0px rgba(0, 0, 0, 0.25);
  background-color: #2159a2;
  color: #fbfdff;
  white-space: nowrap;
  text-align: center;
  padding: 10px 45px;
  font: 600 24px 'Josefin Sans', sans-serif;
  border: none;
  cursor: pointer;
  @media (max-width: 991px) {
    white-space: initial;
    padding: 0 20px;
  }
`;

const Main = styled.main`
  align-self: center;
  margin-top: 42px;
  width: 100%;
  max-width: 1329px;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
  }
`;

const ScriptEditorSection = styled.section`
  gap: 20px;
  display: flex;
  @media (max-width: 991px) {
    flex-direction: column;
    align-items: stretch;
    gap: 0px;
  }
`;

const ScriptEditorColumn = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 74%;
  margin-left: 0px;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const ScriptEditorTitle = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  font-size: 40px;
  color: #011126;
  font-weight: 700;
  text-align: center;
  padding: 0 20px;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
  }
`;

const ScriptEditorHeading = styled.h2`
  font-family: 'Josefin Sans', sans-serif;
  align-self: center;
  margin: 0;
  color: #000;
`;

const ScriptEditorTextArea = styled.textarea`
  border: 1px solid rgba(115, 115, 115, 1);
  background-color: #fff;
  margin-top: 22px;
  height: 575px;
  resize: none;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const AIAssistantColumn = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 30%;
  margin-left: 20px;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const AIAssistantContent = styled.div`
  display: flex;
  flex-direction: column;
  font-weight: 400;
  @media (max-width: 991px) {
    margin-top: 40px;
  }
`;

const AIAssistantDescription = styled.p`
  color: #24777d;
  font: 700 18px 'Josefin Sans', sans-serif;
  margin: 0;
`;

const AIAssistantInstructions = styled.p`
  color: #011126;
  margin-top: 12px;
  font: 14px 'Josefin Sans', sans-serif;
`;

const AIAssistantTextArea = styled.textarea`
  align-items: start;
  border: 1px solid rgba(129, 129, 138, 1);
  background-color: #fff;
  margin-top: 22px;
  color: #81818a;
  aspect-ratio: 1;
  padding: 29px 25px 238px;
  resize: none;
  @media (max-width: 991px) {
    padding: 0 20px 40px;
  }
`;

const AIAssistantSubmitButton = styled.button`
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  box-shadow: 3px 4px 6.1px 0px rgba(0, 0, 0, 0.25);
  background-color: #24777d;
  align-self: center;
  margin-top: 24px;
  width: 212px;
  max-width: 100%;
  color: #fbfdff;
  white-space: nowrap;
  text-align: center;
  padding: 14px 45px;
  font: 600 16px 'Josefin Sans', sans-serif;
  border: none;
  cursor: pointer;
  @media (max-width: 991px) {
    white-space: initial;
    padding: 0 20px;
  }
`;

const Divider = styled.div`
  border-color: rgba(33, 89, 162, 1);
  border-style: solid;
  border-width: 4px;
  background-color: #2159a2;
  z-index: 10;
  min-height: 4px;
  margin-top: 37px;
  width: 100%;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Footer = styled.footer`
  background-color: #fbfdff;
  display: flex;
  align-items: center;
  gap: 20px;
  font-size: 13px;
  color: #2159a2;
  font-weight: 600;
  justify-content: space-between;
  padding: 16px 22px 16px 44px;
  @media (max-width: 991px) {
    flex-wrap: wrap;
    padding: 0 20px;
  }
`;

const Logo = styled.img`
  aspect-ratio: 1;
  object-fit: auto;
  object-position: center;
  width: 79px;
  box-shadow: 2px 5px 13.2px 0px rgba(0, 0, 0, 0.25);
  align-self: stretch;
  margin: auto 0;
`;

const Separator = styled.div`
  border-color: rgba(33, 89, 162, 1);
  border-style: solid;
  border-width: 1px;
  background-color: #2159a2;
  align-self: start;
  width: 1px;
  height: 91px;
`;

const NavItem = styled.div`
  justify-content: center;
  border-radius: 20px;
  box-shadow: 4px 4px 6px 0px rgba(0, 0, 0, 0.25);
  border-color: rgba(217, 217, 217, 1);
  border-style: solid;
  border-width: 1px;
  background-color: #fbfdff;
  align-self: stretch;
  display: flex;
  gap: 15px;
  white-space: nowrap;
  text-align: center;
  margin: auto 0;
  padding: 10px 23px;
  @media (max-width: 991px) {
    white-space: initial;
    padding: 0 20px;
  }
`;

const NavItemIcon = styled.img`
  aspect-ratio: 1.04;
  object-fit: auto;
  object-position: center;
  width: 27px;
`;

const NavItemText = styled.span`
  font-family: 'Josefin Sans', sans-serif;
  margin: auto 0;
`;

const ProfileIcon = styled.img`
  aspect-ratio: 1;
  object-fit: auto;
  object-position: center;
  width: 43px;
  fill: #fbfdff;
  stroke-width: 1px;
  stroke: #2159a2;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  border-color: rgba(33, 89, 162, 1);
  border-style: solid;
  border-width: 1px;
  align-self: stretch;
  margin: auto 0;
`;

const ProgressOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  right: 0;
  bottom: 0;
  background: rgba(205, 205, 205, 0.8);
  z-index: 7777;
  position: fixed;

  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  flex-wrap: wrap;
  `;
  
  const ProgressStatus = styled.div`
    display: block;
    width: 100%;
    font-weight: 400;
    font-size: 24px;
    line-height: 24px;
    margin-bottom: 8px;
    text-align: center;
    color: #011126;
`;

export default EditStory;