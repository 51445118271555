import { getHeaders } from "../Item/utils";
const { REACT_APP_API_URL } = process.env;

export const importCharacters = async (id) => {
    try {
      const { headers } = await getHeaders();
      const response = await fetch(`${REACT_APP_API_URL}story/character/import/${id}`, {
        method: "POST",
        headers,
      });
      return response;
    } catch (err) {
      console.error(err);
    }
  };

  export const updateCharacterDesign = async ({ requestBody }) => {
    try {
      const { headers } = await getHeaders();
      const response = await fetch(`${REACT_APP_API_URL}story/character/design`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          ...headers,
        },
        body: JSON.stringify(requestBody),
      });
      return response.json();
    } catch (error) {
      console.error('Error updating character design:', error);
    }
    };

    export const updateCharacters = async (id, requestBody) => {
      try {
        const { headers } = await getHeaders();
        const response = await fetch(`${REACT_APP_API_URL}story/character/update/${id}`, {
          method: "POST",
        headers: {
          "Content-Type": "application/json",
          ...headers,
        },
        body: JSON.stringify(requestBody),
      });
      return response.json();
    } catch (error) {
        console.error("Character Update Failed");
      }
    };

    export const uploadCharacterImage = async (id, formData) => {
      try {
        const { headers } = await getHeaders();
        const response = await fetch(`${REACT_APP_API_URL}story/character/update/image/${id}`, {
          method: "PUT",
          headers,
          body: formData,
        });
        return response;
      } catch (err) {
        console.error(err);
      }
    };