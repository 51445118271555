import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth0 } from "../../auth/Auth";
import { useForm } from "react-hook-form";
import LinkWithQueryParams from "../../components/LinkWithQueryParams";
import {
  AUTH0_LOGIN_ERROR_STATUS_CODE,
  AUTH0_MANY_ATTEMPTS_CODE,
  EMAIL_INVALID_MESSAGE,
  EMAIL_PATTERN,
  PASSWORD_INVALID_LOGIN_MESSAGE,
  LOGIN_ERROR_MESSAGE,
  REQUIRED_MESSAGE,
} from "../../constants/constants";
import { useApi } from "../../hooks/useApi";
import { useCustomNavigator } from "../../hooks/useCustomNavigator";
import { signin } from "../../store/slices/user/user"
import Spinner from "../../components/layouts/Spinner/Spinner";
import { notify } from "../../helpers/toastNotificationService";
import { SUCCESFUL_SUBSCRIPTION } from "../../constants/constants";
import { getMyUser } from "../../api/user/getUser";

const { REACT_APP_API_URL } = process.env;

const socialMedias = [
    {
      name: "Facebook",
      icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/4a29e2df67afde37b9a75326fcd76e92dce00f87e83b6116e3a58067c126f9d5?apiKey=6ef1b98222454dfe8bba8941b6ab01b5&",
    },
    {
      name: "Google",
      icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/4b1d49202b468f5a48babe23a97bf87cbbb604819538dabe538f9cc0c031f06b?apiKey=6ef1b98222454dfe8bba8941b6ab01b5&",
    },
  ];

  const LoginModal = ({ isOpen, onClose }) => {
    const [isLoading, setIsLoading] = React.useState(false);
    const [loginError, setLoginError] = useState(false);
    const [tooManyError, setTooManyError] = useState(false);
    const { register, handleSubmit, formState: { errors } } = useForm();
    const [isSignUpClicked, setIsSignUpClicked] = useState(false);
    const [emailCapture, setEmailCapture] = React.useState("");
    const { executeApiRequest } = useApi();
    const { login } = useAuth0();
    const navigate = useNavigate();
    const { state } = useLocation();
    const dispatch = useDispatch();
    const { executeApiRequestTwo } = useApi();
    const { customNavigate } = useCustomNavigator();
    const { user } = useAuth0();
    const { email } = useSelector((state) => state.user);
    

    const handleForgotPassword = () => {
        console.log("Forgot password clicked");    
    };

    const onSubmit = async (data) => {
      //console.log("Starting Login process");
      setIsLoading(true);
      setLoginError(false);
        try {
            const { user } = await login({
                email: data.email,
                password: data.password,
            });
            await executeLogin({ user });
            if (state?.path) {
                customNavigate({ path: state.path, state });
            } else {
                customNavigate({ path: "/synthprojects", state });
            }
        } catch (e) {
          setIsLoading(false);
            if (e?.statusCode === AUTH0_LOGIN_ERROR_STATUS_CODE) {
              setLoginError(true);  
            }
            if (e?.code === AUTH0_MANY_ATTEMPTS_CODE) {
                setTooManyError(true);
                setLoginError(false);
            }
        }
    };

    const executeLogin = async ({ user }) => {
      const userData = await getMyUser();
      dispatch(signin({ ...userData, ...user }));
    };

    const onSignUpClick = () => {
      setIsSignUpClicked(true);
    };

    const handleEmailChange = (event) => {
      setEmailCapture(event.target.value);
    };
  
    const onWaitingListClick = async (event) => {
      event.preventDefault();
      //console.log(`Submitting email: ${emailCapture}`);
      
      try {
        await executeApiRequest(
          `${REACT_APP_API_URL}general/email-for-beta`,
          {
            method: "POST",
            body: JSON.stringify({
              email: emailCapture,
            }),
            headers: { "Content-Type": "application/json" },
          }
        );
        notify(SUCCESFUL_SUBSCRIPTION);
      } catch (e) {
          //console.log(e);
      }
    };


    const onCloseButton = () => {
      setIsSignUpClicked(false);
      onClose();
    }

    if (!isOpen) return null;

    return (
      <div>
      <LoginContainer>
          <LoginCard>
              <CloseButton onClick={onCloseButton}>&times;</CloseButton>
              {isSignUpClicked ? (
                  <>
                      <LoginTitle><br/>The Acme Synthesizer™ is currently invite only.</LoginTitle>
                      <form onSubmit={onWaitingListClick}>
                          <InputLabel htmlFor="email">Email address</InputLabel>
                          <InputField
                              type="email"
                              id="emailInput"
                              placeholder="example@mail.com"
                              aria-label="Enter your email"
                              value={emailCapture}
                              onChange={handleEmailChange}
                              style={{ backgroundColor: 'white' }}
                              required
                          />
                          <p className="error-message">{errors.email?.message}</p>

 
                          <SubmitButton type="submit">Join Wating List</SubmitButton>
                      </form>
                      <SignUpButton type="button" onClick={onWaitingListClick}></SignUpButton>
                  </>
              ) : (
                  <>
                      <LoginTitle>Log In</LoginTitle>
                      <form onSubmit={handleSubmit(onSubmit)}>
                          <InputLabel htmlFor="email">Email address</InputLabel>
                          <InputField
                              id="email"
                              type="email"
                              placeholder="example@mail.com"
                              {...register("email", {
                                  required: REQUIRED_MESSAGE,
                                  pattern: {
                                      value: EMAIL_PATTERN,
                                      message: EMAIL_INVALID_MESSAGE,
                                  },
                              })}
                          />
                          <p style={{ color: 'red', textAlign: 'center' }}>{errors.email?.message}</p>
  
                          <InputLabel htmlFor="password">Password</InputLabel>
                          <InputField
                              id="password"
                              type="password"
                              placeholder="*****************"
                              {...register("password", {
                                  required: REQUIRED_MESSAGE,
                                  minLength: {
                                      value: 4,
                                      message: PASSWORD_INVALID_LOGIN_MESSAGE,
                                  },
                                  maxLength: {
                                      value: 20,
                                      message: PASSWORD_INVALID_LOGIN_MESSAGE,
                                  },
                              })}
                          />
                          <p style={{ color: 'red', textAlign: 'center' }}>{errors.password?.message}</p>
                          {loginError && (
                            <p style={{ color: 'red', textAlign: 'center' }}>
                              {LOGIN_ERROR_MESSAGE}
                            </p>
                          )}
  
                          {/*<ForgotPasswordButton type="button" onClick={handleForgotPassword}>*/}
                             {/* Forgot Password?*/}
                          {/*</ForgotPasswordButton>*/}
  
                          <RememberMeCheckbox>
                              <CheckboxInput
                                  type="checkbox"
                                  id="rememberMe"
                                  {...register("rememberMe")}
                              />
                              <label htmlFor="rememberMe">Remember me</label>
                          </RememberMeCheckbox>
  
                          <SubmitButton type="submit">Sign In</SubmitButton>
                      </form>
                      <SignUpButton type="button" onClick={onSignUpClick}>No Account? Join Waitlist</SignUpButton>
                  </>
              )}
          </LoginCard>
      </LoginContainer>
      </div>
  );
};


const BetaOnlyMessage = styled.p`
    font-size: 24px;
    color: #2159a2;
    margin-top: 20px;
`;

const CloseButton = styled.button`
    position: absolute;
    top: 10px;
    right: 1px;
    font-size: 25px;
    border: none;
    background: none;
    cursor: pointer;
    color: #2159a2
`;

const LoginContainer = styled.main`
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 1000 // High z-index to ensure it's on top
      @media (max-width: 991px) {
        
      }
  `;
  
  const LoginCard = styled.section`
  max-width: 460px;
  border-radius: 21px;
  box-shadow: -4px 4px 8.4px 0px rgba(0, 0, 0, 0.25);
  border: 1px solid rgba(217, 217, 217, 1);
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 39px 44px 23px;
  z-index: 1010; // Ensure the card itself has a higher z-index than the overlay
  position: absolute;
  right: 10%;
  @media (max-width: 991px) {
    position: relative;
    right: 0%
  }
`;
    
    const LoginTitle = styled.h1`
      text-align: center;
      font: 700 24px 'Josefin Sans', sans-serif;
    `;
    
    const SocialMediaButton = styled.button`
      justify-content: center;
      border-radius: 15px;
      box-shadow: 2px 4px 10.8px 0px rgba(0, 0, 0, 0.25);
      background-color: #fff;
      display: flex;
      margin-top: 21px;
      width: 100%;
      max-width: 316px;
      gap: 13px;
      font-size: 16px;
      font-weight: 600;
      text-align: center;
      padding: 6px 20px;
      border: none;
      cursor: pointer;
    `;
    
    const SocialMediaIcon = styled.img`
      width: 38px;
      height: 38px;
    `;
    
    const SocialMediaText = styled.span`
      font-family: 'Josefin Sans', sans-serif;
      margin: auto 0;
    `;
    
    const InputLabel = styled.label`
      color: #000;
      font-family: 'Josefin Sans', sans-serif;
      display: block;
      margin-top: 21px;
      font-size: 14px;
    `;
    
    const InputField = styled.input`
      font-family: 'Josefin Sans', sans-serif;
      border-radius: 5px;
      border: 1px solid rgba(129, 129, 138, 1);
      background-color: #fff;
      width: 100%;
      margin-top: 10px;
      color: #4d4a4a;
      padding: 12px 20px;
    `;
    
    const ForgotPasswordButton = styled.button`
      color: #1b70d0;
      text-decoration: underline;
      display: block;
      margin-top: 10px;
      font: 12px 'Josefin Sans', sans-serif;
      cursor: pointer;
      background: none;
      border: none;
      padding: 0;
    `;
    
    const RememberMeCheckbox = styled.div`
      display: flex;
      align-items: center;
      margin-top: 13px;
      gap: 11px;
      font-size: 12px;
      color: #000;
    `;
    
    const CheckboxInput = styled.input`
      width: 15px;
      height: 15px;
    `;
    
    const SubmitButton = styled.button`
      justify-content: center;
      border-radius: 15px;
      box-shadow: 3px 4px 6.1px 0px rgba(0, 0, 0, 0.25);
      background-color: #2159a2;
      margin-top: 15px;
      color: #fbfdff;
      text-align: center;
      padding: 13px 42px;
      font: 600 16px 'Josefin Sans', sans-serif;
      border: none;
      cursor: pointer;
      width: 100%;
    `;
    
    const SignUpButton = styled.button`
      color: #1b70d0;
      text-align: center;
      text-decoration: underline;
      margin-top: 18px;
      font: 12px 'Josefin Sans', sans-serif;
      cursor: pointer;
      background: none;
      border: none;
      padding: 0;
    `;
    

    const ModalOverlay = styled.div`
  position: fixed; /* or 'relative' depending on layout requirements */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: flex-start; /* Aligns children (like LoginCard) to the start (left) */
  align-items: center;
`;

const ModalContent = styled.div`
  background: white;
  padding: 20px;
  border-radius: 5px;
`;

export default LoginModal;